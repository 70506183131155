import { __decorate } from "tslib";
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
let Members = class Members extends VuexModule {
    constructor() {
        super(...arguments);
        this.employees = [];
        this.employeesNotTeam = [];
    }
    setEmployees(employees) {
        this.employees = employees;
    }
    setEmployeesNotTeam(employees) {
        this.employeesNotTeam = employees;
    }
    async getEmployeesNotTeamFromRemote(teamId) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Settings($_id: String!) {
          findAllEmployeesNotTeam(_id: $_id) {
            _id
            firstName
            lastName
          }
        }
      `,
            variables: { _id: teamId },
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findAllEmployeesNotTeam)
            this.context.commit('setEmployeesNotTeam', result.data.findAllEmployeesNotTeam);
    }
    async getEmployeesNotUserFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Settings {
          findEmployeesNotUser {
            _id
            firstName
            lastName
            user {
              firstName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findEmployeesNotUser)
            this.context.commit('setEmployees', result.data.findEmployeesNotUser);
    }
};
__decorate([
    Mutation
], Members.prototype, "setEmployees", null);
__decorate([
    Mutation
], Members.prototype, "setEmployeesNotTeam", null);
__decorate([
    Action
], Members.prototype, "getEmployeesNotTeamFromRemote", null);
__decorate([
    Action
], Members.prototype, "getEmployeesNotUserFromRemote", null);
Members = __decorate([
    Module({ namespaced: true })
], Members);
export default Members;
