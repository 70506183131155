import auth from '@/guards/auth.guard';
import Employees from './../views/employees.vue';
const employeesRoutes = [
    {
        path: '/employees',
        name: 'Employees',
        component: Employees,
        meta: {
            middleware: [auth]
        }
    }
];
export default employeesRoutes;
