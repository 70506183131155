import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const managers = namespace('managers');
let SelectMangerNotTeam = class SelectMangerNotTeam extends Vue {
    async created() {
        await this.getManagersNotTeamFromRemote(this.team._id);
    }
};
__decorate([
    managers.State
], SelectMangerNotTeam.prototype, "managersNotTeam", void 0);
__decorate([
    Prop()
], SelectMangerNotTeam.prototype, "team", void 0);
__decorate([
    Prop({ default: 'User' })
], SelectMangerNotTeam.prototype, "label", void 0);
__decorate([
    managers.Action
], SelectMangerNotTeam.prototype, "getManagersNotTeamFromRemote", void 0);
SelectMangerNotTeam = __decorate([
    Component({
        name: 'SelectMangerNotTeam'
    })
], SelectMangerNotTeam);
export default SelectMangerNotTeam;
