import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import CountrySelect from '@/components/country-select.vue';
import SelectTeam from '@/modules/settings/components/team/select-team.vue';
const employees = namespace('employees');
let NewEmployee = class NewEmployee extends Vue {
    constructor() {
        super(...arguments);
        this.dateOfHireMenu = false;
        this.birthdateMenu = false;
        this.showNotification = false;
        this.loading = false;
        this.contractType = ['CDD', 'CDI'];
        this.familySituation = ['Married', 'Unmarried'];
    }
    async save() {
        this.loading = true;
        const result = await this.createEmployee();
        if (result) {
            this.showNotification = true;
            this.clearNewEmployee();
        }
        this.loading = false;
    }
    closeDialog() {
        this.showNotification = false;
    }
    selectTeam(team) {
        this.setTeamId(team._id);
    }
};
__decorate([
    employees.State
], NewEmployee.prototype, "newEmployee", void 0);
__decorate([
    employees.Mutation
], NewEmployee.prototype, "setNationality", void 0);
__decorate([
    employees.Mutation
], NewEmployee.prototype, "setTeamId", void 0);
__decorate([
    employees.Mutation
], NewEmployee.prototype, "clearNewEmployee", void 0);
__decorate([
    employees.Action
], NewEmployee.prototype, "createEmployee", void 0);
NewEmployee = __decorate([
    Component({
        name: 'NewEmployee',
        components: { Modal, CountrySelect, SelectTeam }
    })
], NewEmployee);
export default NewEmployee;
