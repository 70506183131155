import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectRole from '../role/select-role.vue';
import SelectTeam from '../team/select-team.vue';
const users = namespace('users');
let NewUser = class NewUser extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    async save() {
        const result = await this.createUser();
        if (result) {
            this.showNotification = true;
        }
    }
    closeDialog() {
        this.showNotification = false;
    }
    selectRole(role) {
        this.setNewUser({ ...this.newUser, roleId: role._id });
    }
    selectTeam(team) {
        this.setNewUser({ ...this.newUser, teamId: team._id });
    }
};
__decorate([
    users.State
], NewUser.prototype, "newUser", void 0);
__decorate([
    users.Mutation
], NewUser.prototype, "setNewUser", void 0);
__decorate([
    users.Action
], NewUser.prototype, "createUser", void 0);
NewUser = __decorate([
    Component({
        name: 'NewUser',
        components: { Modal, SelectRole, SelectTeam }
    })
], NewUser);
export default NewUser;
