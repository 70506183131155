import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UserList from './user/user-list.vue';
import User from './user/user.vue';
const users = namespace('users');
const application = namespace('application');
let Users = class Users extends Vue {
    constructor() {
        super(...arguments);
        this.displayUsers = [];
        this.activeTab = 0;
        this.search = '';
        this.searchFilter = '*';
        this.isList = 0;
        this.drawer = false;
    }
    async created() {
        this.setTitle('Users Settings');
        await this.getUsersFromRemote();
        this.displayUsers = this.users;
    }
    updated() {
        this.getUsersFromRemote();
    }
    onList() {
        this.isList = 0;
    }
    onCard() {
        this.isList = 1;
    }
    openDrawer() {
        this.drawer = true;
    }
    handleUser(user) {
        this.setUser(user);
        this.openDrawer();
    }
    onUser(user) {
        this.$emit('user', user);
        this.setUser(user);
        this.drawer = true;
    }
    searchByName(args) {
        this.search = args;
        this.displayUsers = this.users.filter(user => {
            if (this.activeTab === 2) {
                return (user != null &&
                    user.firstName &&
                    this.search != null &&
                    typeof args === 'string' &&
                    user.firstName
                        .toLocaleUpperCase()
                        .indexOf(args.toLocaleUpperCase()) !== -1 &&
                    user.status === 'active');
            }
            else if (this.activeTab === 1) {
                return (user != null &&
                    user.firstName &&
                    typeof args === 'string' &&
                    user.firstName
                        .toLocaleUpperCase()
                        .indexOf(args.toLocaleUpperCase()) !== -1 &&
                    !user.status);
            }
            return (user != null &&
                ((user.firstName &&
                    typeof args === 'string' &&
                    user.firstName
                        .toLocaleUpperCase()
                        .indexOf(args.toLocaleUpperCase()) !== -1) ||
                    (user.lastName &&
                        typeof args === 'string' &&
                        user.lastName
                            .toLocaleUpperCase()
                            .indexOf(args.toLocaleUpperCase()) !== -1)) &&
                true);
        });
    }
    onChangeTab(tab) {
        this.displayUsers = this.users.filter(user => {
            if (tab === 2) {
                return (user != null &&
                    ((user.firstName &&
                        typeof this.search === 'string' &&
                        user.firstName
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                        (user.lastName &&
                            typeof this.search === 'string' &&
                            user.lastName
                                .toLocaleUpperCase()
                                .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
                    user.status === 'active');
            }
            else if (tab === 1) {
                return (user != null &&
                    ((user.firstName &&
                        typeof this.search === 'string' &&
                        user.firstName
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                        (user.lastName &&
                            typeof this.search === 'string' &&
                            user.lastName
                                .toLocaleUpperCase()
                                .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
                    !user.status);
            }
            return (user != null &&
                ((user.firstName &&
                    typeof this.search === 'string' &&
                    user.firstName
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                    (user.lastName &&
                        typeof this.search === 'string' &&
                        user.lastName
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
                true);
        });
    }
};
__decorate([
    Prop({ default: false })
], Users.prototype, "onlyDataTable", void 0);
__decorate([
    Prop({ default: 'Users' })
], Users.prototype, "title", void 0);
__decorate([
    users.State
], Users.prototype, "users", void 0);
__decorate([
    Prop({
        default: () => [
            {
                text: 'Fullname',
                value: 'firstName'
            },
            {
                text: 'Role',
                value: 'role'
            },
            {
                text: 'Mail',
                value: 'mail'
            },
            {
                text: 'Phone',
                value: 'phone'
            },
            {
                text: 'Status',
                value: 'status',
                width: '200px'
            },
            { text: '', value: 'actions' }
        ]
    })
], Users.prototype, "headers", void 0);
__decorate([
    users.Mutation
], Users.prototype, "setUser", void 0);
__decorate([
    users.Action
], Users.prototype, "getUsersFromRemote", void 0);
__decorate([
    application.Mutation
], Users.prototype, "setTitle", void 0);
Users = __decorate([
    Component({
        name: 'Users',
        components: { Header, User, UserList }
    })
], Users);
export default Users;
