import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import ToDoListItem from './to-do-list-item.vue';
let ToDoList = class ToDoList extends Vue {
    get timeOffRequests() {
        return {
            title: 'Time off requests',
            subtitle: `You have ${this.timeOffCount} time off requests to review`
        };
    }
    get requests() {
        return {
            title: 'Requests',
            subtitle: `You have ${this.requestsCount} requests to review`
        };
    }
    get payrollRequests() {
        return {
            title: 'Payroll approval',
            subtitle: 'You have the monthly payroll approval'
        };
    }
    onTimeOffRequests() {
        this.$router.push('/manager/timeoff');
    }
    onRequests() {
        this.$router.push('/manager/requests');
    }
    onPayroll() {
        this.$router.push('/manager/payroll');
    }
};
__decorate([
    Prop()
], ToDoList.prototype, "toDo", void 0);
__decorate([
    Prop()
], ToDoList.prototype, "timeOffCount", void 0);
__decorate([
    Prop()
], ToDoList.prototype, "requestsCount", void 0);
ToDoList = __decorate([
    Component({
        components: {
            ToDoListItem
        }
    })
], ToDoList);
export default ToDoList;
