import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
const requests = namespace('requests');
let HomeOfficeRequest = class HomeOfficeRequest extends Vue {
    constructor() {
        super(...arguments);
        this.firstDate = null;
        this.firstDateMenu = false;
        this.lastDate = null;
        this.lastDateMenu = false;
        this.loading = false;
        this.showNotification = false;
    }
    created() {
        this.clearNewRequest();
    }
    closeDialog() {
        this.showNotification = false;
        this.clearNewRequest();
        this.loading = false;
    }
    open() {
        this.setCategory('Home Office');
        this.setType('Demand');
    }
    async save() {
        /*  this.showNotification = true; */
        this.loading = true;
        const result = await this.createRequest();
        if (result) {
            this.showNotification = true;
        }
        this.loading = false;
    }
};
__decorate([
    requests.State
], HomeOfficeRequest.prototype, "newRequest", void 0);
__decorate([
    requests.Mutation
], HomeOfficeRequest.prototype, "clearNewRequest", void 0);
__decorate([
    requests.Mutation
], HomeOfficeRequest.prototype, "setCategory", void 0);
__decorate([
    requests.Mutation
], HomeOfficeRequest.prototype, "setType", void 0);
__decorate([
    requests.Action
], HomeOfficeRequest.prototype, "createRequest", void 0);
HomeOfficeRequest = __decorate([
    Component({
        name: 'HomeOfficeRequest',
        components: { Header, Modal }
    })
], HomeOfficeRequest);
export default HomeOfficeRequest;
