import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
let UpdateEmployeeContractType = class UpdateEmployeeContractType extends Vue {
    constructor() {
        super(...arguments);
        this.types = ['CDD', 'CDI'];
    }
};
__decorate([
    Prop()
], UpdateEmployeeContractType.prototype, "employee", void 0);
__decorate([
    Prop({ default: false })
], UpdateEmployeeContractType.prototype, "showNotification", void 0);
UpdateEmployeeContractType = __decorate([
    Component({
        name: 'UpdateEmployeeContractType',
        components: { UpdateEmployee }
    })
], UpdateEmployeeContractType);
export default UpdateEmployeeContractType;
