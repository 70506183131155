import { __decorate } from "tslib";
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
let Roles = class Roles extends VuexModule {
    constructor() {
        super(...arguments);
        this.services = [];
        this.models = [];
        this.newRole = {
            fullName: '',
            description: ''
        };
        this.role = {
            _id: '',
            fullName: '',
            description: ''
        };
        this.roles = [];
    }
    setRole(role) {
        this.role = role;
    }
    setModels(models) {
        this.models = models;
    }
    setServices(services) {
        this.services = services;
    }
    clearNewRole() {
        this.newRole = {
            fullName: '',
            description: ''
        };
    }
    setRoles(roles) {
        this.roles = roles;
    }
    async getRolesFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Role {
          findAllRoles {
            _id
            fullName
            description
            isPaydocs
            isPeople
            isPayroll
            isRequests
            isSettings
            isTimeOff
            isTimeOffManager
            isEmployee
            isPaydocsManager
            isRequestsManager
            isManager
            services {
              _id
              fullName
              description
              level
              prerequisites
              prerequisitesId
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findAllRoles)
            this.context.commit('setRoles', result.data.findAllRoles);
    }
    async createRole() {
        const { token, host } = this.context?.rootState?.auth;
        const { newRole } = this;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Role($createRoleInput: CreateRoleInput!) {
          createRole(createRoleInput: $createRoleInput) {
            _id
            fullName
            description
          }
        }
      `,
            variables: { createRoleInput: newRole },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.createRole) {
            this.context.commit('clearNewRole');
            await this.context.dispatch('getRolesFromRemote');
            return true;
        }
        return false;
    }
    async updateRole(role) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Role($updateRoleInput: UpdateRoleInput!) {
          updateRole(updateRoleInput: $updateRoleInput)
        }
      `,
            variables: { updateRoleInput: role },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.updateRole) {
            this.context.dispatch('getRolesFromRemote');
            return true;
        }
        return false;
    }
    async deleteRoleFromRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Role($_id: String!) {
          deleteRole(_id: $_id)
        }
      `,
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.deleteRole) {
            this.context.dispatch('getRolesFromRemote');
            return true;
        }
        return false;
    }
    async getServices() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Role {
          getServices {
            _id
            fullName
            description
            level
            prerequisites
            prerequisitesId
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getServices) {
            this.context.commit('setServices', result.data.getServices);
            return true;
        }
        return false;
    }
    async getModels() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Role {
          getModels {
            _id
            fullName
            services {
              _id
              fullName
              description
              level
              prerequisitesId
              prerequisites
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getModels) {
            this.context.commit('setModels', result.data.getModels);
            return true;
        }
        return false;
    }
};
__decorate([
    Mutation
], Roles.prototype, "setRole", null);
__decorate([
    Mutation
], Roles.prototype, "setModels", null);
__decorate([
    Mutation
], Roles.prototype, "setServices", null);
__decorate([
    Mutation
], Roles.prototype, "clearNewRole", null);
__decorate([
    Mutation
], Roles.prototype, "setRoles", null);
__decorate([
    Action({ rawError: true })
], Roles.prototype, "getRolesFromRemote", null);
__decorate([
    Action
], Roles.prototype, "createRole", null);
__decorate([
    Action
], Roles.prototype, "updateRole", null);
__decorate([
    Action
], Roles.prototype, "deleteRoleFromRemote", null);
__decorate([
    Action({ rawError: true })
], Roles.prototype, "getServices", null);
__decorate([
    Action({ rawError: true })
], Roles.prototype, "getModels", null);
Roles = __decorate([
    Module({ namespaced: true })
], Roles);
export default Roles;
