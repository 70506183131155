import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import Label from '@/components/label.vue';
import SelectPeriod from '@/components/select-period.vue';
const timeOff = namespace('timeOff');
let RequestTimeOff = class RequestTimeOff extends Vue {
    constructor() {
        super(...arguments);
        this.firstDate = null;
        this.lastDate = null;
        this.showNotification = false;
        this.dialog = false;
        this.loading = false;
        this.types = [
            'Vacation',
            'Sick Leave',
            'Unpaid Leave',
            'Abroad Mission',
            'Authorized Leave',
            'Local Mision',
            'Marriage',
            'Parent Death',
            'Maternity Leave',
            'Paternity Leave',
            'Circumcision',
            'Training',
            'Donation',
            'Birthday Leave'
        ];
    }
    created() {
        this.myTimeOffBalanceFromRemote();
    }
    closeDialog() {
        this.dialog = false;
        this.showNotification = false;
        this.loading = false;
    }
    async save() {
        this.loading = true;
        const result = await this.createTimeOff();
        if (result) {
            this.showNotification = true;
        }
        this.loading = false;
    }
    setStartDate(startDate) {
        this.setNewTimeOff({ ...this.newTimeOff, startDate });
    }
    setEndDate(endDate) {
        this.setNewTimeOff({ ...this.newTimeOff, endDate });
    }
};
__decorate([
    timeOff.State
], RequestTimeOff.prototype, "timeOffBalance", void 0);
__decorate([
    timeOff.State
], RequestTimeOff.prototype, "newTimeOff", void 0);
__decorate([
    timeOff.Mutation
], RequestTimeOff.prototype, "setNewTimeOff", void 0);
__decorate([
    timeOff.Action
], RequestTimeOff.prototype, "createTimeOff", void 0);
__decorate([
    timeOff.Action
], RequestTimeOff.prototype, "myTimeOffBalanceFromRemote", void 0);
RequestTimeOff = __decorate([
    Component({
        name: 'RequestTimeOff',
        components: { Header, Modal, Label, SelectPeriod }
    })
], RequestTimeOff);
export default RequestTimeOff;
