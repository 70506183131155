import { __decorate } from "tslib";
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import imageCompression from 'browser-image-compression';
let Profile = class Profile extends VuexModule {
    constructor() {
        super(...arguments);
        this.avatar = 'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light';
        this.profile = {
            userName: '',
            lastName: '',
            firstName: '',
            status: '',
            mailNotification: false,
            role: {
                _id: '',
                fullName: '',
                isEmployee: false,
                isManager: false,
                isPaydocs: false,
                isPaydocsManager: false,
                isPayroll: false,
                isPeople: false,
                isRequests: false,
                isRequestsManager: false,
                isSettings: false,
                isTimeOff: false,
                isTimeOffManager: false
            }
        };
        this.isManager = false;
    }
    setIsManager(isManager) {
        this.isManager = isManager;
    }
    setAvatar(avatar) {
        this.avatar = avatar;
    }
    setProfile(profile) {
        this.profile = profile;
    }
    async getMyProfile() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query User {
          getMyProfile {
            userName
            status
            avatar
            birthdate
            employeeId
            firstName
            lastName
            type
            mail
            phone
            mailNotification
            employee {
              teamId
            }

            role {
              fullName
              isEmployee
              isManager
              isPaydocs
              isPaydocsManager
              isPayroll
              isPeople
              isRequests
              isRequestsManager
              isSettings
              isTimeOff
              isTimeOffManager
              services {
                _id
                fullName
                description
                level
                prerequisites
                prerequisitesId
              }
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getMyProfile) {
            this.context.commit('setProfile', result.data.getMyProfile);
            this.context.commit('setAvatar', result.data.getMyProfile.avatar);
            return result.data.getMyProfile;
        }
        return false;
    }
    async updateMyAvatar(avatar) {
        const { token, host } = this.context?.rootState?.auth;
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 500
        };
        const compressedAvatar = await imageCompression(avatar, options);
        const reader = new FileReader();
        reader.onload = async (e) => {
            const result = await apolloClient.mutate({
                mutation: gql `
          mutation User($avatar: String) {
            updateMyAvatar(avatar: $avatar)
          }
        `,
                variables: { avatar: reader.result },
                context: {
                    uri: host,
                    hasUpload: true,
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            });
            if (result && result.data && result.data.updateMyAvatar) {
                this.context.commit('setAvatar', reader.result);
                return result.data.updateMyAvatar;
            }
            return false;
        };
        reader.onerror = error => {
            console.log('Error: ', error);
            return false;
        };
        reader.readAsDataURL(compressedAvatar);
        return true;
    }
    async updateMyInfo(updateMyInfoInput) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation User($updateMyInfoInput: UpdateMyInfoInput!) {
          updateMyInfo(updateMyInfoInput: $updateMyInfoInput)
        }
      `,
            variables: { updateMyInfoInput },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.updateMyInfo) {
            return result.data.updateMyInfo;
        }
        return false;
    }
    async updateMyPassword(updateMyPasswordInput) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation User($updateMyPasswordInput: UpdateMyPasswordInput!) {
          updateMyPassword(updateMyPasswordInput: $updateMyPasswordInput)
        }
      `,
            variables: { updateMyPasswordInput },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.updateMyPassword) {
            return result.data.updateMyPassword;
        }
        return false;
    }
    isPeople() {
        const isPeople = this.profile.role?.services?.filter(item => item.fullName === 'People');
        return (isPeople && isPeople.length > 0) || false;
    }
};
__decorate([
    Mutation
], Profile.prototype, "setIsManager", null);
__decorate([
    Mutation
], Profile.prototype, "setAvatar", null);
__decorate([
    Mutation
], Profile.prototype, "setProfile", null);
__decorate([
    Action
], Profile.prototype, "getMyProfile", null);
__decorate([
    Action
], Profile.prototype, "updateMyAvatar", null);
__decorate([
    Action
], Profile.prototype, "updateMyInfo", null);
__decorate([
    Action
], Profile.prototype, "updateMyPassword", null);
__decorate([
    Action
], Profile.prototype, "isPeople", null);
Profile = __decorate([
    Module({ namespaced: true })
], Profile);
export default Profile;
