import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Label from '@/components/label.vue';
let SelectPeriod = class SelectPeriod extends Vue {
    constructor() {
        super(...arguments);
        this.valid = false;
        this.startAM = true;
        this.startPM = true;
        this.endAM = true;
        this.endPM = true;
        this.firstDateMenu = false;
        this.lastDateMenu = false;
        this.startDateDisplay = '';
        this.endDateDisplay = '';
        this.checkboxRules = [
            () => (this.startDateDisplay === this.endDateDisplay &&
                this.startAM !== this.endPM) ||
                'One must be checked'
        ];
    }
    get showStartPM() {
        return (this.startDateDisplay && this.startDateDisplay !== this.endDateDisplay);
    }
    get showEndAM() {
        return this.endDateDisplay && this.startDateDisplay !== this.endDateDisplay;
    }
    handleDate() {
        if (this.startDateDisplay &&
            this.endDateDisplay &&
            this.endDateDisplay === this.startDateDisplay) {
            if (this.startAM && !this.endPM) {
                const start = new Date(this.startDateDisplay);
                const end = new Date(this.endDateDisplay);
                start.setHours(8);
                end.setHours(12);
                this.$emit('start', start);
                this.$emit('end', end);
            }
            else if (!this.startAM && this.endPM) {
                const start = new Date(this.startDateDisplay);
                const end = new Date(this.endDateDisplay);
                start.setHours(13);
                end.setHours(17);
                this.$emit('start', start);
                this.$emit('end', end);
            }
            else {
                const start = new Date(this.startDateDisplay);
                const end = new Date(this.endDateDisplay);
                start.setHours(8);
                end.setHours(17);
                this.$emit('start', start);
                this.$emit('end', end);
            }
        }
        else if (this.startDateDisplay &&
            this.endDateDisplay &&
            this.endDateDisplay !== this.startDateDisplay) {
            if (this.startAM && this.endPM) {
                const start = new Date(this.startDateDisplay);
                const end = new Date(this.endDateDisplay);
                start.setHours(8);
                end.setHours(17);
                this.$emit('start', start);
                this.$emit('end', end);
            }
            if (this.startAM && !this.endPM) {
                const start = new Date(this.startDateDisplay);
                const end = new Date(this.endDateDisplay);
                start.setHours(8);
                end.setHours(12);
                this.$emit('start', start);
                this.$emit('end', end);
            }
            else if (!this.startAM && this.endPM) {
                const start = new Date(this.startDateDisplay);
                const end = new Date(this.endDateDisplay);
                start.setHours(13);
                end.setHours(17);
                this.$emit('start', start);
                this.$emit('end', end);
            }
            else if (!this.startAM && !this.endPM) {
                const start = new Date(this.startDateDisplay);
                const end = new Date(this.endDateDisplay);
                start.setHours(13);
                end.setHours(12);
                this.$emit('start', start);
                this.$emit('end', end);
            }
        }
        else if (this.startDateDisplay && this.startAM) {
            const start = new Date(this.startDateDisplay);
            start.setHours(8);
            this.$emit('start', start);
        }
        else if (this.startDateDisplay && !this.startAM) {
            const start = new Date(this.startDateDisplay);
            start.setHours(13);
            this.$emit('start', start);
        }
        else if (this.endDateDisplay && this.endPM) {
            const end = new Date(this.endDateDisplay);
            end.setHours(17);
            this.$emit('end', end);
        }
        else if (this.endDateDisplay && !this.endPM) {
            const end = new Date(this.endDateDisplay);
            end.setHours(12);
            this.$emit('end', end);
        }
    }
};
SelectPeriod = __decorate([
    Component({ name: 'SelectPeriod', components: { Label } })
], SelectPeriod);
export default SelectPeriod;
