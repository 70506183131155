import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Header = class Header extends Vue {
};
__decorate([
    Prop()
], Header.prototype, "text", void 0);
Header = __decorate([
    Component
], Header);
export default Header;
