import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue';
import SelectEmployee from './../components/select-employee.vue';
import SelectCategory from './../components/select-category.vue';
import UploadPaydoc from './../components/upload-paydoc.vue';
import { namespace } from 'vuex-class';
import AddDocument from '../components/add-document.vue';
import Payroll from '../components/payroll.vue';
import PaydocsTracker from '../components/paydocs-tracker.vue';
import AddPayroll from '../components/add-payroll.vue';
import PaydocsRequests from '../components/paydocs-requests.vue';
const paydocs = namespace('paydocs');
const application = namespace('application');
const profile = namespace('profile');
let PaydocsManager = class PaydocsManager extends Vue {
    constructor() {
        super(...arguments);
        this.drawer = false;
        this.employee = {};
        this.paydoc = [];
        this.period = '';
        this.category = '';
        this.requestSelected = {};
    }
    created() {
        this.setPaydocs([]);
        this.setIsNavigation(true);
        this.setTitle('Pay');
    }
    async uploadSuccess() {
        this.employee = {};
        this.category = '';
        this.paydoc = [];
        await this.getPaydocsRequestsForMeFromRemote();
    }
    onRequest(request) {
        this.drawer = true;
        this.requestSelected = request;
    }
    setEmployee(employee) {
        this.employee = { ...employee };
        this.getPaydocsFromRemote(employee._id);
    }
    setCategory(category) {
        this.category = category;
    }
    setPeriod(period) {
        this.period = period;
    }
    setPaydoc(paydoc) {
        this.paydoc = paydoc;
    }
    showItem(fileId) {
        this.downloadPaydoc(fileId);
    }
    is(service) {
        const exist = this.profile.role?.services?.filter(item => item.fullName === service);
        return exist && exist.length > 0;
    }
};
__decorate([
    paydocs.State
], PaydocsManager.prototype, "paydocs", void 0);
__decorate([
    profile.State
], PaydocsManager.prototype, "profile", void 0);
__decorate([
    paydocs.Mutation
], PaydocsManager.prototype, "setPaydocs", void 0);
__decorate([
    paydocs.Action
], PaydocsManager.prototype, "getPaydocsFromRemote", void 0);
__decorate([
    paydocs.Action
], PaydocsManager.prototype, "getPaydocsRequestsForMeFromRemote", void 0);
__decorate([
    paydocs.Action
], PaydocsManager.prototype, "downloadPaydoc", void 0);
__decorate([
    application.Mutation
], PaydocsManager.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], PaydocsManager.prototype, "setTitle", void 0);
PaydocsManager = __decorate([
    Component({
        name: 'PaydocsManager',
        components: {
            Header,
            UploadPaydoc,
            SelectEmployee,
            SelectCategory,
            AddDocument,
            AddPayroll,
            Payroll,
            PaydocsRequests,
            PaydocsTracker
        }
    })
], PaydocsManager);
export default PaydocsManager;
