import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectUser from './select-user.vue';
const teams = namespace('teams');
let ChooseLeader = class ChooseLeader extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    onSelectUser(user) {
        this.setTeam({ ...this.team, leaderId: user._id });
    }
    async save() {
        if (this.team._id && this.team.leaderId) {
            const result = await this.updateTeam(this.team);
            if (result) {
                this.showNotification = true;
            }
        }
    }
    closeDialog() {
        this.showNotification = false;
    }
};
__decorate([
    Prop()
], ChooseLeader.prototype, "team", void 0);
__decorate([
    teams.Mutation
], ChooseLeader.prototype, "setTeam", void 0);
__decorate([
    teams.Action
], ChooseLeader.prototype, "updateTeam", void 0);
ChooseLeader = __decorate([
    Component({
        name: 'ChooseLeader',
        components: { Modal, SelectUser }
    })
], ChooseLeader);
export default ChooseLeader;
