import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Calendar from '@/components/calendar.vue';
import Header from '@/components/header.vue';
import TimelineItem from './../components/TimelineItem.vue';
import ToDoListItem from './../components/to-do-list-item.vue';
import EmployeesOnHoliday from './../components/employees-on-holiday.vue';
import { namespace } from 'vuex-class';
import Upcoming from '../components/Upcoming.vue';
import Celebrations from '../components/celebrations.vue';
import Profile from '@/components/profile.vue';
import MyLastPayslip from '../components/my-last-payslip.vue';
import dtFormat from '@/plugins/filters/dateFilter';
const overview = namespace('overview');
const profile = namespace('profile');
const application = namespace('application');
const timeOff = namespace('timeOff');
let Overview = class Overview extends Vue {
    constructor() {
        super(...arguments);
        this.events = [];
    }
    get getEvents() {
        return this.timeOff
            .filter(item => item.type !== 'Paid Leave Rights')
            .map(item => {
            return {
                name: item.type,
                start: dtFormat(item.startDate?.toString() || '', 'YYYY-MM-DD'),
                end: dtFormat(item.endDate?.toString() || '', 'YYYY-MM-DD'),
                timed: false,
                color: item.status === 'approved' ? 'success' : 'primary'
            };
        });
    }
    async created() {
        await this.getTimeLineFromRemote();
        this.setIsNavigation(true);
        this.setTitle(`Good afternoon, ${this.profile.firstName}`);
        await this.getMyTimeOffFromRemote();
    }
};
__decorate([
    overview.State
], Overview.prototype, "toDo", void 0);
__decorate([
    overview.State
], Overview.prototype, "holiday", void 0);
__decorate([
    overview.State
], Overview.prototype, "timeline", void 0);
__decorate([
    profile.State
], Overview.prototype, "profile", void 0);
__decorate([
    timeOff.State
], Overview.prototype, "timeOff", void 0);
__decorate([
    overview.Action
], Overview.prototype, "getTimeLineFromRemote", void 0);
__decorate([
    timeOff.Action
], Overview.prototype, "getMyTimeOffFromRemote", void 0);
__decorate([
    application.Mutation
], Overview.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], Overview.prototype, "setTitle", void 0);
Overview = __decorate([
    Component({
        components: {
            Calendar,
            Header,
            TimelineItem,
            ToDoListItem,
            EmployeesOnHoliday,
            Upcoming,
            Celebrations,
            Profile,
            MyLastPayslip
        }
    })
], Overview);
export default Overview;
