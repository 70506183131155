import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
import { namespace } from 'vuex-class';
const employees = namespace('employees');
let UpdateEmployeeHiredate = class UpdateEmployeeHiredate extends Vue {
    constructor() {
        super(...arguments);
        this.hiredateMenu = false;
    }
    updateDate(hiredate) {
        this.$emit('hiredate', hiredate);
        this.hiredateMenu = false;
    }
};
__decorate([
    Prop()
], UpdateEmployeeHiredate.prototype, "employee", void 0);
__decorate([
    Prop({ default: false })
], UpdateEmployeeHiredate.prototype, "showNotification", void 0);
UpdateEmployeeHiredate = __decorate([
    Component({
        name: 'UpdateEmployeeHiredate',
        components: { UpdateEmployee }
    })
], UpdateEmployeeHiredate);
export default UpdateEmployeeHiredate;
