import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const application = namespace('application');
let ManuAppBar = class ManuAppBar extends Vue {
    constructor() {
        super(...arguments);
        this.showMenu = false;
        this.itemsAdmin = [
            {
                action: 'mdi-cog',
                title: 'Settings',
                path: '/manager/settings',
                items: [],
                enable: true
            }
        ];
        this.drawer = false;
    }
    get menu() {
        const m = [
            {
                action: 'mdi-view-dashboard',
                title: 'Overview',
                noAction: false,
                path: '/manager/overview',
                items: [],
                enable: this.profile?.role?.isManager || false,
                manager: true
            },
            {
                action: 'mdi-account-group',
                title: 'People',
                path: '/employees',
                items: [],
                enable: this.profile?.role?.isPeople || false,
                manager: true
            },
            {
                action: 'mdi-file-document-multiple-outline',
                title: 'Paydoc(s)',
                path: '/manager/paydocs',
                items: [],
                enable: this.profile?.role?.isPaydocsManager || false,
                manager: true
            },
            {
                action: 'mdi-sitemap',
                title: 'Payroll',
                path: '/payroll',
                items: [],
                enable: this.profile?.role?.isPayroll || false,
                manager: true
            },
            {
                action: 'mdi-calendar-multiselect',
                title: 'Time Off',
                path: '/manager/timeOff',
                items: [],
                enable: this.profile?.role?.isTimeOffManager || false,
                manager: true
            },
            {
                action: 'mdi-frequently-asked-questions',
                title: 'Request(s)',
                path: '/manager/requests',
                items: [],
                enable: this.profile?.role?.isRequestsManager || false,
                manager: true
            },
            {
                action: 'mdi-cog',
                title: 'Settings',
                path: '/manager/settings',
                items: [],
                enable: this.profile?.role?.isSettings || false,
                manager: true
            },
            {
                action: 'mdi-view-dashboard',
                title: 'Overview',
                noAction: false,
                path: '/overview',
                items: [],
                enable: true,
                manager: false
            },
            {
                action: 'mdi-information',
                title: 'My Info',
                path: '/myInfo',
                items: [],
                enable: false,
                manager: false
            },
            {
                action: 'mdi-file-document-multiple-outline',
                title: 'Paydoc(s)',
                path: '/paydocs',
                items: [],
                enable: this.profile?.role?.isPaydocs || false,
                manager: false
            },
            {
                action: 'mdi-calendar-multiselect',
                title: 'Timeoff',
                path: '/timeOff',
                items: [],
                enable: this.profile?.role?.isTimeOff || false,
                manager: false
            },
            {
                action: 'mdi-frequently-asked-questions',
                title: 'Request(s)',
                path: '/requests',
                items: [],
                enable: this.profile?.role?.isRequests || false,
                manager: false
            }
        ];
        return m;
    }
    onButton(path) {
        this.$router.push(path);
    }
};
__decorate([
    application.State
], ManuAppBar.prototype, "mode", void 0);
__decorate([
    Prop()
], ManuAppBar.prototype, "isMenu", void 0);
__decorate([
    Prop()
], ManuAppBar.prototype, "profile", void 0);
__decorate([
    Prop()
], ManuAppBar.prototype, "items", void 0);
ManuAppBar = __decorate([
    Component({
        name: 'ManuAppBar'
    })
], ManuAppBar);
export default ManuAppBar;
