import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
import SelectCategory from './select-category.vue';
import SelectPeriod from './select-period.vue';
import { namespace } from 'vuex-class';
const paydocs = namespace('paydocs');
let RequestDocument = class RequestDocument extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
        this.fullName = '';
        this.category = '';
        this.period = '';
        this.loading = false;
    }
    closeDialog() {
        this.showNotification = false;
        this.loading = false;
    }
    async save() {
        const { category, fullName, period } = this;
        if (period && period !== '') {
            this.loading = true;
            const result = await this.createRequestPaydoc({
                category,
                fullName,
                period: period
            });
            this.loading = false;
            if (result) {
                this.success(true);
            }
        }
        else if (category === 'Other' || category === 'Tax') {
            this.loading = true;
            const result = await this.createRequestPaydoc({
                category,
                fullName
            });
            this.loading = false;
            if (result) {
                this.success(true);
            }
        }
    }
    async success(args) {
        this.showNotification = true;
        await this.getMyPaydocsFromRemote();
        await this.getMyPayslipsFromRemote();
        this.$emit('success', args);
    }
    setPeriod(period) {
        this.period = period;
    }
    setCategory(category) {
        this.category = category;
        if (category === 'Payslip')
            this.fullName = 'Payslip';
    }
};
__decorate([
    paydocs.Action
], RequestDocument.prototype, "createRequestPaydoc", void 0);
__decorate([
    paydocs.Action
], RequestDocument.prototype, "getMyPaydocsFromRemote", void 0);
__decorate([
    paydocs.Action
], RequestDocument.prototype, "getMyPayslipsFromRemote", void 0);
RequestDocument = __decorate([
    Component({
        name: 'RequestDocument',
        components: { Header, Modal, SelectCategory, SelectPeriod }
    })
], RequestDocument);
export default RequestDocument;
