import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const register = namespace('register');
const application = namespace('application');
let Register = class Register extends Vue {
    constructor() {
        super(...arguments);
        this.dateOfHireMenu = false;
        this.showPassword = false;
        this.showConfirmPassword = false;
        this.confirmPassword = '';
        this.valid = false;
        this.mailRules = [
            (v) => !v ||
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                'E-mail must be valid'
        ];
        this.passwordRules = [
            (value) => (!!value && value.length > 7) || 'Un minimum de 8 caractères'
        ];
    }
    created() {
        this.setIsNavigation(false);
        this.setTitle('');
    }
};
__decorate([
    register.State
], Register.prototype, "newUser", void 0);
__decorate([
    application.State
], Register.prototype, "logoPath", void 0);
__decorate([
    application.Mutation
], Register.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], Register.prototype, "setTitle", void 0);
__decorate([
    register.Mutation
], Register.prototype, "setNewUser", void 0);
__decorate([
    register.Action
], Register.prototype, "registerOnRemote", void 0);
Register = __decorate([
    Component({
        name: 'Register'
    })
], Register);
export default Register;
