import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Value from '@/components/value.vue';
const overview = namespace('overview');
let Celebrations = class Celebrations extends Vue {
    constructor() {
        super(...arguments);
        this.indexCelebration = 0;
    }
    async created() {
        await this.getNextCelebrations();
    }
};
__decorate([
    overview.State
], Celebrations.prototype, "nextCelebrations", void 0);
__decorate([
    overview.Action
], Celebrations.prototype, "getNextCelebrations", void 0);
Celebrations = __decorate([
    Component({
        name: 'Celebrations',
        components: { Value }
    })
], Celebrations);
export default Celebrations;
