import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EmployeeListItem from './employee-list-item.vue';
const overview = namespace('overview');
let EmployeesOnHoliday = class EmployeesOnHoliday extends Vue {
    async created() {
        await this.getNextHolidaysFromRemote();
    }
};
__decorate([
    overview.State
], EmployeesOnHoliday.prototype, "nextHolidays", void 0);
__decorate([
    overview.Action
], EmployeesOnHoliday.prototype, "getNextHolidaysFromRemote", void 0);
EmployeesOnHoliday = __decorate([
    Component({ name: 'EmployeesOnHoliday', components: { EmployeeListItem } })
], EmployeesOnHoliday);
export default EmployeesOnHoliday;
