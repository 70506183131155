import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateTeam from './update-team.vue';
let UpdateTeamFullName = class UpdateTeamFullName extends Vue {
};
__decorate([
    Prop()
], UpdateTeamFullName.prototype, "team", void 0);
__decorate([
    Prop({ default: false })
], UpdateTeamFullName.prototype, "showNotification", void 0);
UpdateTeamFullName = __decorate([
    Component({
        name: 'UpdateTeamFullName',
        components: { UpdateTeam }
    })
], UpdateTeamFullName);
export default UpdateTeamFullName;
