import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Update from './update.vue';
let UpdateFirstNameAndLastName = class UpdateFirstNameAndLastName extends Vue {
};
__decorate([
    Prop()
], UpdateFirstNameAndLastName.prototype, "user", void 0);
__decorate([
    Prop({ default: false })
], UpdateFirstNameAndLastName.prototype, "showNotification", void 0);
UpdateFirstNameAndLastName = __decorate([
    Component({
        name: 'UpdateFirstNameAndLastName',
        components: { Update }
    })
], UpdateFirstNameAndLastName);
export default UpdateFirstNameAndLastName;
