import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const items = namespace('items');
let Items = class Items extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Ref',
                value: 'ref'
            },
            {
                text: 'Fullname',
                value: 'fullName'
            },
            {
                text: 'Qtt',
                value: 'stock'
            },
            {
                text: 'Model',
                value: 'model'
            },
            {
                text: 'Warehouse',
                value: 'warehouse'
            },
            {
                text: '',
                align: 'end',
                value: 'actions',
                sortable: false
            }
        ];
        this.types = [
            {
                text: 'Sale',
                value: 'sale'
            },
            {
                text: 'Stock correction',
                value: 'stock-correction'
            },
            {
                text: 'Expired product',
                value: 'expired-product'
            },
            {
                text: 'Transfer',
                value: 'transfer'
            }
        ];
        this.itemSelected = {
            fullName: '',
            modelId: '',
            warehouseId: '',
            stock: 0
        };
        this.stock = 0;
        this.removeDialog = false;
        this.editDialog = false;
    }
    created() {
        this.pullItems();
    }
    onRemove(item) {
        this.selectItem(item);
        this.itemSelected = item;
        this.removeDialog = true;
    }
    onEdit(item) {
        this.selectItem(item);
        this.itemSelected = item;
        this.editDialog = true;
    }
    onConfirmRemove() {
        this.removeItem(this.itemSelected);
        this.removeDialog = false;
    }
    onConfirmEdit() {
        this.saveStock(this.stock);
        this.updateItemOnRemote();
        this.editDialog = false;
    }
    onCancel() {
        this.removeDialog = false;
        this.editDialog = false;
    }
    decrement() {
        this.stock -= 1;
    }
    increment() {
        this.stock += 1;
    }
};
__decorate([
    items.State
], Items.prototype, "items", void 0);
__decorate([
    items.Action
], Items.prototype, "pullItems", void 0);
__decorate([
    items.Action
], Items.prototype, "updateItemOnRemote", void 0);
__decorate([
    items.Action
], Items.prototype, "removeItem", void 0);
__decorate([
    items.Action
], Items.prototype, "selectItem", void 0);
__decorate([
    items.Action
], Items.prototype, "saveStock", void 0);
Items = __decorate([
    Component({
        name: 'Items'
    })
], Items);
export default Items;
