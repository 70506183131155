import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
import SelectCategory from './select-category.vue';
import UploadPaydoc from './upload-paydoc.vue';
import SelectEmployee from './select-employee.vue';
import SelectPeriod from './select-period.vue';
let AddDocument = class AddDocument extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    closeDialog() {
        this.showNotification = false;
    }
    async save() {
        /*     if (result) { */
        this.showNotification = true;
        /*    } */
    }
    onSuccess(args) {
        this.showNotification = true;
        this.$emit('success', args);
    }
};
__decorate([
    Prop()
], AddDocument.prototype, "employee", void 0);
__decorate([
    Prop()
], AddDocument.prototype, "category", void 0);
__decorate([
    Prop({ default: '' })
], AddDocument.prototype, "period", void 0);
__decorate([
    Prop()
], AddDocument.prototype, "paydoc", void 0);
AddDocument = __decorate([
    Component({
        name: 'AddDocument',
        components: {
            Header,
            Modal,
            SelectCategory,
            SelectEmployee,
            SelectPeriod,
            UploadPaydoc
        }
    })
], AddDocument);
export default AddDocument;
