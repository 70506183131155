import { __decorate } from "tslib";
import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
let Application = class Application extends VuexModule {
    constructor() {
        super(...arguments);
        this.isNavigation = false;
        this.isMenuHome = false;
        this.isDarkMode = false;
        this.logoPath = process.env.VUE_APP_LOGO_PATH;
        this.mode = '';
        this.title = '';
    }
    setMode(mode) {
        this.mode = mode;
    }
    setTitle(title) {
        this.title = title;
    }
    setIsNavigation(isNavigation) {
        this.isNavigation = isNavigation;
    }
    setIsMenuHome(isMenuHome) {
        this.isMenuHome = isMenuHome;
    }
    setIsDarkMode(isDarkMode) {
        this.isDarkMode = isDarkMode;
    }
};
__decorate([
    Mutation
], Application.prototype, "setMode", null);
__decorate([
    Mutation
], Application.prototype, "setTitle", null);
__decorate([
    Mutation
], Application.prototype, "setIsNavigation", null);
__decorate([
    Mutation
], Application.prototype, "setIsMenuHome", null);
__decorate([
    Mutation
], Application.prototype, "setIsDarkMode", null);
Application = __decorate([
    Module({ namespaced: true })
], Application);
export default Application;
