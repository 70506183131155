import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
let Auth = class Auth extends VuexModule {
    constructor() {
        super(...arguments);
        this.token = '';
        this.host = '';
        this.isConnected = false;
    }
    setIsConnected(isConnected) {
        this.isConnected = isConnected;
    }
    setHost(host) {
        this.host = host;
    }
    setToken(token) {
        this.token = token;
    }
    saveHost(host) {
        this.context.commit('setHost', host);
    }
    saveToken(token) {
        this.context.commit('setToken', token);
    }
    saveIsConnected(isConnected) {
        this.context.commit('setIsConnected', isConnected);
    }
};
__decorate([
    Mutation
], Auth.prototype, "setIsConnected", null);
__decorate([
    Mutation
], Auth.prototype, "setHost", null);
__decorate([
    Mutation
], Auth.prototype, "setToken", null);
__decorate([
    Action
], Auth.prototype, "saveHost", null);
__decorate([
    Action
], Auth.prototype, "saveToken", null);
__decorate([
    Action
], Auth.prototype, "saveIsConnected", null);
Auth = __decorate([
    Module({ namespaced: true })
], Auth);
export default Auth;
