import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import SelectEmployee from './select-employee.vue';
import { namespace } from 'vuex-class';
const paydocs = namespace('paydocs');
let PaydocsTracker = class PaydocsTracker extends Vue {
    constructor() {
        super(...arguments);
        this.employee = {};
        this.headers = [
            {
                text: 'Employee',
                sortable: true,
                value: 'fullName'
            },
            {
                text: 'Category',
                value: 'category'
            },
            {
                text: 'Period',
                value: 'period'
            },
            {
                text: 'Date',
                value: 'creationDate'
            }
        ];
    }
    async setEmployee(employee) {
        this.employee = { ...employee };
        await this.getPaydocsFromRemote(employee._id);
    }
};
__decorate([
    paydocs.State
], PaydocsTracker.prototype, "paydocs", void 0);
__decorate([
    paydocs.Action
], PaydocsTracker.prototype, "getPaydocsFromRemote", void 0);
PaydocsTracker = __decorate([
    Component({
        name: 'PaydocsTracker',
        components: { SelectEmployee }
    })
], PaydocsTracker);
export default PaydocsTracker;
