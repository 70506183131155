import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import EmployeeListItem from './employee-list-item.vue';
let EmployeeList = class EmployeeList extends Vue {
    onEmployee(employee) {
        this.$emit('employee', employee);
    }
};
__decorate([
    Prop()
], EmployeeList.prototype, "employees", void 0);
__decorate([
    Prop({ default: false })
], EmployeeList.prototype, "active", void 0);
EmployeeList = __decorate([
    Component({
        name: 'EmployeeList',
        components: {
            EmployeeListItem
        }
    })
], EmployeeList);
export default EmployeeList;
