import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const login = namespace('login');
const application = namespace('application');
let ResetPassword = class ResetPassword extends Vue {
    constructor() {
        super(...arguments);
        this.valid = false;
        this.loading = false;
        this.password = '';
        this.confirmPassword = '';
        this.showPassword = false;
        this.showConfirmPassword = false;
        this.isPasswordUpdated = false;
        this.passwordRules = [
            (value) => (!!value && value.length > 7) || 'Un minimum de 8 caractères'
        ];
    }
    created() {
        this.setIsNavigation(false);
    }
    async onContinue() {
        this.loading = true;
        if (this.isPasswordUpdated) {
            this.$router.push('/login');
        }
        else {
            const token = this.$route.params.token;
            this.isPasswordUpdated = await this.updatePassword({
                password: this.password,
                token
            });
        }
        this.loading = false;
    }
};
__decorate([
    application.State
], ResetPassword.prototype, "logoPath", void 0);
__decorate([
    application.Mutation
], ResetPassword.prototype, "setIsNavigation", void 0);
__decorate([
    login.Action
], ResetPassword.prototype, "updatePassword", void 0);
ResetPassword = __decorate([
    Component({
        name: 'ResetPassword'
    })
], ResetPassword);
export default ResetPassword;
