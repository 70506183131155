import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
let UpdateTeam = class UpdateTeam extends Vue {
};
__decorate([
    Prop({ default: false })
], UpdateTeam.prototype, "showNotification", void 0);
UpdateTeam = __decorate([
    Component({
        name: 'UpdateTeam',
        components: { Modal }
    })
], UpdateTeam);
export default UpdateTeam;
