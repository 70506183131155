import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const application = namespace('application');
let Subscription = class Subscription extends Vue {
};
__decorate([
    application.Mutation
], Subscription.prototype, "setIsNavigation", void 0);
Subscription = __decorate([
    Component({ name: 'Subscription' })
], Subscription);
export default Subscription;
