import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
let Overview = class Overview extends VuexModule {
    constructor() {
        super(...arguments);
        this.timeline = [];
        this.toDo = [];
        this.nextHolidays = [];
        this.nextCelebrations = [];
    }
    setTimeLine(timeline) {
        this.timeline = timeline;
    }
    setToDo(toDo) {
        this.toDo = toDo;
    }
    setNextCelebrations(nextCelebrations) {
        this.nextCelebrations = nextCelebrations;
    }
    setNextHolidays(nextHolidays) {
        this.nextHolidays = nextHolidays;
    }
    getTimeLineFromRemote() {
        const result = [
            {
                date: 'SEP 13, 2020',
                title: 'SALARY CHANGE',
                subtitle: "Manager John set Erica Cruz's hourly rate to 25€ effective on May 5,2013.",
                avatar: 'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
            },
            {
                date: 'SEP 13, 2020',
                title: 'SALARY CHANGE',
                subtitle: "Manager John set Erica Cruz's hourly rate to 25€ effective on May 5,2013.",
                avatar: 'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
            },
            {
                date: 'SEP 13, 2020',
                title: 'SALARY CHANGE',
                subtitle: "Manager John set Erica Cruz's hourly rate to 25€ effective on May 5,2013.",
                avatar: 'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
            }
        ];
        this.context.commit('setTimeLine', result);
    }
    async getNextHolidaysFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query TimeOff {
          getNextHolidays {
            firstName
            lastName
            startDate
            daysTillHoliday
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getNextHolidays)
            this.context.commit('setNextHolidays', result.data.getNextHolidays);
    }
    async getNextCelebrations() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Employee {
          getNextCelebrations {
            firstName
            lastName
            birthdate
            daysTillBirthday
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getNextCelebrations)
            this.context.commit('setNextCelebrations', result.data.getNextCelebrations);
    }
};
__decorate([
    Mutation
], Overview.prototype, "setTimeLine", null);
__decorate([
    Mutation
], Overview.prototype, "setToDo", null);
__decorate([
    Mutation
], Overview.prototype, "setNextCelebrations", null);
__decorate([
    Mutation
], Overview.prototype, "setNextHolidays", null);
__decorate([
    Action
], Overview.prototype, "getTimeLineFromRemote", null);
__decorate([
    Action
], Overview.prototype, "getNextHolidaysFromRemote", null);
__decorate([
    Action
], Overview.prototype, "getNextCelebrations", null);
Overview = __decorate([
    Module({ namespaced: true })
], Overview);
export default Overview;
