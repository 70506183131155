import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Label from '@/components/label.vue';
import Value from '@/components/value.vue';
import LabelAndValue from '@/components/label-and-value.vue';
import UserTeams from './user-teams.vue';
import AddTeam from '../team/add-team.vue';
import ChooseEmployee from '../employee/choose-employee.vue';
import UpdateUserRole from './update-user-role.vue';
import UpdateUserMail from './update-user-mail.vue';
import UpdateUserFirstNameAndLastName from './update-user-firstname-and-lastname.vue';
import DeleteUser from './delete-user.vue';
const users = namespace('users');
let User = class User extends Vue {
    constructor() {
        super(...arguments);
        this.isActivate = false;
        this.showNotification = false;
    }
    onActivate(activate) {
        if (this.user && this.user._id)
            activate
                ? this.activeAccount(this.user._id)
                : this.disableAccount(this.user._id);
    }
    async cancelUpdate() {
        if (this.user && this.user._id)
            await this.getUserFromRemote(this.user._id);
    }
    close() {
        this.showNotification = false;
    }
    async updateUserFirstNameAndLastName() {
        if (this.user &&
            this.user._id &&
            this.user.firstName &&
            this.user.lastName) {
            const isUpdated = await this.updateUser({
                _id: this.user._id,
                firstName: this.user.firstName,
                lastName: this.user.lastName
            });
            if (isUpdated) {
                this.showNotification = true;
            }
        }
    }
    async updateUserRole() {
        if (this.user && this.user._id && this.user.roleId) {
            const isUpdated = await this.updateUser({
                _id: this.user._id,
                roleId: this.user.roleId
            });
            if (isUpdated) {
                this.showNotification = true;
            }
        }
    }
    async updateUserMail() {
        if (this.user && this.user._id && this.user.mail) {
            const isUpdated = await this.updateUser({
                _id: this.user._id,
                mail: this.user.mail
            });
            if (isUpdated) {
                this.showNotification = true;
            }
        }
    }
};
__decorate([
    users.State
], User.prototype, "user", void 0);
__decorate([
    users.Action
], User.prototype, "activeAccount", void 0);
__decorate([
    users.Action
], User.prototype, "disableAccount", void 0);
__decorate([
    users.Action
], User.prototype, "getUserFromRemote", void 0);
__decorate([
    users.Action
], User.prototype, "updateUser", void 0);
User = __decorate([
    Component({
        name: 'User',
        components: {
            AddTeam,
            ChooseEmployee,
            DeleteUser,
            Header,
            LabelAndValue,
            UserTeams,
            Label,
            Value,
            UpdateUserRole,
            UpdateUserMail,
            UpdateUserFirstNameAndLastName
        }
    })
], User);
export default User;
