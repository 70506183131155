import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import Warehouses from '../warehouses/views/warehouses.vue';
import Models from '../models/views/models.vue';
import Items from '../items/views/items.vue';
let Stocks = class Stocks extends Vue {
};
Stocks = __decorate([
    Component({
        name: 'Stocks',
        components: {
            Header,
            Warehouses,
            Models,
            Items
        }
    })
], Stocks);
export default Stocks;
