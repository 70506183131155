import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let UploadDocument = class UploadDocument extends Vue {
    upload(event) {
        if (event !== []) {
            this.$emit('file', event);
        }
    }
};
__decorate([
    Prop()
], UploadDocument.prototype, "disabled", void 0);
__decorate([
    Prop()
], UploadDocument.prototype, "file", void 0);
UploadDocument = __decorate([
    Component({
        name: 'UploadDocument'
    })
], UploadDocument);
export default UploadDocument;
