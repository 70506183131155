import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue';
import { namespace } from 'vuex-class';
import RequestDocuemt from './../components/request-document.vue';
const paydocs = namespace('paydocs');
const application = namespace('application');
const profile = namespace('profile');
let Paydocs = class Paydocs extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.activeTab = 0;
        this.headers = [
            {
                text: 'Fullname',
                align: 'start',
                sortable: true,
                value: 'fullName'
            },
            {
                text: 'Period',
                value: 'period'
            },
            {
                text: 'Category',
                align: 'start',
                sortable: true,
                value: 'category'
            },
            {
                text: 'Date',
                value: 'creationDate'
            },
            {
                text: 'Status',
                value: 'delivred',
                width: '150'
            },
            /*   { text: 'Check Amount', value: 'checkAmount' }, */
            { text: 'Action', value: 'actions', sortable: false }
        ];
    }
    async created() {
        this.setIsNavigation(true);
        this.setTitle('Pay');
        await this.getMyPayslipsFromRemote();
        await this.getMyPaydocsFromRemote();
    }
    async showItem(fileId) {
        await this.downloadPaydoc(fileId);
    }
};
__decorate([
    profile.State
], Paydocs.prototype, "profile", void 0);
__decorate([
    paydocs.State
], Paydocs.prototype, "payslips", void 0);
__decorate([
    paydocs.State
], Paydocs.prototype, "paydocs", void 0);
__decorate([
    paydocs.State
], Paydocs.prototype, "employeeId", void 0);
__decorate([
    paydocs.Action
], Paydocs.prototype, "downloadPaydoc", void 0);
__decorate([
    paydocs.Action
], Paydocs.prototype, "getMyPayslipsFromRemote", void 0);
__decorate([
    paydocs.Action
], Paydocs.prototype, "getMyPaydocsFromRemote", void 0);
__decorate([
    application.Mutation
], Paydocs.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], Paydocs.prototype, "setTitle", void 0);
Paydocs = __decorate([
    Component({
        name: 'Paydocs',
        components: { Header, RequestDocuemt }
    })
], Paydocs);
export default Paydocs;
