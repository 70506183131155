import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
let UpdateEmployeeJobTitle = class UpdateEmployeeJobTitle extends Vue {
};
__decorate([
    Prop()
], UpdateEmployeeJobTitle.prototype, "employee", void 0);
__decorate([
    Prop({ default: false })
], UpdateEmployeeJobTitle.prototype, "showNotification", void 0);
UpdateEmployeeJobTitle = __decorate([
    Component({
        name: 'UpdateEmployeeJobTitle',
        components: { UpdateEmployee }
    })
], UpdateEmployeeJobTitle);
export default UpdateEmployeeJobTitle;
