import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const items = namespace('items');
const warehouses = namespace('warehouses');
const models = namespace('models');
let NewItems = class NewItems extends Vue {
    constructor() {
        super(...arguments);
        this.modelRules = [
            (value) => !!value || 'Un modèle est obligatoire'
        ];
        this.warehouseRules = [
            (value) => !!value || 'Un entrepôt est obligatoire'
        ];
        this.nameRules = [(value) => !!value || 'Le nom est obligatoire'];
        this.stockRules = [
            (value) => (!!value && value > 0) || 'La valeur doit être positive'
        ];
        this.ref = '';
        this.name = '';
        this.stock = '';
        this.valid = false;
    }
    created() {
        this.pullModels();
        this.pullWarehouses();
    }
    selectModel(model) {
        if (model._id) {
            this.saveModel(model);
            this.name = model.fullName;
        }
    }
    selectWarehouse(warehouse) {
        if (warehouse._id) {
            this.saveWarehouse(warehouse);
        }
    }
    setStock(stock) {
        this.saveStock(parseInt(stock));
    }
    onSave() {
        this.saveItemOnRemote();
    }
};
__decorate([
    warehouses.State
], NewItems.prototype, "warehouses", void 0);
__decorate([
    models.State
], NewItems.prototype, "models", void 0);
__decorate([
    items.Action
], NewItems.prototype, "saveRef", void 0);
__decorate([
    items.Action
], NewItems.prototype, "saveName", void 0);
__decorate([
    items.Action
], NewItems.prototype, "saveDescription", void 0);
__decorate([
    items.Action
], NewItems.prototype, "saveModel", void 0);
__decorate([
    items.Action
], NewItems.prototype, "saveWarehouse", void 0);
__decorate([
    items.Action
], NewItems.prototype, "saveStock", void 0);
__decorate([
    items.Action
], NewItems.prototype, "saveItemOnRemote", void 0);
__decorate([
    warehouses.Action
], NewItems.prototype, "pullWarehouses", void 0);
__decorate([
    models.Action
], NewItems.prototype, "pullModels", void 0);
NewItems = __decorate([
    Component({
        name: 'NewItem'
    })
], NewItems);
export default NewItems;
