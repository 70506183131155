import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const paydocs = namespace('paydocs');
let PaydocsRequests = class PaydocsRequests extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.searchDisplay = '*';
        this.employee = {};
        this.activeTabCategory = 0;
        this.activeTabStatus = 0;
        this.headers = [
            {
                text: 'Employee',
                sortable: true,
                value: 'employee'
            },
            {
                text: 'Fullname',
                sortable: true,
                value: 'fullName'
            },
            {
                text: 'Category',
                value: 'category'
            },
            {
                text: 'Period',
                value: 'period'
            },
            {
                text: 'Date',
                value: 'creationDate'
            },
            {
                text: 'Status',
                value: 'delivred'
            },
            {
                text: '',
                value: 'actions'
            }
        ];
    }
    async created() {
        await this.getPaydocsRequestsForMeFromRemote();
    }
    onAllCat() {
        this.activeTabCategory = 0;
        this.searchDisplay = '*';
    }
    onPayslip() {
        this.activeTabCategory = 1;
        this.searchDisplay = '*';
    }
    onTax() {
        this.activeTabCategory = 2;
        this.searchDisplay = '*';
    }
    onPayroll() {
        console.log('hey');
        this.activeTabStatus = 4;
        this.searchDisplay = '*';
    }
    onOther() {
        this.activeTabCategory = 3;
        this.searchDisplay = '*';
    }
    onAllStatus() {
        this.activeTabStatus = 0;
        this.searchDisplay = '*';
    }
    onCurrent() {
        this.activeTabStatus = 1;
        this.searchDisplay = '*';
    }
    onDelivred() {
        this.activeTabStatus = 2;
        this.searchDisplay = '*';
    }
    onRequest(request) {
        this.$emit('request', request);
    }
    filterRequests(value, search, item) {
        switch (this.activeTabCategory) {
            case 0:
                switch (this.activeTabStatus) {
                    case 0:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))));
                        }
                        return true;
                    case 1:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.delivred !== true);
                        }
                        return item.delivred !== true;
                    case 2:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.delivred === true);
                        }
                        return item.delivred === true;
                    default:
                        return true;
                }
            case 1:
                switch (this.activeTabStatus) {
                    case 0:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.category === 'Payslip');
                        }
                        return item.category === 'Payslip';
                    case 1:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.category === 'Payslip' &&
                                item.delivred !== true);
                        }
                        return item.category === 'Payslip' && item.delivred !== true;
                    case 2:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.category === 'Payslip' &&
                                item.delivred === true);
                        }
                        return item.category === 'Payslip' && item.delivred === true;
                    default:
                        return item.category === 'Payslip';
                }
            case 2:
                switch (this.activeTabStatus) {
                    case 0:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.category === 'Tax');
                        }
                        return item.category === 'Tax';
                    case 1:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.category === 'Tax' &&
                                item.delivred !== true);
                        }
                        return item.category === 'Tax' && item.delivred !== true;
                    case 2:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.category === 'Tax' &&
                                item.delivred === true);
                        }
                        return item.category === 'Tax' && item.delivred === true;
                    default:
                        return item.category === 'Tax';
                }
            case 3:
                switch (this.activeTabStatus) {
                    case 0:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.category === 'Other');
                        }
                        return item.category === 'Other';
                    case 1:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.category === 'Other' &&
                                item.delivred !== true);
                        }
                        return item.category === 'Other' && item.delivred !== true;
                    case 2:
                        if (this.search.length) {
                            return (value != null &&
                                this.search != null &&
                                typeof value === 'string' &&
                                (value
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    (item.employee &&
                                        (item.employee.firstName
                                            .toString()
                                            .toLocaleUpperCase()
                                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                            item.employee.lastName
                                                .toString()
                                                .toLocaleUpperCase()
                                                .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                                item.category === 'Other' &&
                                item.delivred === true);
                        }
                        return item.category === 'Other' && item.delivred === true;
                    default:
                        return item.category === 'Other';
                }
            default:
                if (this.search.length) {
                    return (value != null &&
                        this.search != null &&
                        typeof value === 'string' &&
                        (value
                            .toString()
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                            (item.employee &&
                                (item.employee.firstName
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    item.employee.lastName
                                        .toString()
                                        .toLocaleUpperCase()
                                        .indexOf(this.search.toLocaleUpperCase()) !== -1))));
                }
                return true;
        }
    }
};
__decorate([
    paydocs.State
], PaydocsRequests.prototype, "paydocsRequests", void 0);
__decorate([
    paydocs.Action
], PaydocsRequests.prototype, "getPaydocsRequestsForMeFromRemote", void 0);
PaydocsRequests = __decorate([
    Component({
        name: 'PaydocsRequests'
    })
], PaydocsRequests);
export default PaydocsRequests;
