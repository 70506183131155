var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.paydocsRequests,"items-per-page":5,"search":_vm.searchDisplay,"sort-desc":"","sort-by":"creationDate","custom-filter":_vm.filterRequests},on:{"click:row":_vm.onRequest},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"table__header",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"payroll__search",attrs:{"label":"Search","hide-details":"auto","single-line":"","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',{model:{value:(_vm.activeTabCategory),callback:function ($$v) {_vm.activeTabCategory=$$v},expression:"activeTabCategory"}},[_c('v-btn',{staticClass:"paydocs-request__btn__label",on:{"click":_vm.onAllCat}},[_vm._v(" All ")]),_c('v-btn',{staticClass:"paydocs-request__btn__label",on:{"click":_vm.onPayslip}},[_vm._v(" Payslip ")]),_c('v-btn',{staticClass:"paydocs-request__btn__label",on:{"click":_vm.onTax}},[_vm._v(" Tax ")]),_c('v-btn',{staticClass:"paydocs-request__btn__label",on:{"click":_vm.onOther}},[_vm._v(" Other ")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',{model:{value:(_vm.activeTabStatus),callback:function ($$v) {_vm.activeTabStatus=$$v},expression:"activeTabStatus"}},[_c('v-btn',{staticClass:"paydocs-request__btn__label",on:{"click":_vm.onAllStatus}},[_vm._v(" All ")]),_c('v-btn',{staticClass:"paydocs-request__btn__label",on:{"click":_vm.onCurrent}},[_vm._v(" Current ")]),_c('v-btn',{staticClass:"paydocs-request__btn__label",on:{"click":_vm.onDelivred}},[_vm._v(" Delivred ")])],1)],1)],1)]},proxy:true},{key:"item.employee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.employee.firstName + ' ' + item.employee.lastName)+" ")]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [(item.period)?_c('div',[_vm._v(_vm._s(_vm._f("dtFormat")(item.period,'MMMM - YYYY')))]):_vm._e(),(!item.period)?_c('div',[_vm._v("empty")]):_vm._e()]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dtFormat")(item.creationDate,'DD/MM/YYYY'))+" ")]}},{key:"item.delivred",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
        approved: item.delivred,
        requested: !item.delivred
      }},[_vm._v(" "+_vm._s(item.delivred ? 'Delivred' : 'Pending')+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.onRequest(item)}}},[_vm._v(_vm._s(item.delivred ? 'View' : 'Review'))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }