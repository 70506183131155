import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import NewRole from './../components/role/new-role.vue';
import Role from './role/role.vue';
const roles = namespace('roles');
const application = namespace('application');
let Roles = class Roles extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Fullname',
                value: 'fullName'
            },
            { text: 'Description', value: 'description' },
            { text: '', value: 'actions' }
        ];
        this.drawer = false;
        this.displayRoles = [];
        this.search = '';
    }
    async created() {
        this.setTitle('Roles Settings');
        await this.getRolesFromRemote();
        this.displayRoles = this.roles;
    }
    onRole(role) {
        this.$emit('role', role);
        this.setRole(role);
        this.drawer = true;
    }
    openDrawer() {
        this.drawer = true;
    }
    handleRole(role) {
        this.setRole(role);
        this.openDrawer();
    }
    searchByName(args) {
        this.search = args;
        this.displayRoles = this.roles.filter(role => {
            return (role != null &&
                ((role.fullName &&
                    typeof args === 'string' &&
                    role.fullName
                        .toLocaleUpperCase()
                        .indexOf(args.toLocaleUpperCase()) !== -1) ||
                    (role.description &&
                        typeof args === 'string' &&
                        role.description
                            .toLocaleUpperCase()
                            .indexOf(args.toLocaleUpperCase()) !== -1)));
        });
    }
};
__decorate([
    roles.State
], Roles.prototype, "roles", void 0);
__decorate([
    roles.Mutation
], Roles.prototype, "setRole", void 0);
__decorate([
    roles.Action
], Roles.prototype, "getRolesFromRemote", void 0);
__decorate([
    application.Mutation
], Roles.prototype, "setTitle", void 0);
Roles = __decorate([
    Component({
        name: 'Roles',
        components: { Header, NewRole, Role }
    })
], Roles);
export default Roles;
