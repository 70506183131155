import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectUser from '../user/select-user.vue';
import SelectRole from '../role/select-role.vue';
const teams = namespace('teams');
let NewTeam = class NewTeam extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    selectUser(leader) {
        if (leader && leader._id)
            this.setNewTeam({ ...this.newTeam, leaderId: leader._id });
    }
    async save() {
        const result = await this.createTeam();
        if (result) {
            this.showNotification = true;
        }
    }
    closeDialog() {
        this.showNotification = false;
    }
};
__decorate([
    teams.State
], NewTeam.prototype, "newTeam", void 0);
__decorate([
    teams.Mutation
], NewTeam.prototype, "setNewTeam", void 0);
__decorate([
    teams.Action
], NewTeam.prototype, "createTeam", void 0);
NewTeam = __decorate([
    Component({
        name: 'NewTeam',
        components: { Modal, SelectUser, SelectRole }
    })
], NewTeam);
export default NewTeam;
