import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Update from './update.vue';
let UpdateBirthdate = class UpdateBirthdate extends Vue {
    constructor() {
        super(...arguments);
        this.birthdateMenu = false;
    }
    updateDate(birthdate) {
        this.$emit('birthdate', birthdate);
        this.birthdateMenu = false;
    }
};
__decorate([
    Prop()
], UpdateBirthdate.prototype, "user", void 0);
__decorate([
    Prop({ default: false })
], UpdateBirthdate.prototype, "showNotification", void 0);
UpdateBirthdate = __decorate([
    Component({
        name: 'UpdateBirthdate',
        components: { Update }
    })
], UpdateBirthdate);
export default UpdateBirthdate;
