import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
let UpdateEmployee = class UpdateEmployee extends Vue {
};
__decorate([
    Prop({ default: false })
], UpdateEmployee.prototype, "showNotification", void 0);
UpdateEmployee = __decorate([
    Component({
        name: 'UpdateEmployee',
        components: { Modal }
    })
], UpdateEmployee);
export default UpdateEmployee;
