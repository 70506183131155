import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UpdateUser from './update-user.vue';
import SelectRole from '../role/select-role.vue';
const users = namespace('users');
let UpdateUserRole = class UpdateUserRole extends Vue {
    selectRole(role) {
        this.setUser({ ...this.user, roleId: role._id });
    }
};
__decorate([
    Prop()
], UpdateUserRole.prototype, "user", void 0);
__decorate([
    Prop({ default: false })
], UpdateUserRole.prototype, "showNotification", void 0);
__decorate([
    users.Mutation
], UpdateUserRole.prototype, "setUser", void 0);
UpdateUserRole = __decorate([
    Component({
        name: 'UpdateUserRole',
        components: { UpdateUser, SelectRole }
    })
], UpdateUserRole);
export default UpdateUserRole;
