import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Notification = class Notification extends Vue {
};
__decorate([
    Prop({ type: String, default: '' })
], Notification.prototype, "title", void 0);
__decorate([
    Prop({ type: String, default: '' })
], Notification.prototype, "icon", void 0);
__decorate([
    Prop({ type: String, default: '' })
], Notification.prototype, "message", void 0);
Notification = __decorate([
    Component({
        name: 'Notification'
    })
], Notification);
export default Notification;
