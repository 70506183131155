import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectManagerNotTeam from './select-manager-not-team.vue';
const teams = namespace('teams');
let AddTeamManager = class AddTeamManager extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
        this.userSelected = {
            _id: '',
            firstName: '',
            lastName: ''
        };
    }
    setUserSelected(userSelected) {
        this.userSelected = {
            _id: userSelected._id,
            firstName: userSelected.firstName,
            lastName: userSelected.lastName
        };
    }
    async save() {
        if (this.userSelected && this.userSelected._id) {
            const result = await this.addTeamManager({
                teamId: this.team._id,
                managerId: this.userSelected._id
            });
            if (result) {
                this.showNotification = true;
            }
        }
    }
    closeDialog() {
        this.showNotification = false;
    }
};
__decorate([
    teams.State
], AddTeamManager.prototype, "team", void 0);
__decorate([
    teams.Action
], AddTeamManager.prototype, "addTeamManager", void 0);
AddTeamManager = __decorate([
    Component({
        name: 'AddTeamManager',
        components: { Modal, SelectManagerNotTeam }
    })
], AddTeamManager);
export default AddTeamManager;
