import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
let UpdateEmployeeMail = class UpdateEmployeeMail extends Vue {
};
__decorate([
    Prop()
], UpdateEmployeeMail.prototype, "employee", void 0);
__decorate([
    Prop({ default: false })
], UpdateEmployeeMail.prototype, "showNotification", void 0);
UpdateEmployeeMail = __decorate([
    Component({
        name: 'UpdateEmployeeMail',
        components: { UpdateEmployee }
    })
], UpdateEmployeeMail);
export default UpdateEmployeeMail;
