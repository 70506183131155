import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const members = namespace('members');
let SelectEmployeeNotTeam = class SelectEmployeeNotTeam extends Vue {
    async created() {
        await this.getEmployeesNotTeamFromRemote(this.team._id);
    }
    async updated() {
        await this.getEmployeesNotTeamFromRemote(this.team._id);
    }
    selectEmployee(employee) {
        this.$emit('employee', employee);
    }
};
__decorate([
    members.State
], SelectEmployeeNotTeam.prototype, "employeesNotTeam", void 0);
__decorate([
    Prop()
], SelectEmployeeNotTeam.prototype, "team", void 0);
__decorate([
    Prop({ default: 'Employee' })
], SelectEmployeeNotTeam.prototype, "label", void 0);
__decorate([
    members.Action
], SelectEmployeeNotTeam.prototype, "getEmployeesNotTeamFromRemote", void 0);
SelectEmployeeNotTeam = __decorate([
    Component({
        name: 'SelectEmployeeNotTeam'
    })
], SelectEmployeeNotTeam);
export default SelectEmployeeNotTeam;
