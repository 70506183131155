import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Update from './update.vue';
let UpdatePhone = class UpdatePhone extends Vue {
};
__decorate([
    Prop()
], UpdatePhone.prototype, "user", void 0);
__decorate([
    Prop({ default: false })
], UpdatePhone.prototype, "showNotification", void 0);
UpdatePhone = __decorate([
    Component({
        name: 'UpdatePhone',
        components: { Update }
    })
], UpdatePhone);
export default UpdatePhone;
