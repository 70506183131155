import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
let AddProject = class AddProject extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    closeDialog() {
        this.showNotification = false;
    }
    async save() {
        /*     if (result) { */
        this.showNotification = true;
        /*    } */
    }
    onSuccess(args) {
        this.showNotification = true;
        this.$emit('success', args);
    }
};
AddProject = __decorate([
    Component({
        name: 'AddProject',
        components: {
            Header,
            Modal
        }
    })
], AddProject);
export default AddProject;
