import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
let Teams = class Teams extends VuexModule {
    constructor() {
        super(...arguments);
        this.newTeam = {
            fullName: '',
            description: '',
            leaderId: ''
        };
        this.team = {
            _id: '',
            fullName: '',
            description: ''
        };
        this.teams = [];
        this.teamsNotUser = [];
    }
    setNewTeam(newTeam) {
        this.newTeam = newTeam;
    }
    clearNewTeam() {
        this.newTeam = {
            fullName: '',
            description: '',
            leaderId: ''
        };
    }
    setTeam(team) {
        this.team = { ...team };
    }
    setTeams(teams) {
        this.teams = teams;
    }
    setTeamsNotUser(users) {
        this.teamsNotUser = users;
    }
    async createTeam() {
        const { token, host } = this.context?.rootState?.auth;
        const { newTeam } = this;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Settings($createTeamInput: CreateTeamInput!) {
          createTeam(createTeamInput: $createTeamInput) {
            _id
            fullName
            description
          }
        }
      `,
            variables: { createTeamInput: newTeam },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.createTeam) {
            this.context.commit('clearNewTeam');
            this.context.dispatch('getTeamsFromRemote');
            return true;
        }
        return false;
    }
    async getTeamFromRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Team($_id: String!) {
          getTeam(_id: $_id) {
            _id
            fullName
            description
            leaderId
            leader {
              _id
              firstName
              lastName
            }
            membersId
            members {
              _id
              firstName
              lastName
            }
            managersId
            managers {
              _id
              firstName
              lastName
            }
          }
        }
      `,
            variables: { _id },
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getTeam) {
            this.context.commit('setTeam', result.data.getTeam);
            return true;
        }
        return false;
    }
    async updateTeam(team) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Settings($updateTeamInput: UpdateTeamInput!) {
          updateTeam(updateTeamInput: $updateTeamInput)
        }
      `,
            variables: { updateTeamInput: team },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.updateTeam) {
            this.context.dispatch('getTeamFromRemote', team._id);
            this.context.dispatch('getTeamsFromRemote');
            return true;
        }
        return false;
    }
    async addTeamMember(addTeamMember) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Settings($addTeamMemberInput: AddTeamMemberInput!) {
          addTeamMember(addTeamMemberInput: $addTeamMemberInput)
        }
      `,
            variables: { addTeamMemberInput: addTeamMember },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.addTeamMember) {
            this.context.dispatch('getTeamsFromRemote');
            this.context.dispatch('users/getUsersFromRemote', '', { root: true });
            this.context.dispatch('getTeamFromRemote', addTeamMember.teamId);
            return true;
        }
        return false;
    }
    async removeTeamMember(removeTeamMember) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Team($removeTeamMemberInput: RemoveTeamMemberInput!) {
          removeTeamMember(removeTeamMemberInput: $removeTeamMemberInput)
        }
      `,
            variables: { removeTeamMemberInput: removeTeamMember },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.removeTeamMember) {
            this.context.dispatch('getTeamsFromRemote');
            this.context.dispatch('users/getUsersFromRemote', '', { root: true });
            this.context.dispatch('getTeamFromRemote', removeTeamMember.teamId);
            return true;
        }
        return false;
    }
    async addTeamManager(addTeamManager) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Settings($addTeamManagerInput: AddTeamManagerInput!) {
          addTeamManager(addTeamManagerInput: $addTeamManagerInput)
        }
      `,
            variables: { addTeamManagerInput: addTeamManager },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.addTeamManager) {
            this.context.dispatch('getTeamsFromRemote');
            this.context.dispatch('getTeamFromRemote', addTeamManager.teamId);
            this.context.dispatch('users/getUsersFromRemote', '', { root: true });
            return true;
        }
        return false;
    }
    async removeManager(removeTeamManager) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Settings($removeTeamManagerInput: RemoveTeamManagerInput!) {
          removeTeamManager(removeTeamManagerInput: $removeTeamManagerInput)
        }
      `,
            variables: { removeTeamManagerInput: removeTeamManager },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.removeTeamManager) {
            this.context.dispatch('getTeamsFromRemote');
            this.context.dispatch('getTeamFromRemote', removeTeamManager.teamId);
            this.context.dispatch('users/getUsersFromRemote', '', { root: true });
            return true;
        }
        return false;
    }
    async deleteTeamFromRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Settings($_id: String!) {
          deleteTeam(_id: $_id)
        }
      `,
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.deleteTeam) {
            this.context.dispatch('getTeamsFromRemote');
            return true;
        }
        return false;
    }
    async removeTeamFromRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Settings($_id: String!) {
          removeTeam(_id: $_id)
        }
      `,
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.removeTeam) {
            this.context.dispatch('getTeamsFromRemote');
        }
    }
    async getTeamsFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Settings {
          findAllTeams {
            _id
            fullName
            description
            leaderId
            leader {
              _id
              firstName
              lastName
            }
            membersId
            members {
              _id
              firstName
              lastName
            }
            managersId
            managers {
              _id
              firstName
              lastName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findAllTeams) {
            this.context.commit('setTeams', result.data.findAllTeams);
        }
    }
    async getMyTeamsFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Settings {
          findMyTeams {
            _id
            fullName
            description
            leaderId
            leader {
              _id
              firstName
              lastName
            }
            membersId
            members {
              _id
              firstName
              lastName
            }
            managersId
            managers {
              _id
              firstName
              lastName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findMyTeams) {
            this.context.commit('setTeams', result.data.findMyTeams);
        }
    }
    async getTeamsNotUserFromRemote(userId) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Settings($_id: String!) {
          findAllTeamsNotUser(_id: $_id) {
            _id
            fullName
            description
            leader {
              _id
              firstName
              lastName
            }
          }
        }
      `,
            variables: { _id: userId },
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findAllTeamsNotUser)
            this.context.commit('setTeamsNotUser', result.data.findAllTeamsNotUser);
    }
    async removeRoleRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Settings($_id: String!) {
          removeRole(_id: $_id)
        }
      `,
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.removeRole) {
            this.context.dispatch('teams/getRolesFromRemote', '', { root: true });
        }
    }
};
__decorate([
    Mutation
], Teams.prototype, "setNewTeam", null);
__decorate([
    Mutation
], Teams.prototype, "clearNewTeam", null);
__decorate([
    Mutation
], Teams.prototype, "setTeam", null);
__decorate([
    Mutation
], Teams.prototype, "setTeams", null);
__decorate([
    Mutation
], Teams.prototype, "setTeamsNotUser", null);
__decorate([
    Action
], Teams.prototype, "createTeam", null);
__decorate([
    Action
], Teams.prototype, "getTeamFromRemote", null);
__decorate([
    Action
], Teams.prototype, "updateTeam", null);
__decorate([
    Action
], Teams.prototype, "addTeamMember", null);
__decorate([
    Action
], Teams.prototype, "removeTeamMember", null);
__decorate([
    Action
], Teams.prototype, "addTeamManager", null);
__decorate([
    Action
], Teams.prototype, "removeManager", null);
__decorate([
    Action
], Teams.prototype, "deleteTeamFromRemote", null);
__decorate([
    Action({ rawError: true })
], Teams.prototype, "getTeamsFromRemote", null);
__decorate([
    Action({ rawError: true })
], Teams.prototype, "getMyTeamsFromRemote", null);
__decorate([
    Action
], Teams.prototype, "getTeamsNotUserFromRemote", null);
__decorate([
    Action({ rawError: true })
], Teams.prototype, "removeRoleRemote", null);
Teams = __decorate([
    Module({ namespaced: true })
], Teams);
export default Teams;
