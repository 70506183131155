import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Label from '@/components/label.vue';
import LabelAndValue from '@/components/label-and-value.vue';
import Value from '@/components/value.vue';
import UpdateRoleDescription from './update-role-description.vue';
import UpdateRoleFullName from './update-role-fullname.vue';
import UpdateServices from './update-services.vue';
import DeleteRole from './delete-role.vue';
const roles = namespace('roles');
let Role = class Role extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    log(evt) {
        console.log(evt);
    }
    close() {
        this.showNotification = false;
    }
    async updateServices(services) {
        const isUpdated = await this.updateRole({ _id: this.role._id, services });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    updateIsPaydocs(isPaydocs) {
        this.updateRole({ _id: this.role._id, isPaydocs });
    }
    updateIsPaydocsManager(isPaydocsManager) {
        this.updateRole({ _id: this.role._id, isPaydocsManager });
    }
    updateIsTimeOff(isTimeOff) {
        this.updateRole({ _id: this.role._id, isTimeOff });
    }
    updateIsTimeOffManager(isTimeOffManager) {
        this.updateRole({ _id: this.role._id, isTimeOffManager });
    }
    updateIsPeople(isPeople) {
        this.updateRole({ _id: this.role._id, isPeople });
    }
    updateIsRequests(isRequests) {
        this.updateRole({ _id: this.role._id, isRequests });
    }
    updateIsRequestsManager(isRequestsManager) {
        this.updateRole({ _id: this.role._id, isRequestsManager });
    }
    updateIsSettings(isSettings) {
        this.updateRole({ _id: this.role._id, isSettings });
    }
    updateIsPayroll(isPayroll) {
        this.updateRole({ _id: this.role._id, isPayroll });
    }
    async updateRoleFullName() {
        const isUpdated = await this.updateRole({
            _id: this.role._id,
            fullName: this.role.fullName
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async updateRoleDescription() {
        const isUpdated = this.updateRole({
            _id: this.role._id,
            description: this.role.description
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
};
__decorate([
    roles.State
], Role.prototype, "role", void 0);
__decorate([
    roles.Action
], Role.prototype, "updateRole", void 0);
Role = __decorate([
    Component({
        name: 'Role',
        components: {
            DeleteRole,
            Header,
            Label,
            LabelAndValue,
            Value,
            UpdateRoleDescription,
            UpdateRoleFullName,
            UpdateServices
        }
    })
], Role);
export default Role;
