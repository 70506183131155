var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.profile.employeeId && _vm.profile.employee && _vm.profile.employee.teamId)?_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('RequestDocuemt')],1)],1),_c('div',{staticClass:"l27-spacer--small"}),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.activeTab === 0 ? _vm.payslips : _vm.paydocs,"items-per-page":5,"sort-desc":"","sort-by":"creationDate","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"table__header",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","hide-details":"auto","single-line":"","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-btn',{staticClass:"paydocs__btn__label"},[_vm._v("Payslip(s)")]),_c('v-btn',{staticClass:"paydocs__btn__label"},[_vm._v(" Requested Documents ")])],1)],1)],1)]},proxy:true},{key:"item.period",fn:function(ref){
var item = ref.item;
return [(item.period)?_c('div',[_vm._v(" "+_vm._s(_vm._f("dtFormat")(item.period,'MMMM - YYYY'))+" ")]):_vm._e(),(!item.period)?_c('div',[_vm._v(" empty ")]):_vm._e()]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dtFormat")(item.creationDate,'DD/MM/YYYY'))+" ")]}},{key:"item.delivred",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
                approved: item.delivred,
                requested: !item.delivred
              }},[_vm._v(" "+_vm._s(item.delivred ? 'Delivred' : 'Pending')+" ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(item.fileId)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.showItem(item.fileId)}}},[_vm._v(" View ")]):_vm._e()]}}],null,false,568213999)})],1)],1)],1):_vm._e(),(!_vm.profile.employeeId)?_c('div',[_c('v-alert',{attrs:{"outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" Your account need to be attached to a employee by your manager. ")])],1):_vm._e(),(!_vm.profile.employee || !_vm.profile.employee.teamId)?_c('div',[_c('v-alert',{attrs:{"outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" Your team need to be set by your manager. ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }