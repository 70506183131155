import { ApolloClient } from 'apollo-client';
import VueApollo from 'vue-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import router from '@/router';
const httpOptions = {};
const uploadLink = createUploadLink(httpOptions);
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path ? path : ''}`);
            if (message === 'Unauthorized') {
                router.push('/login');
            }
        });
    if (networkError)
        console.log(`[Network error]: ${networkError}`);
});
// HTTP connection to the API
/* const httpLink = createHttpLink({
  // You should use an absolute URL here
   uri: "https://pstock.herokuapp.com/graphql",
  uri: 'http://localhost:5000/graphql'
}); */
// Cache implementation
const cache = new InMemoryCache({
    addTypename: false
});
// Create the apollo client
const httpLink = ApolloLink.split(operation => operation.getContext().hasUpload, uploadLink, new BatchHttpLink(httpOptions));
const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache
});
export default apolloClient;
export const apolloProvider = new VueApollo({
    defaultClient: apolloClient
});
