import TimeOffManager from './../views/time-off-manager.vue';
import TimeOff from '../views/time-off.vue';
import auth from '@/guards/auth.guard';
const timeOffRoutes = [
    {
        path: '/manager/timeOff',
        name: 'TimeOffManager',
        component: TimeOffManager,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/timeOff',
        name: 'TimeOff',
        component: TimeOff,
        meta: {
            middleware: [auth]
        }
    }
];
export default timeOffRoutes;
