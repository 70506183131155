import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import router from '@/router';
let Login = class Login extends VuexModule {
    constructor() {
        super(...arguments);
        this.user = {
            userName: '',
            password: ''
        };
        this.id = '';
    }
    setUserName(userName) {
        this.user = { ...this.user, userName };
    }
    setPassword(password) {
        this.user = { ...this.user, password };
    }
    setId(id) {
        this.id = id;
    }
    saveUserName(userName) {
        this.context.commit('setUserName', userName);
    }
    savePassword(password) {
        this.context.commit('setPassword', password);
    }
    async connectOnRemote() {
        const host = process.env.VUE_APP_API_AUTH;
        const result = await apolloClient.query({
            query: gql `
        query Auth($user: LoginInput!) {
          login(user: $user) {
            userName
            success
            token
            host
          }
        }
      `,
            variables: { user: this.user },
            context: {
                uri: host
            },
            fetchPolicy: 'network-only'
        });
        if (result &&
            result.data &&
            result.data.login &&
            result.data.login.success &&
            result.data.login.host &&
            result.data.login.userName) {
            await this.context.dispatch('auth/saveIsConnected', result.data.login.success, {
                root: true
            });
            await this.context.dispatch('auth/saveToken', result.data.login.token, {
                root: true
            });
            await this.context.dispatch('auth/saveHost', result.data.login.host, {
                root: true
            });
            const profile = await this.context.dispatch('profile/getMyProfile', result.data.login.host, {
                root: true
            });
            if (profile &&
                (profile.status === 'active' || profile.type === 'Admin')) {
                if (profile.type === 'Admin') {
                    await this.context.commit('application/setMode', 'admin', {
                        root: true
                    });
                    router.push({ path: '/admin' });
                }
                else if (profile.role && profile.role.isManager) {
                    await this.context.commit('application/setMode', 'manager', {
                        root: true
                    });
                    router.push({ path: '/manager/overview' });
                }
                else {
                    await this.context.commit('application/setMode', 'employee', {
                        root: true
                    });
                    router.push({ path: '/overview' });
                }
            }
            else {
                router.push({ path: '/waiting-room' });
            }
            this.context.commit('setUserName', '');
            this.context.commit('setPassword', '');
            return true;
        }
        else {
            return false;
        }
    }
    async resetPassword(userName) {
        const host = process.env.VUE_APP_API_AUTH;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Auth($userName: String!) {
          resetPassword(userName: $userName)
        }
      `,
            variables: { userName },
            context: {
                uri: host
            }
        });
        if (result && result.data && result.data.resetPassword) {
            return result.data.resetPassword;
        }
        return false;
    }
    async updatePassword(updatePasswordInput) {
        const host = process.env.VUE_APP_API_AUTH;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Auth($updatePasswordInput: UpdatePasswordInput!) {
          updatePassword(updatePasswordInput: $updatePasswordInput)
        }
      `,
            variables: { updatePasswordInput },
            context: {
                uri: host
            }
        });
        if (result && result.data && result.data.updatePassword) {
            return result.data.updatePassword;
        }
        return false;
    }
    logOut() {
        this.context.commit('setToken', '');
        this.context.commit('setHost', '');
        this.context.dispatch('saveIsConnected', false);
        router.push({ path: '/login' });
    }
    saveId(id) {
        this.context.commit('setId', id);
    }
};
__decorate([
    Mutation
], Login.prototype, "setUserName", null);
__decorate([
    Mutation
], Login.prototype, "setPassword", null);
__decorate([
    Mutation
], Login.prototype, "setId", null);
__decorate([
    Action
], Login.prototype, "saveUserName", null);
__decorate([
    Action
], Login.prototype, "savePassword", null);
__decorate([
    Action({ rawError: true })
], Login.prototype, "connectOnRemote", null);
__decorate([
    Action({ rawError: true })
], Login.prototype, "resetPassword", null);
__decorate([
    Action({ rawError: true })
], Login.prototype, "updatePassword", null);
__decorate([
    Action
], Login.prototype, "logOut", null);
__decorate([
    Action
], Login.prototype, "saveId", null);
Login = __decorate([
    Module({ namespaced: true })
], Login);
export default Login;
