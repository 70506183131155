import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserListItem from './user-list-item.vue';
let UserList = class UserList extends Vue {
};
__decorate([
    Prop()
], UserList.prototype, "users", void 0);
UserList = __decorate([
    Component({
        name: 'UserList',
        components: {
            UserListItem
        }
    })
], UserList);
export default UserList;
