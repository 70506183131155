import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
let UpdateEmployeeFamilySituation = class UpdateEmployeeFamilySituation extends Vue {
    constructor() {
        super(...arguments);
        this.familySituation = ['Married', 'Unmarried'];
    }
};
__decorate([
    Prop()
], UpdateEmployeeFamilySituation.prototype, "employee", void 0);
__decorate([
    Prop({ default: false })
], UpdateEmployeeFamilySituation.prototype, "showNotification", void 0);
UpdateEmployeeFamilySituation = __decorate([
    Component({
        name: 'UpdateEmployeeFamilySituation',
        components: { UpdateEmployee }
    })
], UpdateEmployeeFamilySituation);
export default UpdateEmployeeFamilySituation;
