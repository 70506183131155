import { __decorate } from "tslib";
import { Vue, Prop, Component } from 'vue-property-decorator';
let TimelineItem = class TimelineItem extends Vue {
};
__decorate([
    Prop()
], TimelineItem.prototype, "item", void 0);
TimelineItem = __decorate([
    Component({})
], TimelineItem);
export default TimelineItem;
