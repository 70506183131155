import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Label from './label.vue';
import Value from './value.vue';
let LabelAndValue = class LabelAndValue extends Vue {
};
__decorate([
    Prop()
], LabelAndValue.prototype, "label", void 0);
__decorate([
    Prop()
], LabelAndValue.prototype, "value", void 0);
LabelAndValue = __decorate([
    Component({ components: { Label, Value } })
], LabelAndValue);
export default LabelAndValue;
