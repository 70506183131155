import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
let UpdatePassword = class UpdatePassword extends Vue {
    constructor() {
        super(...arguments);
        this.passwordRules = [
            (value) => (!!value && value.length > 7) || 'Un minimum de 8 caractères'
        ];
        this.showCurrentPassword = false;
        this.showNewPassword = false;
        this.showConfirmNewPassword = false;
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
    }
    onSave() {
        const { currentPassword, newPassword } = this;
        this.$emit('save', { currentPassword, newPassword });
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
    }
    onClose() {
        this.$emit('close');
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
    }
};
__decorate([
    Prop()
], UpdatePassword.prototype, "user", void 0);
__decorate([
    Prop({ default: false })
], UpdatePassword.prototype, "showNotification", void 0);
UpdatePassword = __decorate([
    Component({
        name: 'UpdatePassword',
        components: { Modal }
    })
], UpdatePassword);
export default UpdatePassword;
