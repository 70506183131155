import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UpdateEmployeeFirstNameAndLastName from './update-employee-firstname-and-lastname.vue';
import UpdateEmployeeBirthdate from './update-employee-birthdate.vue';
import UpdateEmployeeHiredate from './update-employee-hiredate.vue';
import UpdateEmployeeJobTitle from './update-employee-job-title.vue';
import UpdateEmployeeMail from './update-employee-mail.vue';
import UpdateEmployeePhone from './update-employee-phone.vue';
import UpdateEmployeeTeam from './update-employee-team.vue';
import UpdateEmployeeNationalId from './update-employee-national-id.vue';
import UpdateEmployeeFamilySituation from './update-employee-family-situation.vue';
import UpdateEmployeeContractType from './update-employee-contract-type.vue';
import UpdateAvatar from '@/components/update-avatar.vue';
const employees = namespace('employees');
let Employee = class Employee extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    resetDialog() {
        this.showNotification = false;
    }
    async updateEmployeeFirstNameAndLastName() {
        const isUpdated = await this.updateEmployee({
            _id: this.employee._id,
            firstName: this.employee.firstName,
            lastName: this.employee.lastName
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async updateContractType() {
        const isUpdated = await this.updateEmployee({
            _id: this.employee._id,
            contractType: this.employee.contractType
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async updateHireDate() {
        const isUpdated = await this.updateEmployee({
            _id: this.employee._id,
            dateOfHire: this.employee.dateOfHire
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    selectHireDate(dateOfHire) {
        this.setEmployee({ ...this.employee, dateOfHire });
    }
    selectContractType(contractType) {
        this.setEmployee({ ...this.employee, contractType });
    }
    selectFamilySituation(familySituation) {
        this.setEmployee({ ...this.employee, familySituation });
    }
    async updateBirthdate() {
        const isUpdated = await this.updateEmployee({
            _id: this.employee._id,
            birthdate: this.employee.birthdate
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    selectBirthdate(birthdate) {
        this.setEmployee({ ...this.employee, birthdate });
    }
    async updateJobTitle() {
        const isUpdated = await this.updateEmployee({
            _id: this.employee._id,
            jobTitle: this.employee.jobTitle
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async updateFamilySituation() {
        const isUpdated = await this.updateEmployee({
            _id: this.employee._id,
            familySituation: this.employee.familySituation
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async updateNationalId() {
        const isUpdated = await this.updateEmployee({
            _id: this.employee._id,
            nId: this.employee.nId
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async updatePhone() {
        const isUpdated = await this.updateEmployee({
            _id: this.employee._id,
            phone: this.employee.phone
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async updateMail() {
        const isUpdated = await this.updateEmployee({
            _id: this.employee._id,
            mail: this.employee.mail
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async updateTeam() {
        const isUpdated = await this.updateEmployee({
            _id: this.employee._id,
            teamId: this.employee.teamId
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    selectTeam(team) {
        this.setEmployee({ ...this.employee, teamId: team._id, team });
    }
    async cancelUpdate() {
        await this.getEmployeeFromRemote(this.employee._id);
    }
    async changeAvatar(avatar) {
        await this.updateAvatar({ avatar, _id: this.employee._id });
    }
};
__decorate([
    Prop()
], Employee.prototype, "employee", void 0);
__decorate([
    employees.Mutation
], Employee.prototype, "setEmployee", void 0);
__decorate([
    employees.Action
], Employee.prototype, "updateEmployee", void 0);
__decorate([
    employees.Action
], Employee.prototype, "getEmployeeFromRemote", void 0);
__decorate([
    employees.Action
], Employee.prototype, "updateAvatar", void 0);
Employee = __decorate([
    Component({
        name: 'Employee',
        components: {
            UpdateEmployeeFirstNameAndLastName,
            UpdateEmployeeBirthdate,
            UpdateEmployeeHiredate,
            UpdateEmployeeJobTitle,
            UpdateEmployeeMail,
            UpdateEmployeePhone,
            UpdateEmployeeTeam,
            UpdateEmployeeNationalId,
            UpdateEmployeeFamilySituation,
            UpdateEmployeeContractType,
            UpdateAvatar
        }
    })
], Employee);
export default Employee;
