import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import Value from '@/components/value.vue';
const users = namespace('users');
let DeleteUser = class DeleteUser extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    async deleteUser(userId) {
        const isDeleted = await this.deleteUserFromRemote(userId);
        if (isDeleted) {
            this.showNotification = true;
        }
    }
};
__decorate([
    Prop()
], DeleteUser.prototype, "user", void 0);
__decorate([
    users.Action
], DeleteUser.prototype, "deleteUserFromRemote", void 0);
DeleteUser = __decorate([
    Component({
        name: 'DeleteUser',
        components: { Modal, Value }
    })
], DeleteUser);
export default DeleteUser;
