import Vue from 'vue';
import Vuex from 'vuex';
import application from './application.store';
import login from '@/modules/login/store/login.store';
import employees from '@/modules/employees/store';
import payroll from '@/modules/paydocs/store/payroll.store';
import overview from '@/modules/overview/store';
import timeOff from '@/modules/timeOff/store';
import requests from '@/modules/requests/store';
import paydocs from '@/modules/paydocs/store';
import settings from '@/modules/settings/store';
import teams from '@/modules/settings/store/teams.store';
import roles from '@/modules/settings/store/roles.store';
import users from '@/modules/settings/store/users.store';
import managers from '@/modules/settings/store/managers.store';
import members from '@/modules/settings/store/members.store';
import register from '@/modules/register/store';
import profile from './profile.store';
import projects from '@/modules/projects/store';
import warehouses from '@/modules/stocks/warehouses/store';
import models from '@/modules/stocks/models/store';
import items from '@/modules/stocks/items/store';
import auth from './auth.store';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        application,
        login,
        employees,
        payroll,
        overview,
        timeOff,
        requests,
        paydocs,
        settings,
        teams,
        roles,
        users,
        managers,
        members,
        auth,
        register,
        profile,
        projects,
        warehouses,
        models,
        items
    }
});
