import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let UserListItem = class UserListItem extends Vue {
};
__decorate([
    Prop()
], UserListItem.prototype, "user", void 0);
UserListItem = __decorate([
    Component({
        name: 'UserListItem'
    })
], UserListItem);
export default UserListItem;
