import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectEmployeeNotTeam from './select-employee-not-team.vue';
const teams = namespace('teams');
let AddTeamMember = class AddTeamMember extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
        this.userSelected = {
            _id: '',
            firstName: '',
            lastName: ''
        };
    }
    setUserSelected(userSelected) {
        this.userSelected = {
            _id: userSelected._id,
            firstName: userSelected.firstName,
            lastName: userSelected.lastName
        };
    }
    async save() {
        if (this.userSelected && this.userSelected._id) {
            const result = await this.addTeamMember({
                teamId: this.team._id,
                memberId: this.userSelected._id
            });
            if (result) {
                this.showNotification = true;
            }
        }
    }
    closeDialog() {
        this.showNotification = false;
    }
};
__decorate([
    teams.State
], AddTeamMember.prototype, "team", void 0);
__decorate([
    teams.Action
], AddTeamMember.prototype, "addTeamMember", void 0);
AddTeamMember = __decorate([
    Component({
        name: 'AddTeamMember',
        components: { Modal, SelectEmployeeNotTeam }
    })
], AddTeamMember);
export default AddTeamMember;
