import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Value = class Value extends Vue {
};
__decorate([
    Prop({ default: 'empty', type: String })
], Value.prototype, "text", void 0);
__decorate([
    Prop({ default: '', type: String })
], Value.prototype, "color", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], Value.prototype, "isDisable", void 0);
Value = __decorate([
    Component
], Value);
export default Value;
