import { __decorate } from "tslib";
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
let Users = class Users extends VuexModule {
    constructor() {
        super(...arguments);
        this.newUser = {
            firstName: '',
            lastName: '',
            roleId: '',
            teamId: '',
            mail: ''
        };
        this.user = {
            _id: '',
            firstName: '',
            lastName: '',
            roleId: '',
            role: {
                _id: '',
                fullName: '',
                description: ''
            },
            mail: ''
        };
        this.users = [];
        this.usersNotTeam = [];
    }
    setNewUser(newUser) {
        this.newUser = newUser;
    }
    clearNewUser() {
        this.newUser = {
            firstName: '',
            lastName: '',
            roleId: '',
            teamId: '',
            mail: ''
        };
    }
    setUser(user) {
        this.user = user;
    }
    setUsers(users) {
        this.users = users;
    }
    setUsersNotTeam(users) {
        this.usersNotTeam = users;
    }
    async activeAccount(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation User($_id: String!) {
          activeAccount(_id: $_id)
        }
      `,
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.activeAccount)
            this.context.dispatch('getUsersFromRemote');
    }
    async disableAccount(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation User($_id: String!) {
          disableAccount(_id: $_id)
        }
      `,
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.activeAccount)
            this.context.dispatch('getUsersFromRemote');
    }
    async getUserFromRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query User($_id: String!) {
          getUser(_id: $_id) {
            _id
            firstName
            lastName
            roleId
            role {
              _id
              fullName
            }
            teams {
              _id
              fullName
            }
            managerTeams {
              _id
              fullName
            }
            mail
            employeeId
            status
            employee {
              _id
              firstName
              lastName
            }
          }
        }
      `,
            variables: { _id },
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getUser)
            this.context.commit('setUser', result.data.getUser);
    }
    async getUsersFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query User {
          findAllUsers {
            _id
            avatar
            phone
            firstName
            lastName
            roleId
            role {
              _id
              fullName
            }
            teams {
              _id
              fullName
            }
            mail
            employeeId
            managerTeams {
              _id
              fullName
            }
            status
            employee {
              _id
              firstName
              lastName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findAllUsers)
            this.context.commit('setUsers', result.data.findAllUsers);
    }
    async getUsersNotTeamFromRemote(teamId) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query User($_id: String!) {
          findAllUsersNotTeam(_id: $_id) {
            _id
            firstName
            lastName
            roleId
            role {
              _id
              fullName
            }
            mail
          }
        }
      `,
            variables: { _id: teamId },
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findAllUsersNotTeam) {
            this.context.commit('setUsersNotTeam', result.data.findAllUsersNotTeam);
            return true;
        }
        return false;
    }
    async createUser() {
        const { token, host } = this.context?.rootState?.auth;
        const { newUser } = this;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation User($createUserInput: CreateUserInput!) {
          createUser(createUserInput: $createUserInput) {
            _id
            firstName
            lastName
            roleId
            mail
          }
        }
      `,
            variables: { createUserInput: newUser },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.createUser) {
            this.context.commit('clearNewUser');
            this.context.dispatch('getUsersFromRemote');
            return true;
        }
        return false;
    }
    async updateUser(user) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation User($updateUserInput: UpdateUserInput!) {
          updateUser(updateUserInput: $updateUserInput)
        }
      `,
            variables: { updateUserInput: user },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.updateUser) {
            await this.context.dispatch('getUserFromRemote', user._id);
            await this.context.dispatch('getUsersFromRemote');
            return true;
        }
        return false;
    }
    async deleteUserFromRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation User($_id: String!) {
          deleteUser(_id: $_id)
        }
      `,
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.deleteUser) {
            this.context.dispatch('getUsersFromRemote');
            return true;
        }
        return false;
    }
    async linkAccount({ userId, employeeId }) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation User($linkAccountInput: LinkAccountInput!) {
          linkAccount(linkAccountInput: $linkAccountInput)
        }
      `,
            variables: { linkAccountInput: { userId, employeeId } },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.linkAccount) {
            this.context.dispatch('getUsersFromRemote');
            this.context.dispatch('getUserFromRemote', userId);
            return true;
        }
        return false;
    }
};
__decorate([
    Mutation
], Users.prototype, "setNewUser", null);
__decorate([
    Mutation
], Users.prototype, "clearNewUser", null);
__decorate([
    Mutation
], Users.prototype, "setUser", null);
__decorate([
    Mutation
], Users.prototype, "setUsers", null);
__decorate([
    Mutation
], Users.prototype, "setUsersNotTeam", null);
__decorate([
    Action({ rawError: true })
], Users.prototype, "activeAccount", null);
__decorate([
    Action({ rawError: true })
], Users.prototype, "disableAccount", null);
__decorate([
    Action({ rawError: true })
], Users.prototype, "getUserFromRemote", null);
__decorate([
    Action({ rawError: true })
], Users.prototype, "getUsersFromRemote", null);
__decorate([
    Action({ rawError: true })
], Users.prototype, "getUsersNotTeamFromRemote", null);
__decorate([
    Action
], Users.prototype, "createUser", null);
__decorate([
    Action
], Users.prototype, "updateUser", null);
__decorate([
    Action({ rawError: true })
], Users.prototype, "deleteUserFromRemote", null);
__decorate([
    Action
], Users.prototype, "linkAccount", null);
Users = __decorate([
    Module({ namespaced: true })
], Users);
export default Users;
