import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateUser from './update-user.vue';
let UpdateUserFirstNameAndLastName = class UpdateUserFirstNameAndLastName extends Vue {
};
__decorate([
    Prop()
], UpdateUserFirstNameAndLastName.prototype, "user", void 0);
__decorate([
    Prop({ default: false })
], UpdateUserFirstNameAndLastName.prototype, "showNotification", void 0);
UpdateUserFirstNameAndLastName = __decorate([
    Component({
        name: 'UpdateUserFirstNameAndLastName',
        components: { UpdateUser }
    })
], UpdateUserFirstNameAndLastName);
export default UpdateUserFirstNameAndLastName;
