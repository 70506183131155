import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const users = namespace('users');
const managers = namespace('managers');
let SelectUser = class SelectUser extends Vue {
    async created() {
        await this.loadData();
    }
    async updated() {
        await this.loadData();
    }
    async loadData() {
        if (this.manager) {
            this.getManagersFromRemote();
        }
        else {
            await this.getUsersFromRemote();
        }
    }
};
__decorate([
    users.State
], SelectUser.prototype, "users", void 0);
__decorate([
    Prop()
], SelectUser.prototype, "user", void 0);
__decorate([
    Prop({ default: 'User' })
], SelectUser.prototype, "label", void 0);
__decorate([
    Prop({ default: false })
], SelectUser.prototype, "manager", void 0);
__decorate([
    managers.Action
], SelectUser.prototype, "getManagersFromRemote", void 0);
__decorate([
    users.Action
], SelectUser.prototype, "getUsersFromRemote", void 0);
SelectUser = __decorate([
    Component({
        name: 'SelectUser'
    })
], SelectUser);
export default SelectUser;
