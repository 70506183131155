import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const teams = namespace('teams');
let SelectTeam = class SelectTeam extends Vue {
    get items() {
        return this.all ? [{ fullName: 'All' }, ...this.teams] : this.teams;
    }
    async created() {
        await this.getMyTeamsFromRemote();
    }
};
__decorate([
    teams.State
], SelectTeam.prototype, "teams", void 0);
__decorate([
    Prop()
], SelectTeam.prototype, "team", void 0);
__decorate([
    Prop({ default: false })
], SelectTeam.prototype, "all", void 0);
__decorate([
    Prop({ default: 'Team' })
], SelectTeam.prototype, "label", void 0);
__decorate([
    teams.Action
], SelectTeam.prototype, "getMyTeamsFromRemote", void 0);
__decorate([
    teams.Action
], SelectTeam.prototype, "getTeamsFromRemote", void 0);
SelectTeam = __decorate([
    Component({
        name: 'SelectTeam'
    })
], SelectTeam);
export default SelectTeam;
