import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectPeriod from '@/components/select-period.vue';
import Label from '@/components/label.vue';
const requests = namespace('requests');
const timeOff = namespace('timeOff');
let PaidLeaveRequest = class PaidLeaveRequest extends Vue {
    constructor() {
        super(...arguments);
        this.firstDate = null;
        this.firstDateMenu = false;
        this.lastDate = null;
        this.lastDateMenu = false;
        this.loading = false;
        this.showNotification = false;
    }
    created() {
        this.clearNewRequest();
        this.myTimeOffBalanceFromRemote();
    }
    setStartDate(startDate) {
        this.setNewRequest({ ...this.newRequest, startDate });
    }
    setEndDate(endDate) {
        this.setNewRequest({ ...this.newRequest, endDate });
    }
    closeDialog() {
        this.showNotification = false;
        this.loading = false;
        this.clearNewRequest();
    }
    open() {
        this.setCategory('Paid Leave');
        this.setType('Balance Adjustment');
    }
    async save() {
        /*  this.showNotification = true; */
        this.loading = true;
        const result = await this.createRequest();
        if (result) {
            this.showNotification = true;
        }
        this.loading = false;
    }
};
__decorate([
    requests.State
], PaidLeaveRequest.prototype, "newRequest", void 0);
__decorate([
    timeOff.State
], PaidLeaveRequest.prototype, "timeOffBalance", void 0);
__decorate([
    requests.Mutation
], PaidLeaveRequest.prototype, "clearNewRequest", void 0);
__decorate([
    requests.Mutation
], PaidLeaveRequest.prototype, "setNewRequest", void 0);
__decorate([
    requests.Mutation
], PaidLeaveRequest.prototype, "setCategory", void 0);
__decorate([
    requests.Mutation
], PaidLeaveRequest.prototype, "setType", void 0);
__decorate([
    requests.Action
], PaidLeaveRequest.prototype, "createRequest", void 0);
__decorate([
    timeOff.Action
], PaidLeaveRequest.prototype, "myTimeOffBalanceFromRemote", void 0);
PaidLeaveRequest = __decorate([
    Component({
        name: 'PaidLeaveRequest',
        components: { Header, Modal, SelectPeriod, Label }
    })
], PaidLeaveRequest);
export default PaidLeaveRequest;
