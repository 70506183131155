import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const models = namespace('models');
let Models = class Models extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Ref',
                value: 'ref'
            },
            {
                text: 'Fullname',
                value: 'fullName'
            },
            {
                text: 'Description',
                value: 'description'
            }
        ];
    }
    created() {
        this.pullModels();
    }
};
__decorate([
    models.State
], Models.prototype, "models", void 0);
__decorate([
    models.Action
], Models.prototype, "pullModels", void 0);
Models = __decorate([
    Component({
        name: 'Models'
    })
], Models);
export default Models;
