import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectTeam from '@/modules/settings/components/team/select-team.vue';
import SelectPeriod from './../components/select-period.vue';
import EmployeeList from '@/modules/employees/components/employee-list.vue';
import UploadPayroll from './upload-payroll.vue';
import UploadPaydoc from '../../paydocs/components/upload-paydoc.vue';
import UploadPayrollSummary from '../../paydocs/components/upload-payroll-summary.vue';
const employees = namespace('employees');
let AddPayroll = class AddPayroll extends Vue {
    constructor() {
        super(...arguments);
        this.period = '';
        this.showNotification = false;
        this.search = '';
        this.searchDisplay = '';
        this.step = 1;
        this.linkMode = '';
        this.selectedTeam = {
            _id: '',
            fullName: ''
        };
        this.isList = 0; //0:List|1:Card
        this.members = [];
        this.headers = [
            {
                text: 'Fullname',
                value: 'firstName'
            },
            { text: 'Team', value: 'team.fullName' },
            { text: 'Job title', value: 'jobTitle' },
            { text: '', value: 'actions' }
        ];
    }
    async created() {
        await this.getMyEmployeesFromRemote();
    }
    setPeriod(period) {
        this.period = period;
        this.step += 1;
    }
    searchByName(args) {
        this.search = args;
    }
    onAuto() {
        this.linkMode = 'auto';
        this.step += 1;
    }
    onManual() {
        this.linkMode = 'manual';
        this.step += 1;
    }
    previous() {
        this.step -= 1;
    }
    next() {
        this.step += 1;
    }
    closeDialog() {
        this.showNotification = false;
    }
    save() {
        this.showNotification = true;
    }
    selectTeam(team) {
        this.selectedTeam = team;
        this.searchDisplay = '*';
    }
    onEmployee(employee) {
        this.members.push(employee);
    }
    selectAll() {
        if (this.selectedTeam && this.selectedTeam.members) {
            this.members = this.members.map((employee, index) => {
                return { ...employee, active: true, index };
            });
        }
    }
    unselectAll() {
        if (this.selectedTeam && this.selectedTeam.members) {
            this.members = this.members.map((employee, index) => {
                return { ...employee, active: false, index };
            });
        }
    }
    get displayEmployees() {
        return this.employees.filter(element => {
            if (this.selectedTeam &&
                this.selectedTeam._id &&
                this.selectedTeam._id !== '')
                return element.teamId === this.selectedTeam._id;
            return true;
        });
    }
    filterEmployee(value, search, item) {
        if (this.search.length) {
            if (this.selectedTeam &&
                this.selectedTeam._id &&
                this.selectedTeam._id !== '')
                return (item.teamId === this.selectedTeam._id &&
                    value != null &&
                    this.search != null &&
                    typeof value === 'string' &&
                    value
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1);
            return (value != null &&
                this.search != null &&
                typeof value === 'string' &&
                value
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1);
        }
        else {
            if (this.selectedTeam &&
                this.selectedTeam._id &&
                this.selectedTeam._id !== '')
                return item.teamId === this.selectedTeam._id;
            return true;
        }
    }
};
__decorate([
    employees.State
], AddPayroll.prototype, "employees", void 0);
__decorate([
    employees.Action
], AddPayroll.prototype, "getMyEmployeesFromRemote", void 0);
AddPayroll = __decorate([
    Component({
        name: 'AddPayroll',
        components: {
            Header,
            Modal,
            SelectTeam,
            SelectPeriod,
            EmployeeList,
            UploadPayroll,
            UploadPaydoc,
            UploadPayrollSummary
        }
    })
], AddPayroll);
export default AddPayroll;
