import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const employeesN = namespace('employees');
let SelectEmployee = class SelectEmployee extends Vue {
    get getEmployees() {
        const a = this.employees.sort((a, b) => a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0);
        return a;
    }
    async created() {
        await this.getMyEmployeesFromRemote();
    }
};
__decorate([
    employeesN.State
], SelectEmployee.prototype, "employees", void 0);
__decorate([
    Prop()
], SelectEmployee.prototype, "employee", void 0);
__decorate([
    employeesN.Action
], SelectEmployee.prototype, "getMyEmployeesFromRemote", void 0);
SelectEmployee = __decorate([
    Component({
        name: 'SelectEmployee'
    })
], SelectEmployee);
export default SelectEmployee;
