import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import router from '@/router';
let Warehouses = class Warehouses extends VuexModule {
    constructor() {
        super(...arguments);
        this.warehouses = [];
        this.warehouse = {
            fullName: ''
        };
    }
    get getWarehouses() {
        return this.warehouses;
    }
    setWarehouses(warehouses) {
        this.warehouses = warehouses;
    }
    setName(fullName) {
        this.warehouse = { ...this.warehouse, fullName };
    }
    setRef(ref) {
        this.warehouse = { ...this.warehouse, ref };
    }
    setDescription(description) {
        this.warehouse = { ...this.warehouse, description };
    }
    setTel(tel) {
        this.warehouse = {
            ...this.warehouse,
            tel
        };
    }
    setStreet(street) {
        this.warehouse = {
            ...this.warehouse,
            location: { ...this.warehouse.location, street }
        };
    }
    setCity(city) {
        this.warehouse = {
            ...this.warehouse,
            location: { ...this.warehouse.location, city }
        };
    }
    setState(state) {
        this.warehouse = {
            ...this.warehouse,
            location: { ...this.warehouse.location, state }
        };
    }
    setZipCode(zipCode) {
        this.warehouse = {
            ...this.warehouse,
            location: { ...this.warehouse.location, zipCode }
        };
    }
    setCountry(country) {
        this.warehouse = {
            ...this.warehouse,
            location: { ...this.warehouse.location, country }
        };
    }
    saveRef(ref) {
        this.context.commit('setRef', ref);
    }
    saveName(fullName) {
        this.context.commit('setName', fullName);
    }
    saveDescription(description) {
        this.context.commit('setDescription', description);
    }
    saveTel(tel) {
        this.context.commit('setTel', tel);
    }
    saveStreet(street) {
        this.context.commit('setStreet', street);
    }
    saveCity(city) {
        this.context.commit('setCity', city);
    }
    saveState(state) {
        this.context.commit('setState', state);
    }
    saveZipCode(zipCode) {
        this.context.commit('setZipCode', zipCode);
    }
    saveCountry(country) {
        this.context.commit('setCountry', country);
    }
    async fetchWarehouses() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Warehouses {
          getWarehouses {
            _id
            fullName
            description
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            fetchPolicy: 'network-only'
        });
        return result;
    }
    async pullWarehouses() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Warehouses {
          getWarehouses {
            _id
            ref
            fullName
            description
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            fetchPolicy: 'network-only'
        });
        this.context.commit('setWarehouses', result?.data?.getWarehouses);
        return result;
    }
    async saveWarehouseOnRemote() {
        const { token, host } = this.context?.rootState?.auth;
        await apolloClient.mutate({
            mutation: gql `
        mutation Warehouse($warehouse: AddWarehouseInput!) {
          addWarehouse(warehouse: $warehouse) {
            _id
            ref
            fullName
            description
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            variables: { warehouse: this.warehouse }
        });
        router.push({ path: '/stocks' });
    }
    async removeWarehouse(warehouse) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Warehouses($warehouse: removeWarehouseInput) {
          removeWarehouse(warehouse: $warehouse) {}
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            variables: { warehouse }
        });
        return result;
    }
};
__decorate([
    Mutation
], Warehouses.prototype, "setWarehouses", null);
__decorate([
    Mutation
], Warehouses.prototype, "setName", null);
__decorate([
    Mutation
], Warehouses.prototype, "setRef", null);
__decorate([
    Mutation
], Warehouses.prototype, "setDescription", null);
__decorate([
    Mutation
], Warehouses.prototype, "setTel", null);
__decorate([
    Mutation
], Warehouses.prototype, "setStreet", null);
__decorate([
    Mutation
], Warehouses.prototype, "setCity", null);
__decorate([
    Mutation
], Warehouses.prototype, "setState", null);
__decorate([
    Mutation
], Warehouses.prototype, "setZipCode", null);
__decorate([
    Mutation
], Warehouses.prototype, "setCountry", null);
__decorate([
    Action
], Warehouses.prototype, "saveRef", null);
__decorate([
    Action
], Warehouses.prototype, "saveName", null);
__decorate([
    Action
], Warehouses.prototype, "saveDescription", null);
__decorate([
    Action
], Warehouses.prototype, "saveTel", null);
__decorate([
    Action
], Warehouses.prototype, "saveStreet", null);
__decorate([
    Action
], Warehouses.prototype, "saveCity", null);
__decorate([
    Action
], Warehouses.prototype, "saveState", null);
__decorate([
    Action
], Warehouses.prototype, "saveZipCode", null);
__decorate([
    Action
], Warehouses.prototype, "saveCountry", null);
__decorate([
    Action({ rawError: true })
], Warehouses.prototype, "fetchWarehouses", null);
__decorate([
    Action({ rawError: true })
], Warehouses.prototype, "pullWarehouses", null);
__decorate([
    Action({ rawError: true })
], Warehouses.prototype, "saveWarehouseOnRemote", null);
__decorate([
    Action
], Warehouses.prototype, "removeWarehouse", null);
Warehouses = __decorate([
    Module({ namespaced: true })
], Warehouses);
export default Warehouses;
