import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Balance = class Balance extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Firstname', value: 'firstName' },
            { text: 'Lastname', value: 'lastName' },
            { text: 'Days', value: 'timeOffBalance' }
        ];
        this.search = '';
    }
};
__decorate([
    Prop()
], Balance.prototype, "employees", void 0);
Balance = __decorate([
    Component({
        name: 'Balance',
        components: {}
    })
], Balance);
export default Balance;
