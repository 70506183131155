import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const warehouses = namespace('warehouses');
let Warehouses = class Warehouses extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Ref',
                value: 'ref'
            },
            {
                text: 'Fullname',
                value: 'fullName'
            }
        ];
    }
    created() {
        this.pullWarehouses();
    }
};
__decorate([
    warehouses.State
], Warehouses.prototype, "warehouses", void 0);
__decorate([
    warehouses.Action
], Warehouses.prototype, "pullWarehouses", void 0);
Warehouses = __decorate([
    Component({
        name: 'Warehouses'
    })
], Warehouses);
export default Warehouses;
