import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let SelectPeriod = class SelectPeriod extends Vue {
    constructor() {
        super(...arguments);
        this.period = '';
        this.menu = false;
    }
    onContinue(period) {
        this.period = period;
        this.$emit('period', period);
    }
};
SelectPeriod = __decorate([
    Component({
        name: 'SelectPeriod'
    })
], SelectPeriod);
export default SelectPeriod;
