import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
const roles = namespace('roles');
let NewRole = class NewRole extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    async save() {
        const result = await this.createRole();
        if (result) {
            this.showNotification = true;
        }
    }
    closeDialog() {
        this.showNotification = false;
    }
};
__decorate([
    roles.State
], NewRole.prototype, "newRole", void 0);
__decorate([
    roles.Action
], NewRole.prototype, "createRole", void 0);
NewRole = __decorate([
    Component({
        name: 'NewRole',
        components: { Modal }
    })
], NewRole);
export default NewRole;
