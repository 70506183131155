import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
let UpdateRole = class UpdateRole extends Vue {
};
__decorate([
    Prop({ default: false })
], UpdateRole.prototype, "showNotification", void 0);
__decorate([
    Prop({ default: true })
], UpdateRole.prototype, "cardText", void 0);
__decorate([
    Prop({ default: '' })
], UpdateRole.prototype, "maxWidth", void 0);
__decorate([
    Prop({ default: false })
], UpdateRole.prototype, "fullscreen", void 0);
UpdateRole = __decorate([
    Component({
        name: 'UpdateRole',
        components: { Modal }
    })
], UpdateRole);
export default UpdateRole;
