import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import RequestTimeOff from '../components/request-time-off.vue';
const timeOff = namespace('timeOff');
const application = namespace('application');
const profile = namespace('profile');
let TimeOffRequests = class TimeOffRequests extends Vue {
    constructor() {
        super(...arguments);
        this.drawer = false;
        this.headers = [
            {
                text: 'Effective date',
                align: 'start',
                value: 'startDate'
            },
            { text: 'Day(s)', value: 'value' },
            { text: 'Type', value: 'type' },
            { text: 'Creation Date', value: 'creationDate' },
            { text: 'Note', value: 'note' },
            { text: 'Status', value: 'status' },
            { text: '', value: 'actions', sortable: false }
        ];
        this.timeOffSelected = {
            _id: '',
            employeeId: '',
            fullName: '',
            type: '',
            creationDate: new Date(),
            value: 0,
            status: '',
            processed: false
        };
    }
    async created() {
        this.setIsNavigation(true);
        this.setTitle('Time off');
        await this.getMyTimeOffFromRemote();
        await this.myTimeOffBalanceFromRemote();
    }
    async onDelete(_id) {
        await this.removeTimeOffFromRemote(_id);
    }
    onTimeOff(timeOffSelected) {
        this.drawer = true;
        this.timeOffSelected = timeOffSelected;
    }
};
__decorate([
    profile.State
], TimeOffRequests.prototype, "profile", void 0);
__decorate([
    timeOff.State
], TimeOffRequests.prototype, "timeOff", void 0);
__decorate([
    timeOff.State
], TimeOffRequests.prototype, "timeOffBalance", void 0);
__decorate([
    timeOff.Action
], TimeOffRequests.prototype, "getMyTimeOffFromRemote", void 0);
__decorate([
    timeOff.Action
], TimeOffRequests.prototype, "myTimeOffBalanceFromRemote", void 0);
__decorate([
    timeOff.Action
], TimeOffRequests.prototype, "removeTimeOffFromRemote", void 0);
__decorate([
    application.Mutation
], TimeOffRequests.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], TimeOffRequests.prototype, "setTitle", void 0);
TimeOffRequests = __decorate([
    Component({
        name: 'TimeOff',
        components: { Header, RequestTimeOff }
    })
], TimeOffRequests);
export default TimeOffRequests;
