import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UploadDocument from '@/components/upload-document.vue';
const paydocs = namespace('paydocs');
let UploadPaydoc = class UploadPaydoc extends Vue {
    constructor() {
        super(...arguments);
        this.success = false;
    }
    async upload(file) {
        const { _id } = this;
        let newFile = {
            file,
            employeeId: this.employee._id,
            category: this.category,
            period: this.period || new Date()
        };
        if (_id && this._id.length > 0) {
            newFile = { ...newFile, _id };
        }
        const result = await this.uploadFile(newFile);
        if (result) {
            this.success = true;
            this.$emit('success', true);
        }
    }
};
__decorate([
    Prop()
], UploadPaydoc.prototype, "employee", void 0);
__decorate([
    Prop()
], UploadPaydoc.prototype, "category", void 0);
__decorate([
    Prop()
], UploadPaydoc.prototype, "_id", void 0);
__decorate([
    Prop({ default: () => [] })
], UploadPaydoc.prototype, "paydoc", void 0);
__decorate([
    Prop()
], UploadPaydoc.prototype, "disabled", void 0);
__decorate([
    Prop({ default: '' })
], UploadPaydoc.prototype, "period", void 0);
__decorate([
    paydocs.Action
], UploadPaydoc.prototype, "uploadFile", void 0);
UploadPaydoc = __decorate([
    Component({
        name: 'UploadPaydoc',
        components: { UploadDocument }
    })
], UploadPaydoc);
export default UploadPaydoc;
