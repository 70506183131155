import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UpdateTeam from './update-team.vue';
import SelectUser from '../user/select-user.vue';
const teams = namespace('teams');
let UpdateTeamLeader = class UpdateTeamLeader extends Vue {
    selectUser(leader) {
        this.setTeam({ ...this.team, leaderId: leader._id });
    }
};
__decorate([
    Prop()
], UpdateTeamLeader.prototype, "team", void 0);
__decorate([
    Prop({ default: false })
], UpdateTeamLeader.prototype, "showNotification", void 0);
__decorate([
    teams.Mutation
], UpdateTeamLeader.prototype, "setTeam", void 0);
UpdateTeamLeader = __decorate([
    Component({
        name: 'UpdateTeamLeader',
        components: { UpdateTeam, SelectUser }
    })
], UpdateTeamLeader);
export default UpdateTeamLeader;
