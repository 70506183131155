import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Label from '@/components/label.vue';
import LabelAndValue from '@/components/label-and-value.vue';
import Members from '../members.vue';
import Managers from '../managers.vue';
import AddTeamMember from '../employee/add-team-member.vue';
import AddTeamManager from '../user/add-team-manager.vue';
import ChooseLeader from '../user/choose-leader.vue';
import DeleteTeam from './delete-team.vue';
import UpdateTeamFullName from './update-team-fullname.vue';
import UpdateTeamDescription from './update-team-description.vue';
import UpdateTeamLeader from './update-team-leader.vue';
const teams = namespace('teams');
let Team = class Team extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
        this.activeTab = 0;
        this.headers = [
            {
                text: 'Employee',
                value: 'firstName'
            },
            {
                text: '',
                value: 'actions'
            }
        ];
        this.headersMangers = [
            {
                text: 'Manager',
                value: 'firstName'
            },
            {
                text: '',
                value: 'actions'
            }
        ];
    }
    close() {
        this.showNotification = false;
    }
    async cancelUpdate() {
        await this.getTeamFromRemote(this.team._id);
    }
    async updateTeamFullName() {
        const isUpdated = await this.updateTeam({
            _id: this.team._id,
            fullName: this.team.fullName
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async updateTeamDescription() {
        const isUpdated = this.updateTeam({
            _id: this.team._id,
            description: this.team.description
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async updateTeamLeader() {
        const isUpdated = await this.updateTeam({
            _id: this.team._id,
            leaderId: this.team.leaderId
        });
        if (isUpdated) {
            this.showNotification = true;
        }
    }
    async onRemoveManager(manager) {
        if (manager && manager._id)
            this.removeManager({
                teamId: this.team._id,
                managerId: manager._id
            });
    }
    async onRemoveMember(member) {
        if (member && member._id)
            await this.removeTeamMember({
                teamId: this.team._id,
                memberId: member._id
            });
    }
};
__decorate([
    Prop()
], Team.prototype, "team", void 0);
__decorate([
    teams.Action
], Team.prototype, "removeManager", void 0);
__decorate([
    teams.Action
], Team.prototype, "removeTeamMember", void 0);
__decorate([
    teams.Action
], Team.prototype, "getTeamFromRemote", void 0);
__decorate([
    teams.Action
], Team.prototype, "updateTeam", void 0);
Team = __decorate([
    Component({
        name: 'Team',
        components: {
            AddTeamMember,
            AddTeamManager,
            ChooseLeader,
            Header,
            Label,
            LabelAndValue,
            Managers,
            Members,
            DeleteTeam,
            UpdateTeamDescription,
            UpdateTeamFullName,
            UpdateTeamLeader
        }
    })
], Team);
export default Team;
