import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Value from '@/components/value.vue';
import { namespace } from 'vuex-class';
const application = namespace('application');
let WaitingRoom = class WaitingRoom extends Vue {
    created() {
        this.setIsNavigation(false);
    }
};
__decorate([
    application.State
], WaitingRoom.prototype, "logoPath", void 0);
__decorate([
    application.Mutation
], WaitingRoom.prototype, "setIsNavigation", void 0);
WaitingRoom = __decorate([
    Component({
        name: 'WaitingRoom',
        components: { Value }
    })
], WaitingRoom);
export default WaitingRoom;
