import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
let UpdateEmployeePhone = class UpdateEmployeePhone extends Vue {
};
__decorate([
    Prop()
], UpdateEmployeePhone.prototype, "employee", void 0);
__decorate([
    Prop({ default: false })
], UpdateEmployeePhone.prototype, "showNotification", void 0);
UpdateEmployeePhone = __decorate([
    Component({
        name: 'UpdateEmployeePhone',
        components: { UpdateEmployee }
    })
], UpdateEmployeePhone);
export default UpdateEmployeePhone;
