import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let Item = class Item extends Vue {
};
Item = __decorate([
    Component({
        name: "Item"
    })
], Item);
export default Item;
