import { __decorate } from "tslib";
import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';
const paydocs = namespace('paydocs');
let MyLastPayslip = class MyLastPayslip extends Vue {
    async created() {
        await this.getMyLastPayslip();
    }
    async onButton() {
        if (this.myLastPayslip && this.myLastPayslip.fileId) {
            console.log('this.myLastPayslip.fileId', this.myLastPayslip.fileId);
            await this.downloadPaydoc(this.myLastPayslip.fileId);
        }
    }
};
__decorate([
    paydocs.State
], MyLastPayslip.prototype, "myLastPayslip", void 0);
__decorate([
    paydocs.Action
], MyLastPayslip.prototype, "downloadPaydoc", void 0);
__decorate([
    paydocs.Action
], MyLastPayslip.prototype, "getMyLastPayslip", void 0);
MyLastPayslip = __decorate([
    Component({ name: 'MyLastPayslip' })
], MyLastPayslip);
export default MyLastPayslip;
