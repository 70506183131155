import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const login = namespace('login');
const application = namespace('application');
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.valid = false;
        this.error = false;
        this.errorMessages = [];
        this.userName = '';
        this.password = '';
        this.showPassword = false;
        this.userNameRules = [
            (v) => !v ||
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                'E-mail must be valid'
        ];
        this.passwordRules = [
            (value) => (!!value && value.length > 7) || 'Un minimum de 8 caractères'
        ];
    }
    created() {
        this.setIsNavigation(false);
        this.setTitle('');
    }
    async onSave() {
        const isLogedIn = await this.connectOnRemote();
        if (!isLogedIn) {
            this.error = true;
            this.errorMessages = ['Wrong username password combination'];
        }
    }
    onNewAccount() {
        this.$router.push('/register');
    }
    onForgotenPassword() {
        this.$router.push('/password/recover');
    }
};
__decorate([
    application.State
], Login.prototype, "logoPath", void 0);
__decorate([
    application.Mutation
], Login.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], Login.prototype, "setTitle", void 0);
__decorate([
    login.Action
], Login.prototype, "saveUserName", void 0);
__decorate([
    login.Action
], Login.prototype, "savePassword", void 0);
__decorate([
    login.Action
], Login.prototype, "connectOnRemote", void 0);
Login = __decorate([
    Component({
        name: 'Login'
    })
], Login);
export default Login;
