import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PaidLeaveRights from '../components/paid-leave-rights.vue';
import Balance from '../components/balance.vue';
import Calendar from '@/components/calendar.vue';
import dtFormat from '@/plugins/filters/dateFilter';
const timeOff = namespace('timeOff');
const application = namespace('application');
const employees = namespace('employees');
let TimeOffManager = class TimeOffManager extends Vue {
    constructor() {
        super(...arguments);
        this.newTimeOff = {
            _id: '',
            employeeId: '',
            fullName: '',
            type: '',
            creationDate: new Date(),
            value: 0,
            status: '',
            note: '',
            startDate: new Date(),
            endDate: new Date(),
            processed: false
        };
        this.drawer = false;
        this.review = '';
        this.headers = [
            {
                text: 'Fullname',
                align: 'start',
                value: 'employee'
            },
            {
                text: 'Effective date',
                align: 'start',
                value: 'startDate'
            },
            {
                text: 'Creation date',
                align: 'start',
                value: 'creationDate'
            },
            { text: 'Day(s)', value: 'value' },
            { text: 'Type', value: 'type' },
            { text: 'Status', value: 'status' },
            { text: '', value: 'actions', sortable: false }
        ];
        this.search = '';
        this.searchFilter = '*';
        this.activeTab = 0;
        this.events = [];
    }
    async created() {
        this.setIsNavigation(true);
        this.setTitle('Time off');
        await this.getTimeOffForMeFromRemote();
        await this.getMyEmployeesFromRemote();
    }
    get getEvents() {
        return this.timeOff
            .filter(item => item.type !== 'Paid Leave Rights' && item.status !== 'rejected')
            .map(item => {
            return {
                name: item.type +
                    ' ' +
                    item.employee?.firstName +
                    ' ' +
                    item.employee?.lastName,
                start: dtFormat(item.startDate?.toString() || '', 'YYYY-MM-DD'),
                end: dtFormat(item.endDate?.toString() || '', 'YYYY-MM-DD'),
                timed: false,
                color: item.status === 'approved' ? 'success' : 'primary'
            };
        });
    }
    searchByName(args) {
        this.search = args;
        this.searchFilter = '*';
    }
    onAll() {
        this.activeTab = 0;
    }
    onCurrent() {
        this.activeTab = 1;
    }
    onApproved() {
        this.activeTab = 2;
    }
    onRejected() {
        this.activeTab = 3;
    }
    onRequests() {
        this.searchFilter = '*';
        this.activeTab = 0;
    }
    onHistory() {
        this.searchFilter = '*';
    }
    filterRequests(value, search, item) {
        switch (this.activeTab) {
            case 0:
                if (this.search.length) {
                    return (value != null &&
                        this.search != null &&
                        typeof value === 'string' &&
                        (value
                            .toString()
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                            (item.employee &&
                                (item.employee.firstName
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    item.employee.lastName
                                        .toString()
                                        .toLocaleUpperCase()
                                        .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
                            (item.note &&
                                item.note
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)));
                }
                return true;
            case 1:
                if (this.search.length) {
                    return (value != null &&
                        this.search != null &&
                        typeof value === 'string' &&
                        (value
                            .toString()
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                            (item.employee &&
                                (item.employee.firstName
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    item.employee.lastName
                                        .toString()
                                        .toLocaleUpperCase()
                                        .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
                            (item.note &&
                                item.note
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
                        item.processed === false);
                }
                return item.processed === false;
            case 2:
                if (this.search.length) {
                    return (value != null &&
                        this.search != null &&
                        typeof value === 'string' &&
                        (value
                            .toString()
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                            (item.employee &&
                                (item.employee.firstName
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    item.employee.lastName
                                        .toString()
                                        .toLocaleUpperCase()
                                        .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
                            (item.note &&
                                item.note
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
                        item.processed === true &&
                        item.status === 'approved');
                }
                return item.processed === true && item.status === 'approved';
            case 3:
                if (this.search.length) {
                    return (value != null &&
                        this.search != null &&
                        typeof value === 'string' &&
                        (value
                            .toString()
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                            (item.employee &&
                                (item.employee.firstName
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    item.employee.lastName
                                        .toString()
                                        .toLocaleUpperCase()
                                        .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
                            (item.note &&
                                item.note
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
                        item.processed === true &&
                        item.status === 'rejected');
                }
                return item.processed === true && item.status === 'rejected';
            default:
                if (this.search.length) {
                    return (value != null &&
                        this.search != null &&
                        typeof value === 'string' &&
                        (value
                            .toString()
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                            (item.employee &&
                                (item.employee.firstName
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    item.employee.lastName
                                        .toString()
                                        .toLocaleUpperCase()
                                        .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
                            (item.note &&
                                item.note
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)));
                }
                return true;
        }
    }
    showItem(newTimeOff) {
        this.newTimeOff = newTimeOff;
        this.drawer = true;
    }
    async accept(_id) {
        const { review } = this;
        const result = await this.approveTimeOff({ _id, review });
        if (result) {
            this.drawer = false;
        }
    }
    async decline(_id) {
        const { review } = this;
        const result = await this.rejectTimeOff({ _id, review });
        if (result) {
            this.drawer = false;
        }
    }
};
__decorate([
    employees.State
], TimeOffManager.prototype, "employees", void 0);
__decorate([
    timeOff.State
], TimeOffManager.prototype, "timeOff", void 0);
__decorate([
    timeOff.Action
], TimeOffManager.prototype, "getTimeOffForMeFromRemote", void 0);
__decorate([
    timeOff.Action
], TimeOffManager.prototype, "approveTimeOff", void 0);
__decorate([
    timeOff.Action
], TimeOffManager.prototype, "rejectTimeOff", void 0);
__decorate([
    application.Mutation
], TimeOffManager.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], TimeOffManager.prototype, "setTitle", void 0);
__decorate([
    employees.Action
], TimeOffManager.prototype, "getMyEmployeesFromRemote", void 0);
TimeOffManager = __decorate([
    Component({
        name: 'TimeOffManager',
        components: { Header, PaidLeaveRights, Balance, Calendar }
    })
], TimeOffManager);
export default TimeOffManager;
