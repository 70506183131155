import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Label = class Label extends Vue {
};
__decorate([
    Prop()
], Label.prototype, "text", void 0);
Label = __decorate([
    Component
], Label);
export default Label;
