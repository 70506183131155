import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
let UpdateEmployeeNationalId = class UpdateEmployeeNationalId extends Vue {
};
__decorate([
    Prop()
], UpdateEmployeeNationalId.prototype, "employee", void 0);
__decorate([
    Prop({ default: false })
], UpdateEmployeeNationalId.prototype, "showNotification", void 0);
UpdateEmployeeNationalId = __decorate([
    Component({
        name: 'UpdateEmployeeNationalId',
        components: { UpdateEmployee }
    })
], UpdateEmployeeNationalId);
export default UpdateEmployeeNationalId;
