import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const login = namespace('login');
const application = namespace('application');
let ForgotenPassword = class ForgotenPassword extends Vue {
    constructor() {
        super(...arguments);
        this.valid = false;
        this.loading = false;
        this.userName = '';
        this.isMailSent = false;
        this.userNameRules = [
            (v) => !v ||
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                'E-mail must be valid'
        ];
    }
    created() {
        this.setIsNavigation(false);
    }
    async onContinue() {
        this.loading = true;
        const isResetPassword = await this.resetPassword(this.userName);
        if (isResetPassword) {
            this.isMailSent = true;
        }
        this.loading = false;
    }
};
__decorate([
    application.State
], ForgotenPassword.prototype, "logoPath", void 0);
__decorate([
    application.Mutation
], ForgotenPassword.prototype, "setIsNavigation", void 0);
__decorate([
    login.Action
], ForgotenPassword.prototype, "resetPassword", void 0);
ForgotenPassword = __decorate([
    Component({
        name: 'ForgotenPassword'
    })
], ForgotenPassword);
export default ForgotenPassword;
