import Login from '../views/login.vue';
import ForgotenPassword from '../views/forgoten-password.vue';
import ResetPassword from '../views/reset-password.vue';
const loginRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/password/recover',
        name: 'ForgotenPassword',
        component: ForgotenPassword
    },
    {
        path: '/password/reset/:token',
        name: 'ResetPassword',
        component: ResetPassword
    }
];
export default loginRoutes;
