import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
let UpdateEmployeeFirstNameAndLastName = class UpdateEmployeeFirstNameAndLastName extends Vue {
    changeAvatar(avatar) {
        return;
    }
};
__decorate([
    Prop()
], UpdateEmployeeFirstNameAndLastName.prototype, "employee", void 0);
__decorate([
    Prop({ default: false })
], UpdateEmployeeFirstNameAndLastName.prototype, "showNotification", void 0);
UpdateEmployeeFirstNameAndLastName = __decorate([
    Component({
        name: 'UpdateEmployeeFirstNameAndLastName',
        components: { UpdateEmployee }
    })
], UpdateEmployeeFirstNameAndLastName);
export default UpdateEmployeeFirstNameAndLastName;
