import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let Warehouse = class Warehouse extends Vue {
};
Warehouse = __decorate([
    Component({
        name: 'Warehouse'
    })
], Warehouse);
export default Warehouse;
