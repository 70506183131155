import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Badge = class Badge extends Vue {
};
__decorate([
    Prop({ default: '' })
], Badge.prototype, "value", void 0);
__decorate([
    Prop({ default: '' })
], Badge.prototype, "title", void 0);
Badge = __decorate([
    Component
], Badge);
export default Badge;
