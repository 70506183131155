import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const models = namespace('models');
let NewModel = class NewModel extends Vue {
    constructor() {
        super(...arguments);
        this.ref = '';
        this.name = '';
        this.description = '';
        this.valid = false;
        this.nameRules = [(value) => !!value || 'Le nom est obligatoire'];
    }
    onSave() {
        this.saveModelOnRemote();
    }
};
__decorate([
    models.Action
], NewModel.prototype, "saveModelOnRemote", void 0);
__decorate([
    models.Action
], NewModel.prototype, "saveName", void 0);
__decorate([
    models.Action
], NewModel.prototype, "saveRef", void 0);
__decorate([
    models.Action
], NewModel.prototype, "saveDescription", void 0);
NewModel = __decorate([
    Component({
        name: 'NewModel'
    })
], NewModel);
export default NewModel;
