import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectEmployee from './select-employee.vue';
const users = namespace('users');
let ChooseEmployee = class ChooseEmployee extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    async selectEmployee(employee) {
        await this.setUser({ ...this.user, employeeId: employee._id });
    }
    async save() {
        if (this.user._id && this.user.employeeId) {
            const result = await this.linkAccount({
                userId: this.user._id,
                employeeId: this.user.employeeId
            });
            if (result) {
                this.showNotification = true;
            }
        }
    }
    closeDialog() {
        this.showNotification = false;
    }
};
__decorate([
    Prop()
], ChooseEmployee.prototype, "user", void 0);
__decorate([
    users.Mutation
], ChooseEmployee.prototype, "setUser", void 0);
__decorate([
    users.Action
], ChooseEmployee.prototype, "linkAccount", void 0);
ChooseEmployee = __decorate([
    Component({
        name: 'ChooseEmployee',
        components: { Modal, SelectEmployee }
    })
], ChooseEmployee);
export default ChooseEmployee;
