var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"transition":"slide-y-transition","rounded":"20","offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"profile",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-row',{attrs:{"no-gutters":"","align":'center',"justify":'center'}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-avatar',{attrs:{"color":"primary","size":"50"}},[_c('v-img',{attrs:{"src":_vm.avatar}})],1)],1),_c('v-col',{staticClass:"d-none d-sm-flex",attrs:{"sm":"7"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"profile__fullname"},[_vm._v(" "+_vm._s(_vm.profile.firstName + ' ' + _vm.profile.lastName)+" ")]),_c('v-list-item-subtitle',{staticClass:"profile__role"},[_vm._v(" "+_vm._s(_vm.mode)+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)],1)]}}])},[_c('v-list',[(_vm.mode === 'manager')?_c('v-list-item',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.onModeEmployee}},[_c('v-icon',{staticClass:"profile__icon"},[_vm._v("mdi-account-switch")]),_c('span',[_vm._v(" Switch to employee ")])],1)],1):_vm._e(),(
        _vm.mode === 'employee' &&
          _vm.profile &&
          _vm.profile.role &&
          _vm.profile.role.isManager
      )?_c('v-list-item',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.onModeManager}},[_c('v-icon',{staticClass:"profile__icon"},[_vm._v("mdi-account-switch")]),_c('span',[_vm._v(" Switch to manager ")])],1)],1):_vm._e(),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.onMyAccount}},[_c('v-icon',{staticClass:"profile__icon"},[_vm._v("mdi-account")]),_c('span',[_vm._v(" My Account ")])],1)],1),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.onLogout}},[_c('v-icon',{staticClass:"profile__icon"},[_vm._v(" mdi-logout ")]),_c('span',[_vm._v(" Logout ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }