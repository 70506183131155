var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticStyle:{"max-width":"600px"},attrs:{"absolute":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({},'v-app-bar-nav-icon',attrs,false),on))]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',_vm._l((_vm.profile.type === 'Admin'
        ? _vm.itemsAdmin.filter(function (item) { return item.enable === true; })
        : _vm.menu.filter(
            function (item) { return item.enable === true &&
              (item.manager === (_vm.mode === 'manager') ||
                item.manager === !(_vm.mode === 'employee')); }
          )),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.onButton(item.path)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }