import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
const application = namespace('application');
let AddPayrollSummariesReceiver = class AddPayrollSummariesReceiver extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    closeDialog() {
        return;
    }
    save() {
        return;
    }
};
AddPayrollSummariesReceiver = __decorate([
    Component({
        name: 'AddPayrollSummariesReceiver',
        components: { Modal }
    })
], AddPayrollSummariesReceiver);
export default AddPayrollSummariesReceiver;
