import auth from '@/guards/auth.guard';
import MyInfo from '../views/my-info.vue';
const myAccountRoutes = [
    {
        path: '/myAccount',
        name: 'MyAccount',
        component: MyInfo,
        meta: {
            middleware: [auth]
        }
    }
];
export default myAccountRoutes;
