import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let EmployeeListItem = class EmployeeListItem extends Vue {
};
__decorate([
    Prop()
], EmployeeListItem.prototype, "item", void 0);
EmployeeListItem = __decorate([
    Component({ name: 'EmployeeListItem' })
], EmployeeListItem);
export default EmployeeListItem;
