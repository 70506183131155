import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AddPayrollSummariesReceiver from './add-payroll-summaries-receiver.vue';
const application = namespace('application');
let PayrollSummariesReceivers = class PayrollSummariesReceivers extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Firstname', value: 'FirstName' },
            { text: 'Lastname', value: 'lastName' },
            { text: 'Role', value: 'role' }
        ];
        this.employees = [];
        this.search = '';
    }
};
PayrollSummariesReceivers = __decorate([
    Component({
        name: 'PayrollSummariesReceivers',
        components: { Header, AddPayrollSummariesReceiver }
    })
], PayrollSummariesReceivers);
export default PayrollSummariesReceivers;
