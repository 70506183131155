import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let EmployeeListItem = class EmployeeListItem extends Vue {
    onCard() {
        this.$emit('employee', {
            ...this.employee,
            index: this.index,
            active: !this.active
        });
    }
};
__decorate([
    Prop()
], EmployeeListItem.prototype, "employee", void 0);
__decorate([
    Prop({ default: 0 })
], EmployeeListItem.prototype, "index", void 0);
__decorate([
    Prop({ default: false })
], EmployeeListItem.prototype, "select", void 0);
__decorate([
    Prop({ default: false })
], EmployeeListItem.prototype, "active", void 0);
EmployeeListItem = __decorate([
    Component({
        name: 'EmployeeListItem'
    })
], EmployeeListItem);
export default EmployeeListItem;
