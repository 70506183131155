import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
import SelectTeam from '@/modules/settings/components/team/select-team.vue';
let UpdateEmployeeTeam = class UpdateEmployeeTeam extends Vue {
    selectTeam(team) {
        this.$emit('team', team);
    }
};
__decorate([
    Prop()
], UpdateEmployeeTeam.prototype, "employee", void 0);
__decorate([
    Prop({ default: false })
], UpdateEmployeeTeam.prototype, "showNotification", void 0);
UpdateEmployeeTeam = __decorate([
    Component({
        name: 'UpdateEmployeeTeam',
        components: { UpdateEmployee, SelectTeam }
    })
], UpdateEmployeeTeam);
export default UpdateEmployeeTeam;
