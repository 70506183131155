import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Header from '@/components/header.vue';
import MyInfoItem from '../components/my-info-item.vue';
import { namespace } from 'vuex-class';
import Label from '@/components/label.vue';
import Value from '@/components/value.vue';
import UpdateFirstNameAndLastName from '../components/update-firstname-and-lastname.vue';
import UpdateBirthdate from '../components/update-birthdate.vue';
import UpdateMail from '../components/update-mail.vue';
import UpdatePhone from '../components/update-phone.vue';
import UpdateBank from '../components/update-bank.vue';
import UpdateAvatar from '../../../components/update-avatar.vue';
import UpdatePassword from '../components/update-password.vue';
const overview = namespace('overview');
const application = namespace('application');
const profile = namespace('profile');
let MyInfo = class MyInfo extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    created() {
        this.setIsNavigation(true);
        this.setTitle('My Account');
    }
    reset() {
        this.showNotification = false;
    }
    setBirthdate(birthdate) {
        this.setProfile({ ...this.profile, birthdate });
    }
    async updateAvatar(avatar) {
        const isUpdated = await this.updateMyAvatar(avatar);
    }
    async updateMailNotification() {
        const isUpdated = await this.updateMyInfo({
            mailNotification: this.profile.mailNotification
        });
        if (isUpdated) {
            this.showNotification = true;
            await this.getMyProfile();
        }
    }
    async updateFullname() {
        const isUpdated = await this.updateMyInfo({
            firstName: this.profile.firstName,
            lastName: this.profile.lastName
        });
        if (isUpdated) {
            this.showNotification = true;
            await this.getMyProfile();
        }
    }
    async updateBirthdate(birthdate) {
        const isUpdated = await this.updateMyInfo({
            birthdate: this.profile.birthdate
        });
        if (isUpdated) {
            this.showNotification = true;
            await this.getMyProfile();
        }
    }
    async updateMail() {
        const isUpdated = await this.updateMyInfo({
            mail: this.profile.mail
        });
        if (isUpdated) {
            this.showNotification = true;
            await this.getMyProfile();
        }
    }
    async updatePhone() {
        const isUpdated = await this.updateMyInfo({
            phone: this.profile.phone
        });
        if (isUpdated) {
            this.showNotification = true;
            await this.getMyProfile();
        }
    }
    async updatePassword(updateMyPasswordInput) {
        const isUpdated = await this.updateMyPassword(updateMyPasswordInput);
        console.log('isUpdated', isUpdated);
        if (isUpdated) {
            this.showNotification = true;
        }
    }
};
__decorate([
    profile.State
], MyInfo.prototype, "profile", void 0);
__decorate([
    profile.State
], MyInfo.prototype, "avatar", void 0);
__decorate([
    profile.Mutation
], MyInfo.prototype, "setProfile", void 0);
__decorate([
    application.Mutation
], MyInfo.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], MyInfo.prototype, "setTitle", void 0);
__decorate([
    profile.Action
], MyInfo.prototype, "updateMyInfo", void 0);
__decorate([
    profile.Action
], MyInfo.prototype, "updateMyAvatar", void 0);
__decorate([
    profile.Action
], MyInfo.prototype, "getMyProfile", void 0);
__decorate([
    profile.Action
], MyInfo.prototype, "updateMyPassword", void 0);
MyInfo = __decorate([
    Component({
        components: {
            Header,
            MyInfoItem,
            Label,
            Value,
            UpdateFirstNameAndLastName,
            UpdateBirthdate,
            UpdateMail,
            UpdatePhone,
            UpdateBank,
            UpdateAvatar,
            UpdatePassword
        }
    })
], MyInfo);
export default MyInfo;
