import auth from '@/guards/auth.guard';
import Admin from './../views/admin.vue';
const adminRoutes = [
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: {
            middleware: [auth]
        }
    }
];
export default adminRoutes;
