import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const settings = namespace('settings');
let UserTeams = class UserTeams extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Fullname',
                value: 'fullName'
            },
            { text: 'Description', value: 'description' }
        ];
    }
    onTeam(team) {
        this.$emit('team', team);
    }
};
__decorate([
    Prop({ default: false })
], UserTeams.prototype, "onlyDataTable", void 0);
__decorate([
    Prop({ default: 'Teams' })
], UserTeams.prototype, "title", void 0);
__decorate([
    Prop()
], UserTeams.prototype, "teams", void 0);
UserTeams = __decorate([
    Component({
        name: 'UserTeams',
        components: { Header }
    })
], UserTeams);
export default UserTeams;
