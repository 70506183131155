import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectTeamNotUser from './select-team-not-user.vue';
const teams = namespace('teams');
let AddTeam = class AddTeam extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
        this.teamSelected = {
            _id: '',
            fullName: ''
        };
    }
    setTeamSelected(teamSelected) {
        this.teamSelected = {
            _id: teamSelected._id,
            fullName: teamSelected.fullName
        };
    }
    async save() {
        if (this.user && this.user._id) {
            const result = await this.addTeamMember({
                teamId: this.teamSelected._id,
                memberId: this.user._id
            });
            if (result) {
                this.showNotification = true;
            }
        }
    }
    closeDialog() {
        this.showNotification = false;
    }
};
__decorate([
    Prop()
], AddTeam.prototype, "user", void 0);
__decorate([
    teams.Action
], AddTeam.prototype, "addTeamMember", void 0);
AddTeam = __decorate([
    Component({
        name: 'AddTeam',
        components: { Modal, SelectTeamNotUser }
    })
], AddTeam);
export default AddTeam;
