import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const teams = namespace('teams');
let SelectTeamNotUser = class SelectTeamNotUser extends Vue {
    created() {
        this.getTeamsNotUserFromRemote(this.userId);
    }
    updated() {
        this.getTeamsNotUserFromRemote(this.userId);
    }
};
__decorate([
    teams.State
], SelectTeamNotUser.prototype, "teamsNotUser", void 0);
__decorate([
    Prop()
], SelectTeamNotUser.prototype, "userId", void 0);
__decorate([
    Prop({ default: 'Team' })
], SelectTeamNotUser.prototype, "label", void 0);
__decorate([
    teams.Action
], SelectTeamNotUser.prototype, "getTeamsNotUserFromRemote", void 0);
SelectTeamNotUser = __decorate([
    Component({
        name: 'SelectTeamNotUser'
    })
], SelectTeamNotUser);
export default SelectTeamNotUser;
