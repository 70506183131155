import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const roles = namespace('roles');
let SelectRole = class SelectRole extends Vue {
    created() {
        this.getRolesFromRemote();
    }
};
__decorate([
    roles.State
], SelectRole.prototype, "roles", void 0);
__decorate([
    Prop()
], SelectRole.prototype, "role", void 0);
__decorate([
    roles.Action
], SelectRole.prototype, "getRolesFromRemote", void 0);
SelectRole = __decorate([
    Component({
        name: 'SelectRole'
    })
], SelectRole);
export default SelectRole;
