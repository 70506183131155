import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
let Payroll = class Payroll extends VuexModule {
    constructor() {
        super(...arguments);
        this.payroll = [];
    }
    setPayroll(payroll) {
        this.payroll = payroll;
    }
    async getPayrollFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Paydoc {
          getPayroll {
            fullName
            type
            category
            period
            status
            creationDate
          }
        }
      `,
            context: {
                uri: host,
                hasUpload: true,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getPayroll) {
            this.context.commit('setPayroll', result.data.getPayroll);
            return true;
        }
        return false;
        this.context.commit('setPayroll', result);
    }
    async uploadPayroll(data) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Paydoc(
          $file: Upload!
          $employeeId: String!
          $category: String!
        ) {
          uploadPaydoc(
            file: $file
            employeeId: $employeeId
            category: $category
          )
        }
      `,
            variables: {
                file: data.file,
                employeeId: data.employeeId,
                category: data.category
            },
            context: {
                uri: host,
                hasUpload: true,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.uploadPaydoc) {
            this.context.dispatch('getPaydocsFromRemote', data.employeeId);
            return true;
        }
        return false;
    }
};
__decorate([
    Mutation
], Payroll.prototype, "setPayroll", null);
__decorate([
    Action
], Payroll.prototype, "getPayrollFromRemote", null);
__decorate([
    Action({ rawError: true })
], Payroll.prototype, "uploadPayroll", null);
Payroll = __decorate([
    Module({ namespaced: true })
], Payroll);
export default Payroll;
