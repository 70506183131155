import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const members = namespace('members');
let SelectEmployeeNotUser = class SelectEmployeeNotUser extends Vue {
    created() {
        this.getEmployeesNotUserFromRemote();
    }
    updated() {
        this.getEmployeesNotUserFromRemote();
    }
};
__decorate([
    members.State
], SelectEmployeeNotUser.prototype, "employees", void 0);
__decorate([
    Prop({ default: 'Employee' })
], SelectEmployeeNotUser.prototype, "label", void 0);
__decorate([
    members.Action
], SelectEmployeeNotUser.prototype, "getEmployeesNotUserFromRemote", void 0);
SelectEmployeeNotUser = __decorate([
    Component({
        name: 'SelectEmployeeNotUser'
    })
], SelectEmployeeNotUser);
export default SelectEmployeeNotUser;
