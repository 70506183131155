import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import TeamListItem from './team-list-item.vue';
let TeamList = class TeamList extends Vue {
};
__decorate([
    Prop()
], TeamList.prototype, "teams", void 0);
TeamList = __decorate([
    Component({
        name: 'TeamList',
        components: { TeamListItem }
    })
], TeamList);
export default TeamList;
