import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Notification from './notification.vue';
let Modal = class Modal extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
    }
    close() {
        this.dialog = false;
        this.$emit('close');
    }
    cancel() {
        this.$emit('cancel');
        this.close();
    }
    onSave() {
        this.$emit('save');
    }
    onPrevious() {
        this.$emit('previous');
    }
    onNext() {
        this.$emit('next');
    }
};
__decorate([
    Prop({ type: Boolean, default: true })
], Modal.prototype, "cardText", void 0);
__decorate([
    Prop({ type: String, default: '700px' })
], Modal.prototype, "maxWidth", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "fullscreen", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "noWidth", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "text", void 0);
__decorate([
    Prop({ type: String, default: '' })
], Modal.prototype, "modalTitle", void 0);
__decorate([
    Prop({ type: String, default: '' })
], Modal.prototype, "buttonIcon", void 0);
__decorate([
    Prop({ type: String, default: '' })
], Modal.prototype, "buttonIconSize", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "fabButton", void 0);
__decorate([
    Prop({ type: String, default: '' })
], Modal.prototype, "buttonTitle", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], Modal.prototype, "showSlot", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "showNotification", void 0);
__decorate([
    Prop({ type: String, default: '' })
], Modal.prototype, "notificationTitle", void 0);
__decorate([
    Prop({ type: String, default: '' })
], Modal.prototype, "notificationMessage", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "showSave", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "saveIsDisabled", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "showPrevious", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "previousIsDisabled", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "showNext", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Modal.prototype, "nextIsDisabled", void 0);
__decorate([
    Prop({ type: String, default: '' })
], Modal.prototype, "notificationIcon", void 0);
__decorate([
    Prop({ type: String, default: 'primary' })
], Modal.prototype, "colorButton", void 0);
__decorate([
    Prop({ type: String, default: '' })
], Modal.prototype, "textColorButton", void 0);
Modal = __decorate([
    Component({
        name: 'Modal',
        components: { Notification }
    })
], Modal);
export default Modal;
