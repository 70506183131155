import { __decorate } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
let MyInfoItem = class MyInfoItem extends Vue {
};
__decorate([
    Prop()
], MyInfoItem.prototype, "title", void 0);
__decorate([
    Prop()
], MyInfoItem.prototype, "subtitle", void 0);
__decorate([
    Prop()
], MyInfoItem.prototype, "buttonTitle", void 0);
MyInfoItem = __decorate([
    Component({})
], MyInfoItem);
export default MyInfoItem;
