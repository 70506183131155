import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const overview = namespace('overview');
let Upcoming = class Upcoming extends Vue {
};
Upcoming = __decorate([
    Component({
        name: 'Upcoming'
    })
], Upcoming);
export default Upcoming;
