import { __decorate } from "tslib";
import apolloClient from '@/plugins/apollo';
import router from '@/router';
import gql from 'graphql-tag';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
let Register = class Register extends VuexModule {
    constructor() {
        super(...arguments);
        this.newUser = {
            firstName: '',
            lastName: '',
            mail: '',
            phone: '',
            password: ''
        };
    }
    setNewUser(newUser) {
        this.newUser = newUser;
    }
    async registerOnRemote() {
        const host = process.env.VUE_APP_API_AUTH;
        const newUser = { ...this.newUser, userName: this.newUser.mail };
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Onbording($registerUserInput: RegisterUserInput!) {
          registerUser(registerUserInput: $registerUserInput) {
            userName
            success
          }
        }
      `,
            variables: { registerUserInput: newUser },
            context: {
                uri: host
            }
        });
        if (result &&
            result.data &&
            result.data.registerUser &&
            result.data.registerUser.success) {
            router.push('/waiting-room');
        }
    }
};
__decorate([
    Mutation
], Register.prototype, "setNewUser", null);
__decorate([
    Action
], Register.prototype, "registerOnRemote", null);
Register = __decorate([
    Module({ namespaced: true })
], Register);
export default Register;
