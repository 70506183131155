import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Update from './update.vue';
let UpdateMail = class UpdateMail extends Vue {
};
__decorate([
    Prop()
], UpdateMail.prototype, "user", void 0);
__decorate([
    Prop({ default: false })
], UpdateMail.prototype, "showNotification", void 0);
UpdateMail = __decorate([
    Component({
        name: 'UpdateMail',
        components: { Update }
    })
], UpdateMail);
export default UpdateMail;
