import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
let Update = class Update extends Vue {
};
__decorate([
    Prop({ default: false })
], Update.prototype, "showNotification", void 0);
Update = __decorate([
    Component({
        name: 'Update',
        components: { Modal }
    })
], Update);
export default Update;
