import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
let UpdateUser = class UpdateUser extends Vue {
};
__decorate([
    Prop({ default: false })
], UpdateUser.prototype, "showNotification", void 0);
UpdateUser = __decorate([
    Component({
        name: 'UpdateUser',
        components: { Modal }
    })
], UpdateUser);
export default UpdateUser;
