import auth from '@/guards/auth.guard';
import Request from './../views/request.vue';
import Requests from './../views/requests.vue';
import RequestsManager from './../views/requests-manager.vue';
const requestsRoutes = [
    {
        path: '/request/:id',
        name: 'Request',
        component: Request,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/requests',
        name: 'Requests',
        component: Requests,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/manager/requests',
        name: 'RequestsManager',
        component: RequestsManager,
        meta: {
            middleware: [auth]
        }
    }
];
export default requestsRoutes;
