import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
let UpdateEmployeeBirthdate = class UpdateEmployeeBirthdate extends Vue {
    constructor() {
        super(...arguments);
        this.birthdateMenu = false;
    }
    updateDate(birthdate) {
        this.$emit('birthdate', birthdate);
        this.birthdateMenu = false;
    }
};
__decorate([
    Prop()
], UpdateEmployeeBirthdate.prototype, "employee", void 0);
__decorate([
    Prop({ default: false })
], UpdateEmployeeBirthdate.prototype, "showNotification", void 0);
UpdateEmployeeBirthdate = __decorate([
    Component({
        name: 'UpdateEmployeeBirthdate',
        components: { UpdateEmployee }
    })
], UpdateEmployeeBirthdate);
export default UpdateEmployeeBirthdate;
