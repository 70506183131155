import auth from '@/guards/auth.guard';
import Warehouses from '../warehouses/views/warehouses.vue';
import Warehouse from '../warehouses/views/warehouse.vue';
import NewWarehouse from '../warehouses/views/new-warehouse.vue';
import Item from '../items/views/item.vue';
import Items from '../items/views/items.vue';
import NewItem from '../items/views/new-item.vue';
import Models from '../models/views/models.vue';
import Model from '../models/views/model.vue';
import NewModel from '../models/views/new-model.vue';
import Stocks from '../views/stocks.vue';
const usersRoutes = [
    {
        path: '/stocks',
        name: 'Stocks',
        component: Stocks,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/warehouses',
        name: 'Warehouses',
        component: Warehouses,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/warehouse',
        name: 'Warehouse',
        component: Warehouse,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/warehouses/new',
        name: 'NewWarehouse',
        component: NewWarehouse,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/item',
        name: 'Item',
        component: Item,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/items',
        name: 'Items',
        component: Items,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/items/new',
        name: 'NewItem',
        component: NewItem,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/model',
        name: 'Model',
        component: Model,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/models',
        name: 'Models',
        component: Models,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/models/new',
        name: 'NewModel',
        component: NewModel,
        meta: {
            middleware: [auth]
        }
    }
];
export default usersRoutes;
