import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import Value from '@/components/value.vue';
const roles = namespace('roles');
let DeleteRole = class DeleteRole extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
    async onDelete(_id) {
        const deleted = await this.deleteRoleFromRemote(_id);
        if (deleted) {
            this.showNotification = true;
        }
    }
};
__decorate([
    Prop()
], DeleteRole.prototype, "role", void 0);
__decorate([
    roles.Action
], DeleteRole.prototype, "deleteRoleFromRemote", void 0);
DeleteRole = __decorate([
    Component({
        name: 'DeleteRole',
        components: { Modal, Value }
    })
], DeleteRole);
export default DeleteRole;
