import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
let Paydocs = class Paydocs extends VuexModule {
    constructor() {
        super(...arguments);
        this.payslips = [];
        this.paydocsRequests = [];
        this.employees = [];
        this.myLastPayslip = {};
        this.paydocs = [];
        this.employeeId = '';
    }
    setPayslips(payslips) {
        this.payslips = payslips;
    }
    setMyLastPayslip(myLastPayslip) {
        this.myLastPayslip = myLastPayslip;
    }
    setPaydocsRequests(paydocsRequests) {
        this.paydocsRequests = paydocsRequests;
    }
    setEmployees(employees) {
        this.employees = employees;
    }
    setPaydocs(paydocs) {
        this.paydocs = paydocs;
    }
    async getPayslipsFromRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Paydoc($_id: String!) {
          payslips(_id: $_id) {
            fullName
            category
            period
            creationDate
            fileId
            delivred
          }
        }
      `,
            fetchPolicy: 'network-only',
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.payslips) {
            this.context.commit('setPayslips', result.data.payslips);
        }
    }
    async getMyPayslipsFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Paydoc {
          getMyPayslips {
            fullName
            category
            period
            creationDate
            fileId
            delivred
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getMyPayslips) {
            this.context.commit('setPayslips', result.data.getMyPayslips);
        }
    }
    async getMyLastPayslip() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Paydoc {
          getMyLastPayslip {
            fullName
            category
            period
            creationDate
            fileId
            delivred
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getMyLastPayslip) {
            this.context.commit('setMyLastPayslip', result.data.getMyLastPayslip);
        }
    }
    async getPaydocsRequestsForMeFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Paydoc {
          getPaydocsRequestsForMe {
            _id
            fullName
            category
            period
            creationDate
            delivred
            employee {
              _id
              firstName
              lastName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getPaydocsRequestsForMe) {
            this.context.commit('setPaydocsRequests', result.data.getPaydocsRequestsForMe);
        }
    }
    async getEmployeesFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Employee {
          employees {
            _id
            nId
            ssId
            cId
            firstName
            lastName
            fullName
            terminated
            team {
              _id
              fullName
            }
            teamId
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.employees) {
            this.context.commit('setEmployees', result.data.employees);
        }
    }
    async createRequestPaydoc(requestPaydocInput) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Paydoc($requestPaydocInput: RequestPaydocInput!) {
          createRequestPaydoc(requestPaydocInput: $requestPaydocInput)
        }
      `,
            variables: {
                requestPaydocInput
            },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.createRequestPaydoc) {
            return result.data.createRequestPaydoc;
        }
        return false;
    }
    async uploadFile(data) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Paydoc(
          $file: Upload!
          $employeeId: String
          $category: String!
          $period: DateTime!
          $_id: String
        ) {
          uploadPaydoc(
            file: $file
            employeeId: $employeeId
            category: $category
            period: $period
            _id: $_id
          )
        }
      `,
            variables: {
                file: data.file,
                employeeId: data.employeeId,
                category: data.category,
                period: data.period,
                _id: data._id
            },
            context: {
                uri: host,
                hasUpload: true,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.uploadPaydoc) {
            return result.data.uploadPaydoc;
        }
        return false;
    }
    async downloadPaydoc(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Paydoc($_id: String!) {
          downloadPaydoc(_id: $_id)
        }
      `,
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.downloadPaydoc) {
            window.open(result.data.downloadPaydoc, '_blank');
        }
    }
    async getPaydocsFromRemote(_id = '') {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Paydoc($_id: String!) {
          paydocs(_id: $_id) {
            fullName
            category
            creationDate
            fileId
            period
          }
        }
      `,
            fetchPolicy: 'network-only',
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.paydocs) {
            this.context.commit('setPaydocs', result.data.paydocs);
        }
    }
    async getMyPaydocsFromRemote(_id = '') {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Paydoc {
          getMyPaydocs {
            fullName
            category
            creationDate
            fileId
            delivred
          }
        }
      `,
            fetchPolicy: 'network-only',
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getMyPaydocs) {
            this.context.commit('setPaydocs', result.data.getMyPaydocs);
        }
    }
};
__decorate([
    Mutation
], Paydocs.prototype, "setPayslips", null);
__decorate([
    Mutation
], Paydocs.prototype, "setMyLastPayslip", null);
__decorate([
    Mutation
], Paydocs.prototype, "setPaydocsRequests", null);
__decorate([
    Mutation
], Paydocs.prototype, "setEmployees", null);
__decorate([
    Mutation
], Paydocs.prototype, "setPaydocs", null);
__decorate([
    Action
], Paydocs.prototype, "getPayslipsFromRemote", null);
__decorate([
    Action
], Paydocs.prototype, "getMyPayslipsFromRemote", null);
__decorate([
    Action
], Paydocs.prototype, "getMyLastPayslip", null);
__decorate([
    Action
], Paydocs.prototype, "getPaydocsRequestsForMeFromRemote", null);
__decorate([
    Action
], Paydocs.prototype, "getEmployeesFromRemote", null);
__decorate([
    Action({ rawError: true })
], Paydocs.prototype, "createRequestPaydoc", null);
__decorate([
    Action({ rawError: true })
], Paydocs.prototype, "uploadFile", null);
__decorate([
    Action({ rawError: true })
], Paydocs.prototype, "downloadPaydoc", null);
__decorate([
    Action({ rawError: true })
], Paydocs.prototype, "getPaydocsFromRemote", null);
__decorate([
    Action({ rawError: true })
], Paydocs.prototype, "getMyPaydocsFromRemote", null);
Paydocs = __decorate([
    Module({ namespaced: true })
], Paydocs);
export default Paydocs;
