import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import imageCompression from 'browser-image-compression';
let Employees = class Employees extends VuexModule {
    constructor() {
        super(...arguments);
        this.newEmployee = {
            nId: '',
            ssId: '',
            cId: '',
            firstName: '',
            lastName: '',
            fullName: '',
            mail: '',
            phone: '',
            contractType: '',
            baseSalary: 0,
            timeOffBalance: 0,
            team: '',
            teamId: '',
            jobTitle: '',
            terminated: false,
            familySituation: ''
        };
        this.employee = {
            _id: '',
            nId: '',
            ssId: '',
            cId: '',
            firstName: '',
            lastName: '',
            fullName: '',
            mail: '',
            phone: '',
            birthdate: new Date(),
            dateOfHire: new Date(),
            contractType: '',
            baseSalary: 0,
            timeOffBalance: 0,
            team: { _id: '', fullName: '' },
            teamId: '',
            jobTitle: '',
            terminated: false,
            familySituation: '',
            nationality: ''
        };
        this.employees = [];
    }
    clearNewEmployee() {
        this.newEmployee = {
            nId: '',
            ssId: '',
            cId: '',
            firstName: '',
            lastName: '',
            fullName: '',
            mail: '',
            phone: '',
            contractType: '',
            baseSalary: 0,
            timeOffBalance: 0,
            team: '',
            teamId: '',
            jobTitle: '',
            terminated: false,
            familySituation: ''
        };
    }
    setEmployee(employee) {
        this.employee = employee;
    }
    setEmployees(employees) {
        this.employees = employees;
    }
    setNationality(nationality) {
        this.newEmployee = { ...this.newEmployee, nationality };
    }
    setTeamId(teamId) {
        this.newEmployee = { ...this.newEmployee, teamId };
    }
    async getEmployeeFromRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Employee($_id: String!) {
          employee(_id: $_id) {
            _id
            nId
            ssId
            cId
            firstName
            lastName
            fullName
            mail
            phone
            birthdate
            nationality
            dateOfHire
            contractType
            baseSalary
            timeOffBalance
            team {
              _id
              fullName
            }
            teamId
            jobTitle
            terminated
            familySituation
            avatar
          }
        }
      `,
            variables: { _id },
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.employee) {
            this.context.commit('setEmployee', result.data.employee);
        }
    }
    async getMyEmployeesFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Employee {
          myEmployees {
            _id
            nId
            ssId
            cId
            firstName
            lastName
            fullName
            mail
            phone
            birthdate
            nationality
            dateOfHire
            contractType
            baseSalary
            timeOffBalance
            team {
              _id
              fullName
            }
            teamId
            jobTitle
            terminated
            familySituation
            avatar
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.myEmployees) {
            this.context.commit('setEmployees', result.data.myEmployees);
        }
    }
    async getEmployeesFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Employee {
          employees {
            _id
            nId
            ssId
            cId
            firstName
            lastName
            fullName
            mail
            phone
            birthdate
            nationality
            dateOfHire
            contractType
            baseSalary
            timeOffBalance
            team {
              _id
              fullName
            }
            teamId
            jobTitle
            terminated
            familySituation
            avatar
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.employees) {
            this.context.commit('setEmployees', result.data.employees);
        }
    }
    async createEmployee() {
        const { token, host } = this.context?.rootState?.auth;
        const { newEmployee } = this;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Employee($createEmployeeInput: CreateEmployeeInput!) {
          createEmployee(createEmployeeInput: $createEmployeeInput) {
            lastName
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            variables: { createEmployeeInput: newEmployee }
        });
        if (result && result.data && result.data.createEmployee) {
            this.context.dispatch('getMyEmployeesFromRemote');
            return true;
        }
        return false;
    }
    async updateEmployee(employee) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Employee($updateEmployeeInput: UpdateEmployeeInput!) {
          updateEmployee(updateEmployeeInput: $updateEmployeeInput)
        }
      `,
            variables: { updateEmployeeInput: employee },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.updateEmployee) {
            this.context.dispatch('getMyEmployeesFromRemote');
            return true;
        }
        return false;
    }
    async updateAvatar(updateAvatarInput) {
        const { token, host } = this.context?.rootState?.auth;
        const { profile } = this.context?.rootState?.profile;
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 500,
            useWebWorker: true
        };
        const compressedAvatar = await imageCompression(updateAvatarInput.avatar, options);
        const reader = new FileReader();
        reader.onload = async (e) => {
            const updateEmployeeInput = {
                _id: updateAvatarInput._id,
                avatar: e.target?.result
            };
            const result = await apolloClient.mutate({
                mutation: gql `
          mutation Employee($updateEmployeeInput: UpdateEmployeeInput!) {
            updateEmployee(updateEmployeeInput: $updateEmployeeInput)
          }
        `,
                variables: { updateEmployeeInput },
                context: {
                    uri: host,
                    hasUpload: true,
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            });
            if (result && result.data && result.data.updateEmployee) {
                this.context.dispatch('getEmployeeFromRemote', this.employee._id);
                this.context.dispatch('getMyEmployeesFromRemote');
                if (this.employee._id === profile?.employeeId) {
                    this.context.dispatch('profile/getMyProfile', '', { root: true });
                }
            }
            return false;
        };
        reader.onerror = error => {
            console.log('Error: ', error);
            return false;
        };
        await reader.readAsDataURL(compressedAvatar);
        return true;
    }
};
__decorate([
    Mutation
], Employees.prototype, "clearNewEmployee", null);
__decorate([
    Mutation
], Employees.prototype, "setEmployee", null);
__decorate([
    Mutation
], Employees.prototype, "setEmployees", null);
__decorate([
    Mutation
], Employees.prototype, "setNationality", null);
__decorate([
    Mutation
], Employees.prototype, "setTeamId", null);
__decorate([
    Action
], Employees.prototype, "getEmployeeFromRemote", null);
__decorate([
    Action
], Employees.prototype, "getMyEmployeesFromRemote", null);
__decorate([
    Action
], Employees.prototype, "getEmployeesFromRemote", null);
__decorate([
    Action
], Employees.prototype, "createEmployee", null);
__decorate([
    Action
], Employees.prototype, "updateEmployee", null);
__decorate([
    Action
], Employees.prototype, "updateAvatar", null);
Employees = __decorate([
    Module({ namespaced: true })
], Employees);
export default Employees;
