import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UploadDocument from '@/components/upload-document.vue';
const paydocs = namespace('paydocs');
let UploadPayrollSummary = class UploadPayrollSummary extends Vue {
    constructor() {
        super(...arguments);
        this.success = false;
    }
    async upload(file) {
        const fileToSave = {
            file,
            category: 'Payroll',
            type: 'Summary',
            period: this.period
        };
        console.log('fileToSave', fileToSave);
        const result = await this.uploadFile(fileToSave);
        if (result) {
            this.success = true;
            this.$emit('success', true);
        }
    }
};
__decorate([
    Prop()
], UploadPayrollSummary.prototype, "period", void 0);
__decorate([
    Prop()
], UploadPayrollSummary.prototype, "payroll", void 0);
__decorate([
    Prop()
], UploadPayrollSummary.prototype, "disabled", void 0);
__decorate([
    paydocs.Action
], UploadPayrollSummary.prototype, "uploadFile", void 0);
UploadPayrollSummary = __decorate([
    Component({
        name: 'UploadPayrollSummary',
        components: { UploadDocument }
    })
], UploadPayrollSummary);
export default UploadPayrollSummary;
