import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const application = namespace('application');
let Admin = class Admin extends Vue {
    created() {
        this.setIsNavigation(true);
    }
};
__decorate([
    application.Mutation
], Admin.prototype, "setIsNavigation", void 0);
Admin = __decorate([
    Component({
        name: 'Admin',
        components: { Header }
    })
], Admin);
export default Admin;
