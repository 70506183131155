import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateRole from './update-role.vue';
import { namespace } from 'vuex-class';
const roles = namespace('roles');
let UpdateServices = class UpdateServices extends Vue {
    constructor() {
        super(...arguments);
        this.selectedServices = [];
        this.model = '';
        this.search = '';
        this.headers = [
            {
                text: 'Fullname',
                align: 'start',
                value: 'fullName'
            },
            {
                text: 'Level',
                align: 'start',
                value: 'level'
            },
            {
                text: 'Description',
                align: 'start',
                value: 'description'
            }
        ];
    }
    async created() {
        if (this.role && this.role.services) {
            this.selectedServices = this.role.services;
        }
        await this.getServices();
        await this.getModels();
    }
    selectModel(model) {
        console.log('model', model);
        if (model && model.services) {
            this.selectedServices = model.services;
        }
    }
};
__decorate([
    Prop()
], UpdateServices.prototype, "role", void 0);
__decorate([
    Prop({ default: false })
], UpdateServices.prototype, "showNotification", void 0);
__decorate([
    roles.State
], UpdateServices.prototype, "services", void 0);
__decorate([
    roles.State
], UpdateServices.prototype, "models", void 0);
__decorate([
    roles.Action
], UpdateServices.prototype, "getServices", void 0);
__decorate([
    roles.Action
], UpdateServices.prototype, "getModels", void 0);
UpdateServices = __decorate([
    Component({
        name: 'UpdateServices',
        components: { UpdateRole }
    })
], UpdateServices);
export default UpdateServices;
