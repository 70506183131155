import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const application = namespace('application');
let Projects = class Projects extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Fullname',
                align: 'start',
                value: 'employee'
            },
            {
                text: 'Type',
                align: 'start',
                value: 'type'
            },
            {
                text: 'Creation Date',
                align: 'start',
                value: 'creationDate'
            }
        ];
        this.projects = [];
    }
    async created() {
        this.setIsNavigation(true);
        this.setTitle('Project(s)');
    }
};
__decorate([
    application.Mutation
], Projects.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], Projects.prototype, "setTitle", void 0);
Projects = __decorate([
    Component({
        name: 'Projects'
    })
], Projects);
export default Projects;
