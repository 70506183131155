import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Users from './../components/users.vue';
import Roles from './../components/roles.vue';
import Teams from './../components/teams.vue';
import User from './../components/user/user.vue';
import Role from './../components/role/role.vue';
import Team from './../components/team/team.vue';
import NewUser from './../components/user/new-user.vue';
import NewRole from './../components/role/new-role.vue';
import NewTeam from './../components/team/new-team.vue';
import ApplicationSettings from '../components/application.vue';
const application = namespace('application');
let Settings = class Settings extends Vue {
    constructor() {
        super(...arguments);
        this.drawer = false;
    }
    created() {
        this.setIsNavigation(true);
        this.setTitle('Settings');
    }
    onUsers() {
        this.$router.push('/manager/settings/users');
    }
    onApplication() {
        this.$router.push('/manager/settings/application');
    }
    onRoles() {
        this.$router.push('/manager/settings/roles');
    }
    onTeams() {
        this.$router.push('/manager/settings/teams');
    }
};
__decorate([
    application.Mutation
], Settings.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], Settings.prototype, "setTitle", void 0);
Settings = __decorate([
    Component({
        name: 'Settings',
        components: {
            Header,
            Users,
            Roles,
            Teams,
            ApplicationSettings,
            User,
            Role,
            Team,
            NewUser,
            NewRole,
            NewTeam
        }
    })
], Settings);
export default Settings;
