import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const paydocs = namespace('paydocs');
let SelectCategory = class SelectCategory extends Vue {
    constructor() {
        super(...arguments);
        this.categories = ['Payslip', 'Tax', 'Other'];
    }
};
__decorate([
    Prop()
], SelectCategory.prototype, "category", void 0);
SelectCategory = __decorate([
    Component({
        name: 'SelectCategory'
    })
], SelectCategory);
export default SelectCategory;
