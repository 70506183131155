import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Update from './update.vue';
let UpdateBank = class UpdateBank extends Vue {
};
__decorate([
    Prop()
], UpdateBank.prototype, "user", void 0);
__decorate([
    Prop({ default: false })
], UpdateBank.prototype, "showNotification", void 0);
UpdateBank = __decorate([
    Component({
        name: 'UpdateBank',
        components: { Update }
    })
], UpdateBank);
export default UpdateBank;
