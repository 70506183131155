import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let Model = class Model extends Vue {
};
Model = __decorate([
    Component({
        name: "Model"
    })
], Model);
export default Model;
