import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const warehouses = namespace('warehouses');
let NewWarehouse = class NewWarehouse extends Vue {
    constructor() {
        super(...arguments);
        this.ref = '';
        this.name = '';
        this.description = '';
        this.tel = '';
        this.street = '';
        this.city = '';
        this.state = '';
        this.zipCode = '';
        this.country = '';
        this.valid = false;
        this.nameRules = [(value) => !!value || 'Le nom est obligatoire'];
    }
    onSave() {
        this.saveWarehouseOnRemote();
    }
};
__decorate([
    warehouses.Action
], NewWarehouse.prototype, "saveWarehouseOnRemote", void 0);
__decorate([
    warehouses.Action
], NewWarehouse.prototype, "saveName", void 0);
__decorate([
    warehouses.Action
], NewWarehouse.prototype, "saveRef", void 0);
__decorate([
    warehouses.Action
], NewWarehouse.prototype, "saveTel", void 0);
__decorate([
    warehouses.Action
], NewWarehouse.prototype, "saveDescription", void 0);
__decorate([
    warehouses.Action
], NewWarehouse.prototype, "saveStreet", void 0);
__decorate([
    warehouses.Action
], NewWarehouse.prototype, "saveCity", void 0);
__decorate([
    warehouses.Action
], NewWarehouse.prototype, "saveState", void 0);
__decorate([
    warehouses.Action
], NewWarehouse.prototype, "saveZipCode", void 0);
__decorate([
    warehouses.Action
], NewWarehouse.prototype, "saveCountry", void 0);
NewWarehouse = __decorate([
    Component({
        name: 'NewWarehouse'
    })
], NewWarehouse);
export default NewWarehouse;
