import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let ToDoListItem = class ToDoListItem extends Vue {
};
__decorate([
    Prop()
], ToDoListItem.prototype, "item", void 0);
ToDoListItem = __decorate([
    Component({})
], ToDoListItem);
export default ToDoListItem;
