import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import BadgeMenu from './badge-menu.vue';
const application = namespace('application');
let Menu = class Menu extends Vue {
    constructor() {
        super(...arguments);
        this.showMenu = false;
        this.itemsAdmin = [
            {
                action: 'mdi-cog',
                title: 'Settings',
                path: '/manager/settings',
                items: [],
                enable: true
            }
        ];
    }
    onButton(path) {
        this.$router.push(path);
    }
    get menu() {
        const m = [
            {
                action: 'mdi-view-dashboard',
                title: 'Overview',
                noAction: false,
                path: '/manager/overview',
                items: [],
                enable: this.profile?.role?.isManager || false,
                manager: true
            },
            {
                action: 'mdi-account-group',
                title: 'People',
                path: '/employees',
                items: [],
                enable: this.is('People'),
                manager: true
            },
            /*   {
              action: 'mdi-folder-open',
              title: 'Project(s)',
              path: '/manager/projects',
              items: [],
              enable: true,
              manager: true
            }, */
            {
                action: 'mdi-file-document-multiple-outline',
                title: 'Pay',
                path: '/manager/paydocs',
                items: [],
                enable: this.is('Pay Manager') || false,
                manager: true
            },
            {
                action: 'mdi-calendar-multiselect',
                title: 'Time Off',
                path: '/manager/timeOff',
                items: [],
                enable: this.is('Time Off Manager') || false,
                manager: true
            },
            {
                action: 'mdi-frequently-asked-questions',
                title: 'HO & Queries',
                path: '/manager/requests',
                items: [],
                enable: this.is('Request Manager') || false,
                manager: true
            },
            {
                action: 'mdi-view-dashboard',
                title: 'Overview',
                noAction: false,
                path: '/overview',
                items: [],
                enable: true,
                manager: false
            },
            {
                action: 'mdi-information',
                title: 'My Info',
                path: '/myInfo',
                items: [],
                enable: false,
                manager: false
            },
            /* {
              action: 'mdi-folder-open',
              title: 'Project(s)',
              path: '/projects',
              items: [],
              enable: true,
              manager: false
            }, */
            {
                action: 'mdi-file-document-multiple-outline',
                title: 'Pay',
                path: '/paydocs',
                items: [],
                enable: this.is('Pay') || false,
                manager: false
            },
            {
                action: 'mdi-calendar-multiselect',
                title: 'Timeoff',
                path: '/timeOff',
                items: [],
                enable: this.is('Time Off') || false,
                manager: false
            },
            {
                action: 'mdi-frequently-asked-questions',
                title: 'HO & Queries',
                path: '/requests',
                items: [],
                enable: this.is('Request') || false,
                manager: false
            },
            /* {
              action: 'mdi-frequently-asked-questions',
              title: 'Stock',
              path: '/stocks',
              items: [],
              enable: true,
              manager: true
            } */
            {
                action: 'mdi-cog',
                title: 'Settings',
                path: '/manager/settings',
                items: [],
                enable: this.profile?.role?.isSettings || false,
                manager: true
            }
        ];
        return m;
    }
    is(service) {
        const exist = this.profile.role?.services?.filter(item => item.fullName === service);
        return (exist && exist.length > 0) || false;
    }
};
__decorate([
    application.State
], Menu.prototype, "mode", void 0);
__decorate([
    application.State
], Menu.prototype, "logoPath", void 0);
__decorate([
    Prop()
], Menu.prototype, "isMenu", void 0);
__decorate([
    Prop()
], Menu.prototype, "profile", void 0);
__decorate([
    Prop()
], Menu.prototype, "items", void 0);
Menu = __decorate([
    Component({
        name: 'Menu',
        components: { BadgeMenu }
    })
], Menu);
export default Menu;
