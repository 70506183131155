import { __decorate } from "tslib";
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
let Managers = class Managers extends VuexModule {
    constructor() {
        super(...arguments);
        this.managers = [];
        this.managersNotTeam = [];
    }
    setManagers(managers) {
        this.managers = managers;
    }
    setManagersNotTeam(managersNotTeam) {
        this.managersNotTeam = managersNotTeam;
    }
    async getManagersFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Users {
          findAllManagers {
            _id
            firstName
            lastName
            roleId
            role {
              _id
              fullName
            }
            teams {
              _id
              fullName
            }
            mail
            employeeId
            status
            employee {
              _id
              firstName
              lastName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findAllManagers)
            this.context.commit('setManagers', result.data.findAllManagers);
    }
    async getManagersNotTeamFromRemote(teamId) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query User($_id: String!) {
          findAllManagersNotTeam(_id: $_id) {
            _id
            firstName
            lastName
            roleId
            role {
              _id
              fullName
            }
            mail
          }
        }
      `,
            variables: { _id: teamId },
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findAllManagersNotTeam) {
            this.context.commit('setManagersNotTeam', result.data.findAllManagersNotTeam);
            return true;
        }
        return false;
    }
};
__decorate([
    Mutation
], Managers.prototype, "setManagers", null);
__decorate([
    Mutation
], Managers.prototype, "setManagersNotTeam", null);
__decorate([
    Action({ rawError: true })
], Managers.prototype, "getManagersFromRemote", null);
__decorate([
    Action({ rawError: true })
], Managers.prototype, "getManagersNotTeamFromRemote", null);
Managers = __decorate([
    Module({ namespaced: true })
], Managers);
export default Managers;
