import auth from '@/guards/auth.guard';
import Projects from '../views/projects.vue';
import ProjectsManager from '../views/projects-manager.vue';
const projectsRoutes = [
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/manager/projects',
        name: 'ProjectsManager',
        component: ProjectsManager,
        meta: {
            middleware: [auth]
        }
    }
];
export default projectsRoutes;
