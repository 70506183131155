import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import router from '@/router';
let Items = class Items extends VuexModule {
    constructor() {
        super(...arguments);
        this.models = [];
        this.model = { fullName: '' };
    }
    get getModels() {
        return this.models;
    }
    setModels(models) {
        this.models = models;
    }
    setName(fullName) {
        this.model = { ...this.model, fullName };
    }
    setDescription(description) {
        this.model = { ...this.model, description };
    }
    setRef(ref) {
        this.model = { ...this.model, ref };
    }
    async fetchModels() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Models {
          getModels {
            _id
            ref
            fullName
            description
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        return result;
    }
    async pullModels() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Models {
          getModels {
            _id
            ref
            fullName
            description
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            fetchPolicy: 'network-only'
        });
        this.context.commit('setModels', result?.data?.getModels);
        return result;
    }
    async saveModelOnRemote() {
        const { token, host } = this.context?.rootState?.auth;
        await apolloClient.mutate({
            mutation: gql `
        mutation Model($model: AddModelInput!) {
          addModel(model: $model) {
            _id
            ref
            fullName
            description
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            variables: { model: this.model }
        });
        router.push({ path: '/models' });
    }
    async removeModelFromRemote(model) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Items($model: removeModelInput) {
            removeModel(model: $model) {}
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            variables: { model }
        });
        return result;
    }
    saveRef(ref) {
        this.context.commit('setRef', ref);
    }
    saveName(fullName) {
        this.context.commit('setName', fullName);
    }
    saveDescription(description) {
        this.context.commit('setDescription', description);
    }
};
__decorate([
    Mutation
], Items.prototype, "setModels", null);
__decorate([
    Mutation
], Items.prototype, "setName", null);
__decorate([
    Mutation
], Items.prototype, "setDescription", null);
__decorate([
    Mutation
], Items.prototype, "setRef", null);
__decorate([
    Action({ rawError: true })
], Items.prototype, "fetchModels", null);
__decorate([
    Action({ rawError: true })
], Items.prototype, "pullModels", null);
__decorate([
    Action({ rawError: true })
], Items.prototype, "saveModelOnRemote", null);
__decorate([
    Action
], Items.prototype, "removeModelFromRemote", null);
__decorate([
    Action
], Items.prototype, "saveRef", null);
__decorate([
    Action
], Items.prototype, "saveName", null);
__decorate([
    Action
], Items.prototype, "saveDescription", null);
Items = __decorate([
    Module({ namespaced: true })
], Items);
export default Items;
