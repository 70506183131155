import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import router from '@/router';
let Items = class Items extends VuexModule {
    constructor() {
        super(...arguments);
        this.items = [];
        this.item = {
            fullName: '',
            modelId: '',
            warehouseId: '',
            stock: 0
        };
    }
    get getItems() {
        return this.items;
    }
    setItems(items) {
        this.items = items;
    }
    setItem(item) {
        this.item = item;
    }
    setRef(ref) {
        this.item = { ...this.item, ref };
    }
    setStock(stock) {
        this.item = { ...this.item, stock };
    }
    setModel(model) {
        this.item = {
            ...this.item,
            modelId: model._id
        };
    }
    setModelId(modelId) {
        this.item = { ...this.item, modelId };
    }
    setWarehouse(warehouse) {
        this.item = {
            ...this.item,
            warehouseId: warehouse._id
        };
    }
    setWarehouseId(warehouseId) {
        this.item = { ...this.item, warehouseId };
    }
    setName(fullName) {
        this.item = { ...this.item, fullName };
    }
    setDescription(description) {
        this.item = { ...this.item, description };
    }
    clearItem() {
        this.item = {
            fullName: '',
            modelId: '',
            warehouseId: '',
            stock: 0
        };
    }
    saveRef(ref) {
        this.context.commit('setRef', ref);
    }
    saveName(fullName) {
        this.context.commit('setName', fullName);
    }
    saveDescription(description) {
        this.context.commit('setName', description);
    }
    saveStock(stock) {
        this.context.commit('setStock', stock);
    }
    saveWarehouse(warehouse) {
        this.context.commit('setWarehouse', warehouse);
    }
    saveModel(model) {
        this.context.commit('setModel', model);
        this.context.commit('setName', model.fullName);
    }
    selectItem(item) {
        const formatedItem = { ...item, __typename: '' };
        delete formatedItem.__typename;
        this.context.commit('setItem', formatedItem);
    }
    async fetchItems() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Items {
          getItems {
            _id
            ref
            fullName
            description
            stock
            modelId
            warehouseId
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        return result;
    }
    async pullItems() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Items {
          getItems {
            _id
            ref
            fullName
            description
            stock
            modelId
            warehouseId
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            fetchPolicy: 'network-only'
        });
        this.context.commit('setItems', result?.data?.getItems);
        return result;
    }
    async saveItemOnRemote() {
        const { token, host } = this.context?.rootState?.auth;
        await apolloClient.mutate({
            mutation: gql `
        mutation Items($item: AddItemInput!) {
          addItem(item: $item) {
            _id
            ref
            fullName
            description
            stock
            modelId
            warehouseId
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            variables: { item: this.item }
        });
        router.push({ path: '/items' });
    }
    async updateItemOnRemote() {
        const { token, host } = this.context?.rootState?.auth;
        await apolloClient.mutate({
            mutation: gql `
        mutation Items($item: UpdateItemInput!) {
          updateItem(item: $item) {
            _id
            ref
            fullName
            description
            stock
            modelId
            warehouseId
          }
        }
      `,
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            },
            variables: { item: this.item }
        });
        this.context.commit('clearItem');
        this.context.dispatch('pullItems');
    }
    async removeItem() {
        const { token, host } = this.context?.rootState?.auth;
        try {
            await apolloClient.mutate({
                mutation: gql `
          mutation Items($item: RemoveItemInput!) {
            removeItem(item: $item)
          }
        `,
                context: {
                    uri: host,
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                },
                variables: { item: this.item }
            });
        }
        catch (error) {
            return error;
        }
        this.context.commit('clearItem');
        this.context.dispatch('pullItems');
    }
};
__decorate([
    Mutation
], Items.prototype, "setItems", null);
__decorate([
    Mutation
], Items.prototype, "setItem", null);
__decorate([
    Mutation
], Items.prototype, "setRef", null);
__decorate([
    Mutation
], Items.prototype, "setStock", null);
__decorate([
    Mutation
], Items.prototype, "setModel", null);
__decorate([
    Mutation
], Items.prototype, "setModelId", null);
__decorate([
    Mutation
], Items.prototype, "setWarehouse", null);
__decorate([
    Mutation
], Items.prototype, "setWarehouseId", null);
__decorate([
    Mutation
], Items.prototype, "setName", null);
__decorate([
    Mutation
], Items.prototype, "setDescription", null);
__decorate([
    Mutation
], Items.prototype, "clearItem", null);
__decorate([
    Action
], Items.prototype, "saveRef", null);
__decorate([
    Action
], Items.prototype, "saveName", null);
__decorate([
    Action
], Items.prototype, "saveDescription", null);
__decorate([
    Action
], Items.prototype, "saveStock", null);
__decorate([
    Action
], Items.prototype, "saveWarehouse", null);
__decorate([
    Action
], Items.prototype, "saveModel", null);
__decorate([
    Action
], Items.prototype, "selectItem", null);
__decorate([
    Action({ rawError: true })
], Items.prototype, "fetchItems", null);
__decorate([
    Action({ rawError: true })
], Items.prototype, "pullItems", null);
__decorate([
    Action({ rawError: true })
], Items.prototype, "saveItemOnRemote", null);
__decorate([
    Action({ rawError: true })
], Items.prototype, "updateItemOnRemote", null);
__decorate([
    Action({ rawError: true })
], Items.prototype, "removeItem", null);
Items = __decorate([
    Module({ namespaced: true })
], Items);
export default Items;
