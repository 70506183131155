import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateRole from './update-role.vue';
let UpdateTeamDescription = class UpdateTeamDescription extends Vue {
};
__decorate([
    Prop()
], UpdateTeamDescription.prototype, "role", void 0);
__decorate([
    Prop({ default: false })
], UpdateTeamDescription.prototype, "showNotification", void 0);
UpdateTeamDescription = __decorate([
    Component({
        name: 'UpdateTeamDescription',
        components: { UpdateRole }
    })
], UpdateTeamDescription);
export default UpdateTeamDescription;
