import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
const requests = namespace('requests');
let PayrollIssuesRequest = class PayrollIssuesRequest extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
        this.loading = false;
        this.types = ['Payslip', 'Payment', 'Tax', 'Other'];
    }
    created() {
        this.clearNewRequest();
    }
    async save() {
        this.loading = true;
        const result = await this.createRequest();
        if (result) {
            this.showNotification = true;
        }
        this.loading = false;
    }
    open() {
        this.setCategory('Payroll');
    }
    closeDialog() {
        this.showNotification = false;
        this.clearNewRequest();
        this.loading = false;
    }
};
__decorate([
    requests.State
], PayrollIssuesRequest.prototype, "newRequest", void 0);
__decorate([
    requests.Mutation
], PayrollIssuesRequest.prototype, "clearNewRequest", void 0);
__decorate([
    requests.Mutation
], PayrollIssuesRequest.prototype, "setCategory", void 0);
__decorate([
    requests.Action
], PayrollIssuesRequest.prototype, "createRequest", void 0);
PayrollIssuesRequest = __decorate([
    Component({
        name: 'PayrollIssuesRequest',
        components: { Header, Modal }
    })
], PayrollIssuesRequest);
export default PayrollIssuesRequest;
