var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-navigation-drawer',{attrs:{"temporary":"","absolute":"","right":"","width":"70%"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('User')],1)],1),(_vm.isList === 0)?_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.displayUsers,"items-per-page":5},on:{"click:row":_vm.onUser},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"table__header",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","hide-details":"auto","single-line":"","prepend-icon":"mdi-magnify"},on:{"input":_vm.searchByName}}),_c('v-col',{attrs:{"xs":"12","sm":"auto"}},[_c('v-btn-toggle',{attrs:{"color":"primary"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-btn',{on:{"click":function($event){return _vm.onChangeTab(0)}}},[_c('span',{staticClass:"users__btn__label"},[_vm._v("All")])]),_c('v-btn',{on:{"click":function($event){return _vm.onChangeTab(1)}}},[_c('span',{staticClass:"users__btn__label"},[_vm._v("Pending")])]),_c('v-btn',{on:{"click":function($event){return _vm.onChangeTab(2)}}},[_c('span',{staticClass:"users__btn__label"},[_vm._v("Current")])])],1)],1),_c('v-col',{staticClass:"d-none d-sm-flex",attrs:{"cols":"auto"}},[_c('v-btn-toggle',{attrs:{"color":"primary"},model:{value:(_vm.isList),callback:function ($$v) {_vm.isList=$$v},expression:"isList"}},[_c('v-btn',{on:{"click":_vm.onList}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-view-list ")])],1),_c('v-btn',{on:{"click":_vm.onCard}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-view-column ")])],1)],1)],1)],1)]},proxy:true},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"users--avatar",attrs:{"color":"primary","size":"50"}},[_c('v-img',{attrs:{"src":item.avatar}})],1),_vm._v(" "+_vm._s((" " + (item.firstName) + " " + (item.lastName)))+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (item.role ? item.role.fullName : '')))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
              approved: item.status === 'active',
              rejected: item.status === 'terminated'
            }},[_vm._v(" "+_vm._s(item.status)+" ")]),(item.status === '')?_c('div',{staticClass:"requested"},[_vm._v(" Pending ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.onUser(item)}}},[_vm._v(" Manage ")])]}}],null,false,3423252541)})],1)],1):_vm._e(),(_vm.isList === 1)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Search","hide-details":"auto","single-line":"","prepend-icon":"mdi-magnify"},on:{"input":_vm.searchByName}})],1),_c('v-col',{attrs:{"xs":"12","sm":"auto"}},[_c('v-btn-toggle',{attrs:{"color":"primary"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-btn',{on:{"click":function($event){return _vm.onChangeTab(0)}}},[_c('span',{staticClass:"users__btn__label"},[_vm._v("All")])]),_c('v-btn',{on:{"click":function($event){return _vm.onChangeTab(1)}}},[_c('span',{staticClass:"users__btn__label"},[_vm._v("Pending")])]),_c('v-btn',{on:{"click":function($event){return _vm.onChangeTab(2)}}},[_c('span',{staticClass:"users__btn__label"},[_vm._v("Current")])])],1)],1),_c('v-col',{staticClass:"d-none d-sm-flex",attrs:{"cols":"auto"}},[_c('v-btn-toggle',{attrs:{"color":"primary"},model:{value:(_vm.isList),callback:function ($$v) {_vm.isList=$$v},expression:"isList"}},[_c('v-btn',{on:{"click":_vm.onList}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-view-list ")])],1),_c('v-btn',{on:{"click":_vm.onCard}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-view-column ")])],1)],1)],1)],1):_vm._e(),(_vm.isList === 1)?_c('UserList',{attrs:{"users":_vm.displayUsers},on:{"user":_vm.onUser}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }