import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
import Label from '@/components/label.vue';
import Value from '@/components/value.vue';
import LabelAndValue from '@/components/label-and-value.vue';
let TeamListItem = class TeamListItem extends Vue {
    constructor() {
        super(...arguments);
        this.stackedMenu = false;
        this.stackedManagerMenu = false;
        this.menuMaxHeight = '200px';
        this.avatarsStackedLimited = [];
        this.managerAvatarsStackedLimited = [];
    }
    created() {
        if (this.team && this.team.members) {
            this.avatarsStackedLimited = this.team.members.slice(0, 3);
        }
        if (this.team && this.team.managers) {
            this.managerAvatarsStackedLimited = this.team.managers.slice(0, 3);
        }
    }
};
__decorate([
    Prop()
], TeamListItem.prototype, "team", void 0);
TeamListItem = __decorate([
    Component({
        name: 'TeamListItem',
        components: { Modal, Label, LabelAndValue, Value }
    })
], TeamListItem);
export default TeamListItem;
