import { __decorate } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
let UpdateAvatar = class UpdateAvatar extends Vue {
    constructor() {
        super(...arguments);
        this.isSelecting = false;
        this.srcImage = '';
    }
    onFileChanged(file) {
        this.$emit('save', file.target.files[0]);
    }
    onButtonClick() {
        this.isSelecting = true;
        window.addEventListener('focus', () => {
            this.isSelecting = false;
        }, { once: true });
        this.$refs.uploader.click();
    }
};
__decorate([
    Prop({ default: false })
], UpdateAvatar.prototype, "icon", void 0);
UpdateAvatar = __decorate([
    Component({ name: 'UpdateAvatar' })
], UpdateAvatar);
export default UpdateAvatar;
