import { __decorate } from "tslib";
import { namespace } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Badge from './badge.vue';
const timeOff = namespace('timeOff');
const requests = namespace('requests');
let BadgeMenu = class BadgeMenu extends Vue {
    async created() {
        if (this.item) {
            this.resolver(this.item);
        }
    }
    async resolver(item) {
        if (item && item.title === 'Time Off' && item.manager) {
            await this.getTimeOffForMeCount();
        }
        else if (item && item.title === 'Requests' && item.manager) {
            await this.getRequestsForMeCount();
        }
    }
};
__decorate([
    Prop()
], BadgeMenu.prototype, "item", void 0);
__decorate([
    Prop()
], BadgeMenu.prototype, "profile", void 0);
__decorate([
    timeOff.State
], BadgeMenu.prototype, "timeOffCount", void 0);
__decorate([
    requests.State
], BadgeMenu.prototype, "requestsCount", void 0);
__decorate([
    timeOff.Action
], BadgeMenu.prototype, "getTimeOffForMeCount", void 0);
__decorate([
    requests.Action
], BadgeMenu.prototype, "getRequestsForMeCount", void 0);
BadgeMenu = __decorate([
    Component({ components: { Badge } })
], BadgeMenu);
export default BadgeMenu;
