import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import Value from '@/components/value.vue';
const teams = namespace('teams');
let DeleteTeam = class DeleteTeam extends Vue {
    constructor() {
        super(...arguments);
        this.showNotification = false;
    }
};
__decorate([
    Prop()
], DeleteTeam.prototype, "team", void 0);
__decorate([
    teams.Action
], DeleteTeam.prototype, "deleteTeamFromRemote", void 0);
DeleteTeam = __decorate([
    Component({
        name: 'DeleteTeam',
        components: { Modal, Value }
    })
], DeleteTeam);
export default DeleteTeam;
