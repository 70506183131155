import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
let TimeOff = class TimeOff extends VuexModule {
    constructor() {
        super(...arguments);
        this.newTimeOff = {
            type: '',
            startDate: undefined,
            endDate: undefined,
            creationDate: new Date(),
            value: 0,
            status: '',
            note: ''
        };
        this.timeOffBalance = 0;
        this.timeOff = [];
        this.timeOffCount = 0;
        this.startDateDisplay = '';
        this.endDateDisplay = '';
        this.plrSummaries = [];
    }
    clearNewTimeOff() {
        this.newTimeOff = {
            type: '',
            startDate: undefined,
            endDate: undefined,
            creationDate: new Date(),
            value: 0,
            status: '',
            note: ''
        };
    }
    setPLRSummaries(plrSummaries) {
        this.plrSummaries = plrSummaries;
    }
    setNewTimeOff(newTimeOff) {
        this.newTimeOff = newTimeOff;
    }
    setTimeOffBalance(timeOffBalance) {
        this.timeOffBalance = timeOffBalance;
    }
    setTimeOff(timeOff) {
        this.timeOff = timeOff;
    }
    setTimeOffForMeCount(count) {
        this.timeOffCount = count;
    }
    async getTimeOffFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query TimeOff {
          findAll {
            _id
            employeeId
            fullName
            startDate
            endDate
            type
            note
            status
            value
            processed
            creationDate
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.findAll)
            this.context.commit('setTimeOff', result.data.findAll);
    }
    async getMyTimeOffFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query TimeOff {
          myTimeOff {
            _id
            employeeId
            fullName
            startDate
            endDate
            type
            note
            status
            value
            processed
            creationDate
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.myTimeOff)
            this.context.commit('setTimeOff', result.data.myTimeOff);
    }
    async getTimeOffForMeFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query TimeOff {
          getTimeOffForMe {
            _id
            employeeId
            employee {
              firstName
              lastName
            }
            fullName
            startDate
            endDate
            type
            note
            status
            value
            processed
            creationDate
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getTimeOffForMe)
            this.context.commit('setTimeOff', result.data.getTimeOffForMe);
    }
    async getTimeOffForMeCount() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query TimeOff {
          getTimeOffForMeCount
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getTimeOffForMeCount)
            this.context.commit('setTimeOffForMeCount', result.data.getTimeOffForMeCount);
    }
    async createTimeOff() {
        const { token, host } = this.context?.rootState?.auth;
        const { newTimeOff } = this;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation TimeOff($createTimeOffInput: CreateTimeOffInput!) {
          createTimeOff(createTimeOffInput: $createTimeOffInput) {
            processed
            value
            startDate
            endDate
          }
        }
      `,
            variables: { createTimeOffInput: newTimeOff },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.createTimeOff) {
            this.context.commit('clearNewTimeOff');
            this.context.dispatch('getMyTimeOffFromRemote');
            return true;
        }
        return false;
    }
    async approveTimeOff(approveTimeOffInput) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation TimeOff($approveTimeOffInput: ApproveTimeOffInput!) {
          approveTimeOff(approveTimeOffInput: $approveTimeOffInput)
        }
      `,
            variables: { approveTimeOffInput },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.approveTimeOff) {
            await this.context.dispatch('getTimeOffForMeFromRemote');
            return result.data.approveTimeOff;
        }
        return null;
    }
    async rejectTimeOff(rejectTimeOffInput) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation TimeOff($rejectTimeOffInput: RejectTimeOffInput!) {
          rejectTimeOff(rejectTimeOffInput: $rejectTimeOffInput)
        }
      `,
            variables: { rejectTimeOffInput },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.rejectTimeOff) {
            await this.context.dispatch('getTimeOffForMeFromRemote');
            await this.context.dispatch('getTimeOffForMeFromRemote');
            return result.data.rejectTimeOff;
        }
        return null;
    }
    async getTimeOffBalanceFromRemote(employeeId) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Employee($_id: String!) {
          getTimeOffBalance(_id: $_id)
          Number
        }
      `,
            variables: { _id: employeeId },
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getTimeOffBalance) {
            this.context.commit('setTimeOffBalance', result.data.getTimeOffBalance);
        }
    }
    async myTimeOffBalanceFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Employee {
          myTimeOffBalance
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.myTimeOffBalance) {
            this.context.commit('setTimeOffBalance', result.data.myTimeOffBalance);
        }
    }
    async removeTimeOffFromRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation TimeOff($_id: String!) {
          removeTimeOff(_id: $_id)
        }
      `,
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.removeTimeOff) {
            this.context.dispatch('getMyTimeOffFromRemote');
        }
    }
    async getPLRSummariesFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query PL {
          getSummaries {
            _id
            startDate
            endDate
            creationDate
            status
            timeOffsIds
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getSummaries) {
            this.context.commit('setPLRSummaries', result.data.getSummaries);
        }
    }
};
__decorate([
    Mutation
], TimeOff.prototype, "clearNewTimeOff", null);
__decorate([
    Mutation
], TimeOff.prototype, "setPLRSummaries", null);
__decorate([
    Mutation
], TimeOff.prototype, "setNewTimeOff", null);
__decorate([
    Mutation
], TimeOff.prototype, "setTimeOffBalance", null);
__decorate([
    Mutation
], TimeOff.prototype, "setTimeOff", null);
__decorate([
    Mutation
], TimeOff.prototype, "setTimeOffForMeCount", null);
__decorate([
    Action
], TimeOff.prototype, "getTimeOffFromRemote", null);
__decorate([
    Action
], TimeOff.prototype, "getMyTimeOffFromRemote", null);
__decorate([
    Action
], TimeOff.prototype, "getTimeOffForMeFromRemote", null);
__decorate([
    Action
], TimeOff.prototype, "getTimeOffForMeCount", null);
__decorate([
    Action
], TimeOff.prototype, "createTimeOff", null);
__decorate([
    Action
], TimeOff.prototype, "approveTimeOff", null);
__decorate([
    Action
], TimeOff.prototype, "rejectTimeOff", null);
__decorate([
    Action({ rawError: true })
], TimeOff.prototype, "getTimeOffBalanceFromRemote", null);
__decorate([
    Action({ rawError: true })
], TimeOff.prototype, "myTimeOffBalanceFromRemote", null);
__decorate([
    Action({ rawError: true })
], TimeOff.prototype, "removeTimeOffFromRemote", null);
__decorate([
    Action({ rawError: true })
], TimeOff.prototype, "getPLRSummariesFromRemote", null);
TimeOff = __decorate([
    Module({ namespaced: true })
], TimeOff);
export default TimeOff;
