import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PayrollSummariesReceivers from './application/payroll-summaries-receivers.vue';
const application = namespace('application');
let Application = class Application extends Vue {
    created() {
        this.setTitle('Application Settings');
    }
};
__decorate([
    application.Mutation
], Application.prototype, "setTitle", void 0);
Application = __decorate([
    Component({
        name: 'Application',
        components: { Header, PayrollSummariesReceivers }
    })
], Application);
export default Application;
