import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
let Members = class Members extends Vue {
    constructor() {
        super(...arguments);
        this.onlyDataTable = true;
    }
    onMember(member) {
        this.$emit('member', member);
    }
    onDelete(manager) {
        this.$emit('delete', manager);
    }
};
__decorate([
    Prop({ default: () => [] })
], Members.prototype, "members", void 0);
__decorate([
    Prop({ default: false })
], Members.prototype, "manage", void 0);
__decorate([
    Prop({ default: false })
], Members.prototype, "del", void 0);
__decorate([
    Prop({
        default: () => [
            {
                text: 'Fullname',
                value: 'firstName'
            },
            { text: '', value: 'actions' }
        ]
    })
], Members.prototype, "headers", void 0);
Members = __decorate([
    Component({
        name: 'Members',
        components: { Header }
    })
], Members);
export default Members;
