import { render, staticRenderFns } from "./paydocs-tracker.vue?vue&type=template&id=150d809e&"
import script from "./paydocs-tracker.vue?vue&type=script&lang=ts&"
export * from "./paydocs-tracker.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VDataTable,VToolbar})
