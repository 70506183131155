import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue';
let Payslip = class Payslip extends Vue {
};
Payslip = __decorate([
    Component({
        name: 'Payslip',
        components: { Header }
    })
], Payslip);
export default Payslip;
