import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
let Managers = class Managers extends Vue {
    constructor() {
        super(...arguments);
        this.onlyDataTable = true;
    }
    onManager(manager) {
        this.$emit('manager', manager);
    }
    onDelete(manager) {
        this.$emit('delete', manager);
    }
};
__decorate([
    Prop({ default: () => [] })
], Managers.prototype, "managers", void 0);
__decorate([
    Prop({ default: false })
], Managers.prototype, "manage", void 0);
__decorate([
    Prop({ default: false })
], Managers.prototype, "del", void 0);
__decorate([
    Prop({
        default: () => [
            {
                text: 'Fullname',
                value: 'firstName'
            },
            {
                text: 'Role',
                value: 'role'
            },
            { text: '', value: 'actions' }
        ]
    })
], Managers.prototype, "headers", void 0);
Managers = __decorate([
    Component({
        name: 'Managers',
        components: { Header }
    })
], Managers);
export default Managers;
