import Register from './../views/register.vue';
import WaitingRoom from './../views/waiting-room.vue';
const registerRoutes = [
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/waiting-room',
        name: 'WaitingRoom',
        component: WaitingRoom
    }
];
export default registerRoutes;
