import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const timeOff = namespace('timeOff');
let PaidLeaveRights = class PaidLeaveRights extends Vue {
    async created() {
        await this.getPLRSummariesFromRemote();
    }
};
__decorate([
    timeOff.State
], PaidLeaveRights.prototype, "plrSummaries", void 0);
__decorate([
    timeOff.Action
], PaidLeaveRights.prototype, "getPLRSummariesFromRemote", void 0);
PaidLeaveRights = __decorate([
    Component({
        name: 'PaidLeaveRights',
        components: {}
    })
], PaidLeaveRights);
export default PaidLeaveRights;
