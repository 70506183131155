import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Pricing from './../components/pricing.vue';
import Subscription from './../components/subscription.vue';
import Products from './../components/products.vue';
const application = namespace('application');
let Home = class Home extends Vue {
    created() {
        this.setIsNavigation(false);
        this.setIsMenuHome(true);
    }
    destroyed() {
        this.setIsMenuHome(false);
    }
    destroy() {
        this.setIsNavigation(false);
        this.setIsMenuHome(true);
    }
};
__decorate([
    application.Mutation
], Home.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], Home.prototype, "setIsMenuHome", void 0);
Home = __decorate([
    Component({
        name: 'Home',
        components: {
            Pricing,
            Subscription,
            Products
        }
    })
], Home);
export default Home;
