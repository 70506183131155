import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Employee from './../components/employee.vue';
import NewEmployee from './../components/new-employee.vue';
import EmployeeList from './../components/employee-list.vue';
const employees = namespace('employees');
const application = namespace('application');
let Employees = class Employees extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.searchFilter = '*';
        this.isTerminated = 0;
        this.drawer = false;
        this.isList = 0; // 0 mean isList | 1 mean isCard
        this.headers = [
            {
                text: '',
                value: 'avatar'
            },
            {
                text: 'Employee',
                value: 'firstName'
            },
            { text: 'Team', value: 'team.fullName' },
            { text: 'Job title', value: 'jobTitle' },
            { text: 'Mail', value: 'mail' },
            /* { text: 'Next Pay Day', value: 'nextPayDay' }, */
            { text: '', value: 'actions' },
            {
                text: '',
                value: 'lastName'
            }
        ];
    }
    get displayEmployees() {
        switch (this.isTerminated) {
            case 0:
                return this.employees.filter(employee => {
                    return (employee != null &&
                        ((employee.firstName &&
                            this.search != null &&
                            typeof this.search === 'string' &&
                            employee.firstName
                                .toLocaleUpperCase()
                                .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                            (employee.lastName &&
                                this.search != null &&
                                typeof this.search === 'string' &&
                                employee.lastName
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                            (employee.jobTitle &&
                                this.search != null &&
                                typeof this.search === 'string' &&
                                employee.jobTitle
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                            (employee.mail &&
                                this.search != null &&
                                typeof this.search === 'string' &&
                                employee.mail
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                            (employee.phone &&
                                this.search != null &&
                                typeof this.search === 'string' &&
                                employee.phone
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
                        employee.terminated === false);
                });
            case 1:
                return this.employees.filter(employee => {
                    return (employee != null &&
                        ((employee.firstName &&
                            this.search != null &&
                            typeof this.search === 'string' &&
                            employee.firstName
                                .toLocaleUpperCase()
                                .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                            (employee.lastName &&
                                this.search != null &&
                                typeof this.search === 'string' &&
                                employee.lastName
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                            (employee.jobTitle &&
                                this.search != null &&
                                typeof this.search === 'string' &&
                                employee.jobTitle
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                            (employee.mail &&
                                this.search != null &&
                                typeof this.search === 'string' &&
                                employee.mail
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                            (employee.phone &&
                                this.search != null &&
                                typeof this.search === 'string' &&
                                employee.phone
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
                        employee.terminated === true);
                });
            default:
                return [];
        }
    }
    async created() {
        this.setIsNavigation(true);
        this.setTitle('People');
        await this.getMyEmployeesFromRemote();
    }
    onTerminated() {
        this.isTerminated = 1;
    }
    onPayroll() {
        this.isTerminated = 0;
    }
    /*   public searchByName(args: string) {
      this.search = args;
      this.searchFilter = '*';
    } */
    showItem(employee) {
        this.drawer = true;
        this.setEmployee(employee);
    }
    onList() {
        this.isList = 0;
    }
    onCard() {
        this.isList = 1;
    }
    searchByName(args) {
        this.search = args;
        /*  this.displayEmployees = this.employees.filter(employee => {
          if (this.isTerminated) {
            return (
              employee != null &&
              ((employee.firstName &&
                this.search != null &&
                typeof this.search === 'string' &&
                employee.firstName
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                (employee.lastName &&
                  this.search != null &&
                  typeof this.search === 'string' &&
                  employee.lastName
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                (employee.jobTitle &&
                  this.search != null &&
                  typeof this.search === 'string' &&
                  employee.jobTitle
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                (employee.mail &&
                  this.search != null &&
                  typeof this.search === 'string' &&
                  employee.mail
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                (employee.phone &&
                  this.search != null &&
                  typeof this.search === 'string' &&
                  employee.phone
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
              employee.terminated === true
            );
          } else {
            return (
              employee != null &&
              ((employee.firstName &&
                this.search != null &&
                typeof this.search === 'string' &&
                employee.firstName
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                (employee.lastName &&
                  this.search != null &&
                  typeof this.search === 'string' &&
                  employee.lastName
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                (employee.jobTitle &&
                  this.search != null &&
                  typeof this.search === 'string' &&
                  employee.jobTitle
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                (employee.mail &&
                  this.search != null &&
                  typeof this.search === 'string' &&
                  employee.mail
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
                (employee.phone &&
                  this.search != null &&
                  typeof this.search === 'string' &&
                  employee.phone
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
              employee.terminated === false
            );
          }
        }); */
    }
};
__decorate([
    employees.State
], Employees.prototype, "employees", void 0);
__decorate([
    employees.State
], Employees.prototype, "employee", void 0);
__decorate([
    employees.Mutation
], Employees.prototype, "setEmployee", void 0);
__decorate([
    employees.Action
], Employees.prototype, "getMyEmployeesFromRemote", void 0);
__decorate([
    application.Mutation
], Employees.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], Employees.prototype, "setTitle", void 0);
Employees = __decorate([
    Component({
        name: 'Employees',
        components: { Header, NewEmployee, Employee, EmployeeList }
    })
], Employees);
export default Employees;
