import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AddPayroll from '../components/add-payroll.vue';
const payroll = namespace('payroll');
let Payroll = class Payroll extends Vue {
    constructor() {
        super(...arguments);
        this.activeTab = 0;
        this.search = '';
        this.searchFilter = '*';
        this.expanded = [];
        this.singleExpand = false;
        this.headers = [
            { text: 'Fullname', value: 'fullName' },
            { text: 'Type', value: 'type' },
            { text: 'Period', value: 'period' },
            { text: '', value: 'actions' }
        ];
    }
    async created() {
        /* await this.getPayrollFromRemote(); */
    }
    searchByName() {
        this.searchFilter = '*';
    }
    onAll() {
        this.activeTab = 0;
    }
    onPublished() {
        this.activeTab = 1;
    }
    onPaid() {
        this.activeTab = 3;
    }
    filterPayroll(value, search, item) {
        if (this.activeTab === 0) {
            if (this.search.length) {
                return (value != null &&
                    this.search != null &&
                    typeof value === 'string' &&
                    value
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1);
            }
            return true;
        }
        else if (this.activeTab === 1) {
            if (this.search.length) {
                return (value != null &&
                    this.search != null &&
                    typeof value === 'string' &&
                    value
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1 &&
                    item.status === 'published');
            }
            return item.status === 'published';
        }
        else if (this.activeTab === 2) {
            if (this.search.length) {
                return (value != null &&
                    this.search != null &&
                    typeof value === 'string' &&
                    value
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1 &&
                    item.status === 'paid');
            }
            return item.status === 'paid';
        }
    }
};
__decorate([
    payroll.State
], Payroll.prototype, "payroll", void 0);
__decorate([
    payroll.Action
], Payroll.prototype, "getPayrollFromRemote", void 0);
Payroll = __decorate([
    Component({
        name: 'Payroll',
        components: { Header, AddPayroll }
    })
], Payroll);
export default Payroll;
