import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateUser from './update-user.vue';
let UpdateUserMail = class UpdateUserMail extends Vue {
};
__decorate([
    Prop()
], UpdateUserMail.prototype, "user", void 0);
__decorate([
    Prop({ default: false })
], UpdateUserMail.prototype, "showNotification", void 0);
UpdateUserMail = __decorate([
    Component({
        name: 'UpdateUserMail',
        components: { UpdateUser }
    })
], UpdateUserMail);
export default UpdateUserMail;
