import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateRole from './update-role.vue';
let UpdateRoleFullName = class UpdateRoleFullName extends Vue {
};
__decorate([
    Prop()
], UpdateRoleFullName.prototype, "role", void 0);
__decorate([
    Prop({ default: false })
], UpdateRoleFullName.prototype, "showNotification", void 0);
UpdateRoleFullName = __decorate([
    Component({
        name: 'UpdateRoleFullName',
        components: { UpdateRole }
    })
], UpdateRoleFullName);
export default UpdateRoleFullName;
