import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UploadDocument from '@/components/upload-document.vue';
const paydocs = namespace('paydocs');
let UploadPayroll = class UploadPayroll extends Vue {
    async upload(file) {
        const result = await this.uploadFile({
            file,
            employeeId: this.employee._id,
            category: this.category,
            period: this.period
        });
        if (result) {
            this.$emit('success', true);
        }
    }
};
__decorate([
    Prop()
], UploadPayroll.prototype, "employee", void 0);
__decorate([
    Prop()
], UploadPayroll.prototype, "category", void 0);
__decorate([
    Prop()
], UploadPayroll.prototype, "period", void 0);
__decorate([
    Prop()
], UploadPayroll.prototype, "payroll", void 0);
__decorate([
    Prop()
], UploadPayroll.prototype, "disabled", void 0);
__decorate([
    paydocs.Action
], UploadPayroll.prototype, "uploadFile", void 0);
UploadPayroll = __decorate([
    Component({
        name: 'UploadPayroll',
        components: { UploadDocument }
    })
], UploadPayroll);
export default UploadPayroll;
