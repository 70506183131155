import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Calendar = class Calendar extends Vue {
    constructor() {
        super(...arguments);
        this.type = 'week';
        this.focus = '';
        this.typeToLabel = {
            month: 'Month',
            week: 'Week',
            day: 'Day'
        };
        this.weekdays = [1, 2, 3, 4, 5, 6, 0];
    }
    mounted() {
        this.$refs.calendar.checkChange();
    }
    showEvent(event) {
        console.log(event);
    }
    viewDay(event) {
        console.log(event);
    }
    getEventColor(event) {
        return event.color;
    }
    updateRange(event) {
        console.log(event);
    }
    setToday() {
        this.focus = '';
    }
    prev() {
        this.$refs.calendar.prev();
    }
    next() {
        this.$refs.calendar.next();
    }
};
__decorate([
    Prop({ default: [] })
], Calendar.prototype, "events", void 0);
Calendar = __decorate([
    Component
], Calendar);
export default Calendar;
