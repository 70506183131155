import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const application = namespace('application');
let Products = class Products extends Vue {
    constructor() {
        super(...arguments);
        this.items = [
            {
                icon: 'mdi-account-group',
                header: 'Employee(s)',
                img: '',
                description: ''
            },
            {
                icon: 'mdi-file-document-multiple-outline',
                header: 'Paydoc'
            },
            {
                icon: 'mdi-calendar-multiselect',
                header: 'Time off'
            },
            {
                icon: 'mdi-frequently-asked-questions',
                header: 'Requests'
            },
            {
                icon: 'mdi-folder-text-outline',
                header: 'Payroll'
            },
            {
                icon: 'mdi-calendar',
                header: 'Calendar'
            },
            {
                icon: 'mdi-receipt',
                header: 'Costs'
            },
            {
                icon: 'mdi-file-chart',
                header: 'Expense Reports'
            }
        ];
    }
};
Products = __decorate([
    Component({ name: 'Products' })
], Products);
export default Products;
