import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
let Requests = class Requests extends VuexModule {
    constructor() {
        super(...arguments);
        this.newRequest = {
            category: '',
            note: '',
            status: '',
            creationDate: new Date(),
            startDate: undefined,
            endDate: undefined,
            type: ''
        };
        this.requests = [];
        this.requestsCount = 0;
    }
    clearNewRequest() {
        this.newRequest = {
            category: '',
            note: '',
            status: '',
            creationDate: new Date(),
            startDate: undefined,
            endDate: undefined,
            type: ''
        };
    }
    setNewRequest(newRequest) {
        this.newRequest = newRequest;
    }
    setRequests(requests) {
        this.requests = requests;
    }
    setRequestsCount(requestsCount) {
        this.requestsCount = requestsCount;
    }
    setCategory(category) {
        this.newRequest = { ...this.newRequest, category };
    }
    setType(type) {
        this.newRequest = { ...this.newRequest, type };
    }
    async createRequest() {
        const { token, host } = this.context?.rootState?.auth;
        const { newRequest } = this;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Request($createRequestInput: CreateRequestInput!) {
          createRequest(createRequestInput: $createRequestInput) {
            processed
          }
        }
      `,
            variables: { createRequestInput: newRequest },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.createRequest) {
            this.context.commit('clearNewRequest');
            this.context.dispatch('getMyRequestsFromRemote');
            return true;
        }
        return false;
    }
    async getRequestsFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Request {
          requests {
            _id
            category
            note
            status
            creationDate
            startDate
            endDate
            employeeId
            employee {
              firstName
              lastName
            }
            fullName
            processed
            type
            numberOfDays
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.requests) {
            this.context.commit('setRequests', result.data.requests);
        }
    }
    async getRequestsForMeCount() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Request {
          getRequestsForMeCount
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getRequestsForMeCount) {
            this.context.commit('setRequestsCount', result.data.getRequestsForMeCount);
        }
    }
    async getMyRequestsFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Request {
          myRequests {
            _id
            category
            note
            status
            creationDate
            startDate
            endDate
            employeeId
            employee {
              firstName
              lastName
            }
            fullName
            processed
            type
            numberOfDays
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.myRequests) {
            this.context.commit('setRequests', result.data.myRequests);
        }
    }
    async getRequestsForMeFromRemote() {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.query({
            query: gql `
        query Request {
          getRequestsForMe {
            _id
            category
            note
            status
            creationDate
            startDate
            endDate
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
            employeeId
            employee {
              firstName
              lastName
            }
            fullName
            processed
            type
            numberOfDays
          }
        }
      `,
            fetchPolicy: 'network-only',
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.getRequestsForMe) {
            this.context.commit('setRequests', result.data.getRequestsForMe);
        }
    }
    async updateRequest(request) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Request($updateRequestInput: UpdateRequestInput!) {
          updateRequest(updateRequestInput: $updateRequestInput) {
            category
            note
            status
            creationDate
            startDate
            endDate
            employeeId
            processed
            review
          }
        }
      `,
            variables: { updateRequestInput: request },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        return result;
    }
    async approveRequest(approveRequestInput) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Request($approveRequestInput: ApproveRequestInput!) {
          approveRequest(approveRequestInput: $approveRequestInput)
        }
      `,
            variables: { approveRequestInput },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.approveRequest) {
            this.context.dispatch('getRequestsForMeFromRemote');
            return result.data.approveRequest;
        }
        return null;
    }
    async rejectRequest(rejectRequestInput) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Request($rejectRequestInput: RejectRequestInput!) {
          rejectRequest(rejectRequestInput: $rejectRequestInput)
        }
      `,
            variables: { rejectRequestInput },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.rejectRequest) {
            this.context.dispatch('getRequestsForMeFromRemote');
            return result.data.rejectRequest;
        }
        return null;
    }
    async removeRequestFromRemote(_id) {
        const { token, host } = this.context?.rootState?.auth;
        const result = await apolloClient.mutate({
            mutation: gql `
        mutation Request($_id: String!) {
          removeRequest(_id: $_id)
        }
      `,
            variables: { _id },
            context: {
                uri: host,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }
        });
        if (result && result.data && result.data.removeRequest) {
            this.context.dispatch('getMyRequestsFromRemote');
        }
    }
};
__decorate([
    Mutation
], Requests.prototype, "clearNewRequest", null);
__decorate([
    Mutation
], Requests.prototype, "setNewRequest", null);
__decorate([
    Mutation
], Requests.prototype, "setRequests", null);
__decorate([
    Mutation
], Requests.prototype, "setRequestsCount", null);
__decorate([
    Mutation
], Requests.prototype, "setCategory", null);
__decorate([
    Mutation
], Requests.prototype, "setType", null);
__decorate([
    Action
], Requests.prototype, "createRequest", null);
__decorate([
    Action
], Requests.prototype, "getRequestsFromRemote", null);
__decorate([
    Action
], Requests.prototype, "getRequestsForMeCount", null);
__decorate([
    Action
], Requests.prototype, "getMyRequestsFromRemote", null);
__decorate([
    Action
], Requests.prototype, "getRequestsForMeFromRemote", null);
__decorate([
    Action
], Requests.prototype, "updateRequest", null);
__decorate([
    Action
], Requests.prototype, "approveRequest", null);
__decorate([
    Action
], Requests.prototype, "rejectRequest", null);
__decorate([
    Action({ rawError: true })
], Requests.prototype, "removeRequestFromRemote", null);
Requests = __decorate([
    Module({ namespaced: true })
], Requests);
export default Requests;
