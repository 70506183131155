import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Menu from '@/components/menu.vue';
import MenuAppBar from '@/components/menu-app-bar.vue';
import Footer from '@/components/footer.vue';
import Profile from '@/components/profile.vue';
import MenuHome from '@/components/menu-home.vue';
const auth = namespace('auth');
const profile = namespace('profile');
const application = namespace('application');
let App = class App extends Vue {
    created() {
        document.title = 'Payroll';
    }
};
__decorate([
    profile.State
], App.prototype, "profile", void 0);
__decorate([
    auth.State
], App.prototype, "isConnected", void 0);
__decorate([
    application.State
], App.prototype, "isNavigation", void 0);
__decorate([
    application.State
], App.prototype, "isMenuHome", void 0);
__decorate([
    application.State
], App.prototype, "title", void 0);
App = __decorate([
    Component({
        name: 'App',
        components: { Menu, Footer, MenuAppBar, Profile, MenuHome }
    })
], App);
export default App;
