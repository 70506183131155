import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const application = namespace('application');
let Pricing = class Pricing extends Vue {
    constructor() {
        super(...arguments);
        this.items = [
            {
                icon: 'mdi-account-group',
                header: 'Employee(s)',
                sb: '1 - 5',
                pro: '5+'
            },
            {
                icon: 'mdi-file-document-multiple-outline',
                header: 'Paydoc',
                sb: 'Yes',
                pro: 'Yes'
            },
            {
                icon: 'mdi-calendar-multiselect',
                header: 'Time off',
                sb: 'Yes',
                pro: 'Yes'
            },
            {
                icon: 'mdi-frequently-asked-questions',
                header: 'Requests',
                sb: 'Yes',
                pro: 'Yes'
            },
            {
                icon: 'mdi-folder-text-outline',
                header: 'Payroll',
                sb: 'No',
                pro: 'Yes'
            },
            {
                icon: 'mdi-calendar',
                header: 'Calendar',
                sb: 'No',
                pro: 'Yes'
            },
            {
                icon: 'mdi-receipt',
                header: 'Costs',
                sb: 'No',
                pro: 'Yes'
            },
            {
                icon: 'mdi-file-chart',
                header: 'Expense Reports',
                sb: 'No',
                pro: 'Yes'
            }
        ];
    }
};
Pricing = __decorate([
    Component({ name: 'Pricing' })
], Pricing);
export default Pricing;
