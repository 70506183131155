import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PaidLeaveRequest from './../components/paid-leave-request.vue';
import PayrollIssuesRequest from './../components/payroll-issues-request.vue';
import HomeOfficeRequest from './../components/home-office-request.vue';
const requests = namespace('requests');
const application = namespace('application');
const profile = namespace('profile');
let Requests = class Requests extends Vue {
    constructor() {
        super(...arguments);
        this.requestSelected = {};
        this.drawer = false;
        this.activeTab = 0;
        this.isProcessed = false;
        this.dialogPaidLeaveRequest = false;
        this.search = '';
        this.currentHeaders = [
            { text: 'Category', value: 'category' },
            { text: 'Type', value: 'type' },
            { text: 'Creation Date', value: 'creationDate' },
            { text: 'Description', value: 'date' },
            { text: 'Status', value: 'status' },
            { text: '', value: 'actions', sortable: false }
        ];
        this.closedHeaders = [
            { text: 'Category', value: 'category' },
            { text: 'Type', value: 'type' },
            { text: 'Creation Date', value: 'creationDate' },
            { text: 'Description', value: 'date' },
            { text: 'Status', value: 'status' },
            { text: '', value: 'actions', sortable: false }
        ];
    }
    async created() {
        this.setIsNavigation(true);
        this.setTitle('HO & Queries');
        await this.getMyRequestsFromRemote();
    }
    filterRequests(value, search, item) {
        switch (this.activeTab) {
            case 1:
                return item.processed === false;
            case 2:
                return item.processed === true;
            default:
                return true;
        }
    }
    onAll() {
        this.activeTab = 0;
        this.search = '*';
    }
    onCurrent() {
        this.activeTab = 1;
    }
    onClosed() {
        this.activeTab = 2;
    }
    async onDelete(_id) {
        this.removeRequestFromRemote(_id);
    }
    onRequest(request) {
        this.requestSelected = request;
        this.drawer = true;
    }
};
__decorate([
    profile.State
], Requests.prototype, "profile", void 0);
__decorate([
    requests.State
], Requests.prototype, "requests", void 0);
__decorate([
    requests.Action
], Requests.prototype, "getMyRequestsFromRemote", void 0);
__decorate([
    requests.Action
], Requests.prototype, "removeRequestFromRemote", void 0);
__decorate([
    application.Mutation
], Requests.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], Requests.prototype, "setTitle", void 0);
Requests = __decorate([
    Component({
        name: 'Requests',
        components: {
            Header,
            PaidLeaveRequest,
            PayrollIssuesRequest,
            HomeOfficeRequest
        }
    })
], Requests);
export default Requests;
