import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const requests = namespace('requests');
const application = namespace('application');
let Requests = class Requests extends Vue {
    constructor() {
        super(...arguments);
        this.drawer = false;
        this.search = '';
        this.activeTab = 0;
        this.dialogPaidLeaveRequest = false;
        this.requestSelected = {};
        this.searchFilter = '';
        this.review = '';
        this.currentHeaders = [
            {
                text: 'Fullname',
                align: 'start',
                value: 'employee'
            },
            {
                text: 'Creation date',
                value: 'creationDate'
            },
            { text: 'Category', value: 'category' },
            { text: 'Type', value: 'type' },
            { text: 'Description', value: 'date' },
            { text: 'Status', value: 'status', width: '150' },
            { text: '', value: 'actions', sortable: false }
        ];
        this.closedHeaders = [
            {
                text: 'Fullname',
                align: 'start',
                value: 'employee'
            },
            {
                text: 'Creation date',
                value: 'creationDate'
            },
            { text: 'Category', value: 'category' },
            { text: 'Type', value: 'type' },
            { text: 'Description', value: 'date' },
            { text: 'Status', value: 'status' }
        ];
    }
    async created() {
        this.setIsNavigation(true);
        this.setTitle('HO & Queries');
        await this.getRequestsForMeFromRemote();
    }
    onRequest(request) {
        this.requestSelected = request;
        this.drawer = true;
    }
    onAll() {
        this.activeTab = 0;
    }
    onCurrent() {
        this.activeTab = 1;
    }
    onApproved() {
        this.activeTab = 2;
    }
    onRejected() {
        this.activeTab = 3;
    }
    filterRequests(value, search, item) {
        switch (this.activeTab) {
            case 0:
                if (this.search.length) {
                    return (value != null &&
                        this.search != null &&
                        typeof value === 'string' &&
                        (value
                            .toString()
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                            (item.employee &&
                                (item.employee.firstName
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    item.employee.lastName
                                        .toString()
                                        .toLocaleUpperCase()
                                        .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
                            (item.note &&
                                item.note
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)));
                }
                return true;
            case 1:
                if (this.search.length) {
                    return (value != null &&
                        this.search != null &&
                        typeof value === 'string' &&
                        (value
                            .toString()
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                            (item.employee &&
                                (item.employee.firstName
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    item.employee.lastName
                                        .toString()
                                        .toLocaleUpperCase()
                                        .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
                            (item.note &&
                                item.note
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
                        item.processed === false);
                }
                return item.processed === false;
            case 2:
                if (this.search.length) {
                    return (value != null &&
                        this.search != null &&
                        typeof value === 'string' &&
                        (value
                            .toString()
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                            (item.employee &&
                                (item.employee.firstName
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    item.employee.lastName
                                        .toString()
                                        .toLocaleUpperCase()
                                        .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
                            (item.note &&
                                item.note
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
                        item.processed === true &&
                        item.status === 'approved');
                }
                return item.processed === true && item.status === 'approved';
            case 3:
                if (this.search.length) {
                    return (value != null &&
                        this.search != null &&
                        typeof value === 'string' &&
                        (value
                            .toString()
                            .toLocaleUpperCase()
                            .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                            (item.employee &&
                                (item.employee.firstName
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                                    item.employee.lastName
                                        .toString()
                                        .toLocaleUpperCase()
                                        .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
                            (item.note &&
                                item.note
                                    .toString()
                                    .toLocaleUpperCase()
                                    .indexOf(this.search.toLocaleUpperCase()) !== -1)));
                }
                return true;
        }
    }
    async accept(_id) {
        const { review } = this;
        const result = this.approveRequest({ _id, review });
        if (result) {
            this.drawer = false;
        }
    }
    async decline(_id) {
        const { review } = this;
        const result = this.rejectRequest({ _id, review });
        if (result) {
            this.drawer = false;
        }
    }
    onRequests() {
        /* this.search = 'waiting'; */
        this.searchFilter = '*';
        this.activeTab = 0;
    }
    onHistory() {
        /* this.search = 'processed'; */
        this.searchFilter = '*';
    }
    searchByName(args) {
        this.search = args;
        this.searchFilter = '*';
    }
};
__decorate([
    requests.State
], Requests.prototype, "requests", void 0);
__decorate([
    requests.Action
], Requests.prototype, "getRequestsForMeFromRemote", void 0);
__decorate([
    requests.Action
], Requests.prototype, "approveRequest", void 0);
__decorate([
    requests.Action
], Requests.prototype, "rejectRequest", void 0);
__decorate([
    application.Mutation
], Requests.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], Requests.prototype, "setTitle", void 0);
Requests = __decorate([
    Component({
        name: 'RequestsManager',
        components: { Header }
    })
], Requests);
export default Requests;
