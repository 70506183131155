import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const profile = namespace('profile');
const application = namespace('application');
const login = namespace('login');
let Profile = class Profile extends Vue {
    onMyAccount() {
        this.$router.push('/myAccount');
    }
    onModeManager() {
        this.setMode('manager');
        this.$router.push('/manager/overview');
    }
    onModeEmployee() {
        this.setMode('employee');
        this.$router.push('/overview');
    }
    onLogout() {
        this.logOut();
    }
};
__decorate([
    profile.State
], Profile.prototype, "profile", void 0);
__decorate([
    profile.State
], Profile.prototype, "avatar", void 0);
__decorate([
    application.State
], Profile.prototype, "mode", void 0);
__decorate([
    application.Mutation
], Profile.prototype, "setMode", void 0);
__decorate([
    login.Action
], Profile.prototype, "logOut", void 0);
Profile = __decorate([
    Component({
        name: 'Profile'
    })
], Profile);
export default Profile;
