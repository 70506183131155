import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Header from '@/components/header.vue';
import TimelineItem from './../components/TimelineItem.vue';
import EmployeesOnHoliday from './../components/employees-on-holiday.vue';
import ToDoList from './../components/to-do-list.vue';
import { namespace } from 'vuex-class';
import Celebrations from '../components/celebrations.vue';
import Upcoming from '../components/Upcoming.vue';
import Profile from '@/components/profile.vue';
const overview = namespace('overview');
const timeOff = namespace('timeOff');
const requests = namespace('requests');
const profile = namespace('profile');
const application = namespace('application');
let OverviewManager = class OverviewManager extends Vue {
    async created() {
        this.setIsNavigation(true);
        this.setTitle(`Good afternoon, ${this.profile.firstName}`);
        this.getTimeLineFromRemote();
        await this.getTimeOffForMeCount();
        await this.getRequestsForMeCount();
    }
};
__decorate([
    overview.State
], OverviewManager.prototype, "toDo", void 0);
__decorate([
    overview.State
], OverviewManager.prototype, "timeline", void 0);
__decorate([
    timeOff.State
], OverviewManager.prototype, "timeOffCount", void 0);
__decorate([
    requests.State
], OverviewManager.prototype, "requestsCount", void 0);
__decorate([
    profile.State
], OverviewManager.prototype, "profile", void 0);
__decorate([
    overview.Action
], OverviewManager.prototype, "getTimeLineFromRemote", void 0);
__decorate([
    timeOff.Action
], OverviewManager.prototype, "getTimeOffForMeCount", void 0);
__decorate([
    requests.Action
], OverviewManager.prototype, "getRequestsForMeCount", void 0);
__decorate([
    application.Mutation
], OverviewManager.prototype, "setIsNavigation", void 0);
__decorate([
    application.Mutation
], OverviewManager.prototype, "setTitle", void 0);
OverviewManager = __decorate([
    Component({
        components: {
            Header,
            TimelineItem,
            ToDoList,
            EmployeesOnHoliday,
            Celebrations,
            Upcoming,
            Profile
        }
    })
], OverviewManager);
export default OverviewManager;
