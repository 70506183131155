import { __decorate } from "tslib";
import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TeamList from './team/team-list.vue';
import NewTeam from './team/new-team.vue';
import Team from './team/team.vue';
const teams = namespace('teams');
const application = namespace('application');
let Teams = class Teams extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.displayTeams = [];
        this.isList = 0;
        this.drawer = false;
        this.headers = [
            {
                text: 'Fullname',
                value: 'fullName'
            },
            {
                text: 'Leader',
                value: 'leader'
            },
            { text: 'Description', value: 'description' },
            { text: '', value: 'actions' }
        ];
    }
    async created() {
        this.setTitle('Teams Settings');
        await this.getTeamsFromRemote();
        this.displayTeams = this.teams;
    }
    onTeam(team) {
        this.$emit('team', team);
        this.setTeam(team);
        this.drawer = true;
    }
    openDrawer() {
        this.drawer = true;
    }
    handleTeam(team) {
        this.setTeam(team);
        this.openDrawer();
    }
    onList() {
        this.isList = 0;
    }
    onCard() {
        this.isList = 1;
    }
    searchByName(args) {
        this.search = args;
        this.displayTeams = this.teams.filter(team => {
            return (team != null &&
                ((team.fullName &&
                    typeof args === 'string' &&
                    team.fullName
                        .toLocaleUpperCase()
                        .indexOf(args.toLocaleUpperCase()) !== -1) ||
                    (team.description &&
                        typeof args === 'string' &&
                        team.description
                            .toLocaleUpperCase()
                            .indexOf(args.toLocaleUpperCase()) !== -1) ||
                    (team.leader &&
                        team.leader.firstName &&
                        typeof args === 'string' &&
                        team.leader.firstName
                            .toLocaleUpperCase()
                            .indexOf(args.toLocaleUpperCase()) !== -1) ||
                    (team.leader &&
                        team.leader.lastName &&
                        typeof args === 'string' &&
                        team.leader.lastName
                            .toLocaleUpperCase()
                            .indexOf(args.toLocaleUpperCase()) !== -1)));
        });
    }
};
__decorate([
    Prop({ default: false })
], Teams.prototype, "onlyDataTable", void 0);
__decorate([
    Prop({ default: 'Teams' })
], Teams.prototype, "title", void 0);
__decorate([
    teams.State
], Teams.prototype, "team", void 0);
__decorate([
    teams.State
], Teams.prototype, "teams", void 0);
__decorate([
    teams.Mutation
], Teams.prototype, "setTeam", void 0);
__decorate([
    teams.Action
], Teams.prototype, "getTeamsFromRemote", void 0);
__decorate([
    application.Mutation
], Teams.prototype, "setTitle", void 0);
Teams = __decorate([
    Component({
        name: 'Teams',
        components: { Header, TeamList, NewTeam, Team }
    })
], Teams);
export default Teams;
