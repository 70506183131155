var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.payroll.map(function (elem, index) { return (Object.assign({}, elem, {index: index})); }),"items-per-page":5,"item-key":"index","custom-filter":_vm.filterPayroll,"search":_vm.searchFilter},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"table__header",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"payroll__search",attrs:{"label":"Search","hide-details":"auto","single-line":"","prepend-icon":"mdi-magnify"},on:{"input":_vm.searchByName}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{class:{
          requested:
            item.status === 'published' ||
            item.status === 'draft' ||
            item.status === 'approved' ||
            item.status === 'requested',
          approved: item.status === 'paid'
        }},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }